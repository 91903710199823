/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultHttpResponse_CommunityFreeTalkFeedbackResponse_ } from '../models/DefaultHttpResponse_CommunityFreeTalkFeedbackResponse_';
import type { DefaultHttpResponse_CommunityPolicyTalkFeedbackResponse_ } from '../models/DefaultHttpResponse_CommunityPolicyTalkFeedbackResponse_';
import type { DefaultHttpResponse_CommunityWelloNewsFeedbackResponse_ } from '../models/DefaultHttpResponse_CommunityWelloNewsFeedbackResponse_';
import type { DefaultHttpResponse_DetailBestContentsResponse_ } from '../models/DefaultHttpResponse_DetailBestContentsResponse_';
import type { DefaultHttpResponse_DetailCommunityFreeTalkResponse_ } from '../models/DefaultHttpResponse_DetailCommunityFreeTalkResponse_';
import type { DefaultHttpResponse_DetailCommunityPolicyTalkResponse_ } from '../models/DefaultHttpResponse_DetailCommunityPolicyTalkResponse_';
import type { DefaultHttpResponse_DetailCommunityWelloNewsResponse_ } from '../models/DefaultHttpResponse_DetailCommunityWelloNewsResponse_';
import type { DefaultHttpResponse_DetailHometownNewsResponse_ } from '../models/DefaultHttpResponse_DetailHometownNewsResponse_';
import type { DefaultHttpResponse_DetailMetaPolicyAllowsResponse_ } from '../models/DefaultHttpResponse_DetailMetaPolicyAllowsResponse_';
import type { DefaultHttpResponse_DetailPolicyAiInfoResponse_ } from '../models/DefaultHttpResponse_DetailPolicyAiInfoResponse_';
import type { DefaultHttpResponse_GeocodingDto_ } from '../models/DefaultHttpResponse_GeocodingDto_';
import type { DefaultHttpResponse_LatestMetaPolicyResponse_ } from '../models/DefaultHttpResponse_LatestMetaPolicyResponse_';
import type { DefaultHttpResponse_ListAvailCardResponse_ } from '../models/DefaultHttpResponse_ListAvailCardResponse_';
import type { DefaultHttpResponse_ListBankResponse_ } from '../models/DefaultHttpResponse_ListBankResponse_';
import type { DefaultHttpResponse_ListBestContentsResponse_ } from '../models/DefaultHttpResponse_ListBestContentsResponse_';
import type { DefaultHttpResponse_ListCardResponse_ } from '../models/DefaultHttpResponse_ListCardResponse_';
import type { DefaultHttpResponse_ListChildcarePolicyBudgetResponse_ } from '../models/DefaultHttpResponse_ListChildcarePolicyBudgetResponse_';
import type { DefaultHttpResponse_ListChildcareRelatedPolicyResponse_ } from '../models/DefaultHttpResponse_ListChildcareRelatedPolicyResponse_';
import type { DefaultHttpResponse_ListCommunityBannerResponse_ } from '../models/DefaultHttpResponse_ListCommunityBannerResponse_';
import type { DefaultHttpResponse_ListCommunityFreeTalkResponse_ } from '../models/DefaultHttpResponse_ListCommunityFreeTalkResponse_';
import type { DefaultHttpResponse_ListCommunityMainCategoryResponse_ } from '../models/DefaultHttpResponse_ListCommunityMainCategoryResponse_';
import type { DefaultHttpResponse_ListCommunityPolicyTalkResponse_ } from '../models/DefaultHttpResponse_ListCommunityPolicyTalkResponse_';
import type { DefaultHttpResponse_ListCommunitySubCategoryResponse_ } from '../models/DefaultHttpResponse_ListCommunitySubCategoryResponse_';
import type { DefaultHttpResponse_ListCommunityTalkResponse_ } from '../models/DefaultHttpResponse_ListCommunityTalkResponse_';
import type { DefaultHttpResponse_ListCommunityWelloNewsResponse_ } from '../models/DefaultHttpResponse_ListCommunityWelloNewsResponse_';
import type { DefaultHttpResponse_ListExpositionLinkUrlResponse_ } from '../models/DefaultHttpResponse_ListExpositionLinkUrlResponse_';
import type { DefaultHttpResponse_ListHomePolicyReviewResponse_ } from '../models/DefaultHttpResponse_ListHomePolicyReviewResponse_';
import type { DefaultHttpResponse_ListHometownNewsResponse_ } from '../models/DefaultHttpResponse_ListHometownNewsResponse_';
import type { DefaultHttpResponse_ListLatestCommunityResponse_ } from '../models/DefaultHttpResponse_ListLatestCommunityResponse_';
import type { DefaultHttpResponse_ListLatestPolicyResponse_ } from '../models/DefaultHttpResponse_ListLatestPolicyResponse_';
import type { DefaultHttpResponse_ListMetaPolicyResponse_ } from '../models/DefaultHttpResponse_ListMetaPolicyResponse_';
import type { DefaultHttpResponse_ListNewBestContentsResponse_ } from '../models/DefaultHttpResponse_ListNewBestContentsResponse_';
import type { DefaultHttpResponse_ListPolicyByPolicyNameResponse_ } from '../models/DefaultHttpResponse_ListPolicyByPolicyNameResponse_';
import type { DefaultHttpResponse_ListPolicyCaseCategoryResponse_ } from '../models/DefaultHttpResponse_ListPolicyCaseCategoryResponse_';
import type { DefaultHttpResponse_ListPolicyCodeGeocodingResponse_ } from '../models/DefaultHttpResponse_ListPolicyCodeGeocodingResponse_';
import type { DefaultHttpResponse_ListPolicyRelatedCommunityResponse_ } from '../models/DefaultHttpResponse_ListPolicyRelatedCommunityResponse_';
import type { DefaultHttpResponse_ListPolicyResponse_ } from '../models/DefaultHttpResponse_ListPolicyResponse_';
import type { DefaultHttpResponse_ListPopularBestContentsResponse_ } from '../models/DefaultHttpResponse_ListPopularBestContentsResponse_';
import type { DefaultHttpResponse_ListPopularCommunityFreeTalkResponse_ } from '../models/DefaultHttpResponse_ListPopularCommunityFreeTalkResponse_';
import type { DefaultHttpResponse_ListPopularCommunityPolicyTalkResponse_ } from '../models/DefaultHttpResponse_ListPopularCommunityPolicyTalkResponse_';
import type { DefaultHttpResponse_ListPopularHometownNewsResponse_ } from '../models/DefaultHttpResponse_ListPopularHometownNewsResponse_';
import type { DefaultHttpResponse_ListPopularPolicyResponse_ } from '../models/DefaultHttpResponse_ListPopularPolicyResponse_';
import type { DefaultHttpResponse_ListRelatedHometownNewsResponse_ } from '../models/DefaultHttpResponse_ListRelatedHometownNewsResponse_';
import type { DefaultHttpResponse_ListRelatedMetaPolicyResponse_ } from '../models/DefaultHttpResponse_ListRelatedMetaPolicyResponse_';
import type { DefaultHttpResponse_ListWelloPolicyCodeAllResponse_ } from '../models/DefaultHttpResponse_ListWelloPolicyCodeAllResponse_';
import type { DefaultHttpResponse_ListWelloPolicyCodeKbResponse_ } from '../models/DefaultHttpResponse_ListWelloPolicyCodeKbResponse_';
import type { DefaultHttpResponse_ListWelloPolicyCodeResponse_ } from '../models/DefaultHttpResponse_ListWelloPolicyCodeResponse_';
import type { DefaultHttpResponse_MetaPolicyCountResponse_ } from '../models/DefaultHttpResponse_MetaPolicyCountResponse_';
import type { DefaultHttpResponse_MetaPolicyRankingResponse_ } from '../models/DefaultHttpResponse_MetaPolicyRankingResponse_';
import type { DefaultHttpResponse_ReverseGeocodingResponse_ } from '../models/DefaultHttpResponse_ReverseGeocodingResponse_';
import type { DefaultHttpResponse_Void_ } from '../models/DefaultHttpResponse_Void_';
import type { DefaultHttpResponse_WelloFeedbackResponse_ } from '../models/DefaultHttpResponse_WelloFeedbackResponse_';
import type { SaveWelloTrackRequest } from '../models/SaveWelloTrackRequest';
import type { UpdatePolicyAiInfoRequest } from '../models/UpdatePolicyAiInfoRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class Service {
    /**
     * 신청카드 목록 조회
     * @returns DefaultHttpResponse_ListAvailCardResponse_ OK
     * @throws ApiError
     */
    public static availCardListUsingGet({
        availCardTypeCd,
    }: {
        /**
         * 신청카드 타입 코드
         */
        availCardTypeCd: string,
    }): CancelablePromise<DefaultHttpResponse_ListAvailCardResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/avail-card',
            query: {
                'availCardTypeCd': availCardTypeCd,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 은행 목록 조회
     * @returns DefaultHttpResponse_ListBankResponse_ OK
     * @throws ApiError
     */
    public static bankListUsingGet(): CancelablePromise<DefaultHttpResponse_ListBankResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/bank',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 소식 목록 조회
     * @returns DefaultHttpResponse_ListBestContentsResponse_ OK
     * @throws ApiError
     */
    public static bestContentsListUsingGet1({
        page,
        size,
        bestContentsSort,
        codeProvisionType,
        endAt,
        searchKeyword,
        startAt,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 정렬 순서(POPULARITY, LATEST)
         */
        bestContentsSort?: 'LATEST' | 'POPULARITY',
        /**
         * 지원유형[C09-XX]
         */
        codeProvisionType?: string,
        /**
         * 정책 종료 일시
         */
        endAt?: string,
        /**
         * 검색어
         */
        searchKeyword?: string,
        /**
         * 정책 시작 일시
         */
        startAt?: string,
    }): CancelablePromise<DefaultHttpResponse_ListBestContentsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/contents/best',
            query: {
                'best_contents_sort': bestContentsSort,
                'code_provision_type': codeProvisionType,
                'end_at': endAt,
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
                'start_at': startAt,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 소식 내 고잭정보 조회
     * @returns DefaultHttpResponse_WelloFeedbackResponse_ OK
     * @throws ApiError
     */
    public static bestContentsFeedbackUsingGet1({
        bestContentsId,
    }: {
        /**
         * 정책 소식 ID
         */
        bestContentsId: number,
    }): CancelablePromise<DefaultHttpResponse_WelloFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/contents/best/feedback/{best_contents_id}',
            path: {
                'best_contents_id': bestContentsId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 신규 정책 소식 목록 조회
     * @returns DefaultHttpResponse_ListNewBestContentsResponse_ OK
     * @throws ApiError
     */
    public static getNewBestContentsListUsingGet1(): CancelablePromise<DefaultHttpResponse_ListNewBestContentsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/contents/best/new',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 인기 정책 소식 목록 조회
     * @returns DefaultHttpResponse_ListPopularBestContentsResponse_ OK
     * @throws ApiError
     */
    public static getPopularBestContentsListUsingGet1(): CancelablePromise<DefaultHttpResponse_ListPopularBestContentsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/contents/best/popular',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 소식 상세 조회
     * @returns DefaultHttpResponse_DetailBestContentsResponse_ OK
     * @throws ApiError
     */
    public static getBestContentsUsingGet({
        bestContentsIdIdx,
    }: {
        /**
         * 정책 소식 ID
         */
        bestContentsIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailBestContentsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/contents/best/{best_contents_id_idx}',
            path: {
                'best_contents_id_idx': bestContentsIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 카드사 목록 조회
     * @returns DefaultHttpResponse_ListCardResponse_ OK
     * @throws ApiError
     */
    public static cardListUsingGet({
        availCardTypeCd,
    }: {
        /**
         * 신청카드 타입 코드
         */
        availCardTypeCd: string,
    }): CancelablePromise<DefaultHttpResponse_ListCardResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/card',
            query: {
                'availCardTypeCd': availCardTypeCd,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 내 상황에 맞는 육아 정책 조회
     * @returns DefaultHttpResponse_ListChildcarePolicyBudgetResponse_ OK
     * @throws ApiError
     */
    public static getListChildcarePolicyBudgetUsingGet({
        childCount,
        subRegionCode,
    }: {
        /**
         * 아이 수
         */
        childCount: number,
        /**
         * 지역 상세 코드
         */
        subRegionCode: string,
    }): CancelablePromise<DefaultHttpResponse_ListChildcarePolicyBudgetResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy-case/baby',
            query: {
                'child_count': childCount,
                'sub_region_code': subRegionCode,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 내 상황에 맞는 육아 정책 관련 정책 조회
     * @returns DefaultHttpResponse_ListChildcareRelatedPolicyResponse_ OK
     * @throws ApiError
     */
    public static getListChildcareRelatedPolicyUsingGet1({
        subRegionCode,
    }: {
        /**
         * 지역 상세 코드
         */
        subRegionCode: string,
    }): CancelablePromise<DefaultHttpResponse_ListChildcareRelatedPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy-case/baby/related-policy',
            query: {
                'sub_region_code': subRegionCode,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 롤링 배너
     * @returns DefaultHttpResponse_ListCommunityBannerResponse_ OK
     * @throws ApiError
     */
    public static getCommunityBannerListUsingGet(): CancelablePromise<DefaultHttpResponse_ListCommunityBannerResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/banner',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 최신글
     * @returns DefaultHttpResponse_ListLatestCommunityResponse_ OK
     * @throws ApiError
     */
    public static getLatestCommunityListUsingGet1(): CancelablePromise<DefaultHttpResponse_ListLatestCommunityResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/latest',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 카테고리 - 메인 카테고리 목록 조회
     * @returns DefaultHttpResponse_ListCommunityMainCategoryResponse_ OK
     * @throws ApiError
     */
    public static getCommunityMainCategoryListUsingGet(): CancelablePromise<DefaultHttpResponse_ListCommunityMainCategoryResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/category/main-category',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 카테고리 - 서브 카테고리 목록 조회
     * @returns DefaultHttpResponse_ListCommunitySubCategoryResponse_ OK
     * @throws ApiError
     */
    public static getCommunitySubcategoryListByCodeUsingGet({
        communityTypeCd,
    }: {
        /**
         * 커뮤니티 타입 코드
         */
        communityTypeCd: string,
    }): CancelablePromise<DefaultHttpResponse_ListCommunitySubCategoryResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/category/subcategory',
            query: {
                'community_type_cd': communityTypeCd,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 자유 수다 목록 조회
     * @returns DefaultHttpResponse_ListCommunityFreeTalkResponse_ OK
     * @throws ApiError
     */
    public static getCommunityFreeTalkListUsingGet1({
        page,
        size,
        searchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 검색어
         */
        searchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListCommunityFreeTalkResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/free-talk',
            query: {
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 자유 수다 피드백 (조회수, 좋아요 수, 댓글 수, 좋아요 확인 보정)
     * @returns DefaultHttpResponse_CommunityFreeTalkFeedbackResponse_ OK
     * @throws ApiError
     */
    public static feedbackCommunityFreeTalkUsingGet1({
        communityIdIdx,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_CommunityFreeTalkFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/free-talk/feedback/{community_id_idx}',
            path: {
                'community_id_idx': communityIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - HOT한 수다 - 자유수다
     * @returns DefaultHttpResponse_ListCommunityTalkResponse_ OK
     * @throws ApiError
     */
    public static getCommunityFreeTalkHotListUsingGet1({
        page,
        size,
        searchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 검색어
         */
        searchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListCommunityTalkResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/free-talk/hot',
            query: {
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 인기 자유수다
     * @returns DefaultHttpResponse_ListPopularCommunityFreeTalkResponse_ OK
     * @throws ApiError
     */
    public static getPopularCommunityFreeTalkListUsingGet1(): CancelablePromise<DefaultHttpResponse_ListPopularCommunityFreeTalkResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/free-talk/popular',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 자유 수다 상세
     * @returns DefaultHttpResponse_DetailCommunityFreeTalkResponse_ OK
     * @throws ApiError
     */
    public static getCommunityFreeTalkUsingGet1({
        communityIdIdx,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailCommunityFreeTalkResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/free-talk/{community_id_idx}',
            path: {
                'community_id_idx': communityIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 정책 수다 목록 조회
     * @returns DefaultHttpResponse_ListCommunityPolicyTalkResponse_ OK
     * @throws ApiError
     */
    public static getCommunityPolicyTalkListUsingGet1({
        page,
        size,
        code,
        communityCategoryId,
        searchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 코드
         */
        code?: string,
        /**
         * 커뮤니티 카테고리 아이디
         */
        communityCategoryId?: number,
        /**
         * 검색어
         */
        searchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListCommunityPolicyTalkResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/policy-talk',
            query: {
                'code': code,
                'community_category_id': communityCategoryId,
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 정책 수다 피드백 (조회수, 좋아요 수, 댓글 수, 좋아요 확인 보정)
     * @returns DefaultHttpResponse_CommunityPolicyTalkFeedbackResponse_ OK
     * @throws ApiError
     */
    public static feedbackCommunityPolicyTalkUsingGet1({
        communityIdIdx,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_CommunityPolicyTalkFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/policy-talk/feedback/{community_id_idx}',
            path: {
                'community_id_idx': communityIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 홈화면 - 정책 후기 목록 조회
     * @returns DefaultHttpResponse_ListHomePolicyReviewResponse_ OK
     * @throws ApiError
     */
    public static getHomePolicyReviewListUsingGet(): CancelablePromise<DefaultHttpResponse_ListHomePolicyReviewResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/policy-talk/home/policy-reviews',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - HOT한 수다 - 정책수다
     * @returns DefaultHttpResponse_ListCommunityTalkResponse_ OK
     * @throws ApiError
     */
    public static getCommunityPolicyTalkHotListUsingGet1({
        page,
        size,
        searchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 검색어
         */
        searchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListCommunityTalkResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/policy-talk/hot',
            query: {
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 인기 정책수다
     * @returns DefaultHttpResponse_ListPopularCommunityPolicyTalkResponse_ OK
     * @throws ApiError
     */
    public static getPopularCommunityPolicyTalkListUsingGet1(): CancelablePromise<DefaultHttpResponse_ListPopularCommunityPolicyTalkResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/policy-talk/popular',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 정책 수다 상세
     * @returns DefaultHttpResponse_DetailCommunityPolicyTalkResponse_ OK
     * @throws ApiError
     */
    public static getCommunityPolicyTalkUsingGet1({
        communityIdIdx,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailCommunityPolicyTalkResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/policy-talk/{community_id_idx}',
            path: {
                'community_id_idx': communityIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 웰로 소식 목록 조회
     * @returns DefaultHttpResponse_ListCommunityWelloNewsResponse_ OK
     * @throws ApiError
     */
    public static getCommunityWelloNewsListUsingGet1({
        page,
        size,
        searchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 검색어
         */
        searchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListCommunityWelloNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/wello-news',
            query: {
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 웰로 뉴스 피드백 (조회수, 좋아요 수, 댓글 수, 좋아요 확인 보정)
     * @returns DefaultHttpResponse_CommunityWelloNewsFeedbackResponse_ OK
     * @throws ApiError
     */
    public static feedbackCommunityWelloNewsUsingGet1({
        communityIdIdx,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_CommunityWelloNewsFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/wello-news/feedback/{community_id_idx}',
            path: {
                'community_id_idx': communityIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 웰로 소식 상세
     * @returns DefaultHttpResponse_DetailCommunityWelloNewsResponse_ OK
     * @throws ApiError
     */
    public static getCommunityWelloNewsUsingGet1({
        communityIdIdx,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailCommunityWelloNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/community/wello-news/{community_id_idx}',
            path: {
                'community_id_idx': communityIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정부박람회 링크 url 목록 조회
     * @returns DefaultHttpResponse_ListExpositionLinkUrlResponse_ OK
     * @throws ApiError
     */
    public static expositionLinkUrlListUsingGet(): CancelablePromise<DefaultHttpResponse_ListExpositionLinkUrlResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/exposition-link-url',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 시군구 코드로 위/경도 조회
     * @returns DefaultHttpResponse_GeocodingDto_ OK
     * @throws ApiError
     */
    public static getNCloudGeocodingByCodeUsingGet({
        regionCode,
        subRegionCode,
    }: {
        /**
         * regionCode
         */
        regionCode: string,
        /**
         * subRegionCode
         */
        subRegionCode: string,
    }): CancelablePromise<DefaultHttpResponse_GeocodingDto_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/geocoding',
            query: {
                'regionCode': regionCode,
                'subRegionCode': subRegionCode,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 동네 소식 목록
     * @returns DefaultHttpResponse_ListHometownNewsResponse_ OK
     * @throws ApiError
     */
    public static hometownNewsListUsingGet1({
        page,
        size,
        codeRegion,
        codeSubRegion,
        hometownNewsSort,
        searchKeyword,
        tagSearchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 코드_지역(시도)[C01-XX]
         */
        codeRegion?: string,
        /**
         * 코드_지역(시군구)[C01-XX-YY]
         */
        codeSubRegion?: string,
        /**
         * 정렬순서(LATEST,POPULARITY)
         */
        hometownNewsSort?: 'LATEST' | 'POPULARITY',
        /**
         * 검색어
         */
        searchKeyword?: string,
        /**
         * 태그 검색 키워드
         */
        tagSearchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListHometownNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/hometown-news',
            query: {
                'code_region': codeRegion,
                'code_sub_region': codeSubRegion,
                'hometown_news_sort': hometownNewsSort,
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
                'tag_search_keyword': tagSearchKeyword,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 비로그인 멤버 동네 소식 좋아요&스크랩 정보 조회
     * @returns DefaultHttpResponse_WelloFeedbackResponse_ OK
     * @throws ApiError
     */
    public static hometownNewsFeedbackUsingGet1({
        hometownNewsId,
    }: {
        /**
         * 동네 소식 ID
         */
        hometownNewsId: number,
    }): CancelablePromise<DefaultHttpResponse_WelloFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/hometown-news/feedback/{hometown_news_id}',
            path: {
                'hometown_news_id': hometownNewsId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 인기 동네 소식 목록
     * @returns DefaultHttpResponse_ListPopularHometownNewsResponse_ OK
     * @throws ApiError
     */
    public static getPopularHometownNewsListUsingGet1(): CancelablePromise<DefaultHttpResponse_ListPopularHometownNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/hometown-news/popular',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 인기 동네 소식 목록
     * @returns DefaultHttpResponse_ListPopularHometownNewsResponse_ OK
     * @throws ApiError
     */
    public static getPopularHometownNewsListForCommunityUsingGet1(): CancelablePromise<DefaultHttpResponse_ListPopularHometownNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/hometown-news/popular/community',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 동네 소식 상세
     * @returns DefaultHttpResponse_DetailHometownNewsResponse_ OK
     * @throws ApiError
     */
    public static hometownNewsUsingGet1({
        hometownNewsIdIdx,
    }: {
        /**
         * 동네 소식 id idx
         */
        hometownNewsIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailHometownNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/hometown-news/{hometown_news_id_idx}',
            path: {
                'hometown_news_id_idx': hometownNewsIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 관련 동네소식 목록 조회(최대 10개)
     * @returns DefaultHttpResponse_ListRelatedHometownNewsResponse_ OK
     * @throws ApiError
     */
    public static getRelatedHometownNewsListUsingGet({
        hometownNewsIdIdx,
    }: {
        /**
         * 동네 소식 id idx
         */
        hometownNewsIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_ListRelatedHometownNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/hometown-news/{hometown_news_id_idx}/related',
            path: {
                'hometown_news_id_idx': hometownNewsIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 관심 분야별 정책 조회
     * @returns DefaultHttpResponse_ListPolicyResponse_ OK
     * @throws ApiError
     */
    public static allowsInterestPolicyListUsingGet({
        codeProvisionType,
        page,
        size,
        codeRegion,
        codeSubRegion,
    }: {
        /**
         * 관심유형(C14)
         */
        codeProvisionType: string,
        page: number,
        size: number,
        /**
         * 시도 코드
         */
        codeRegion?: string,
        /**
         * 시군구 코드
         */
        codeSubRegion?: string,
    }): CancelablePromise<DefaultHttpResponse_ListPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/interest-policy',
            query: {
                'codeProvisionType': codeProvisionType,
                'codeRegion': codeRegion,
                'codeSubRegion': codeSubRegion,
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 검색목록 조회
     * 정책 검색목록을 조회합니다
     * @returns DefaultHttpResponse_ListMetaPolicyResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyListUsingGet1({
        page,
        size,
        codeProvisionTypeList,
        codeRegion,
        codeSubRegion,
        descAgeList,
        policySort,
        searchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 페이지 사이즈
         */
        size: number,
        /**
         * 지원유형[C09-XX] 목록
         */
        codeProvisionTypeList?: Array<string>,
        /**
         * 메타코드-지역(시도)[C01-XX]
         */
        codeRegion?: string,
        /**
         * 메타코드-지역(시군구)[C01-XX-YY]
         */
        codeSubRegion?: string,
        /**
         * 지원대상 목록
         */
        descAgeList?: Array<string>,
        /**
         * 정렬순서(LATEST,POPULARITY)
         */
        policySort?: 'LATEST' | 'POPULARITY',
        /**
         * 검색어
         */
        searchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListMetaPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy',
            query: {
                'codeProvisionTypeList': codeProvisionTypeList,
                'codeRegion': codeRegion,
                'codeSubRegion': codeSubRegion,
                'descAgeList': descAgeList,
                'page': page,
                'policySort': policySort,
                'searchKeyword': searchKeyword,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 누적 정책 & 정보 제공기관 수 조회
     * 누적 정책 & 정보 제공기관 수를 조회합니다
     * @returns DefaultHttpResponse_MetaPolicyCountResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyCountUsingGet(): CancelablePromise<DefaultHttpResponse_MetaPolicyCountResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy-count',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 비로그인 멤버 정책 좋아요&스크랩 정보 조회
     * @returns DefaultHttpResponse_WelloFeedbackResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyFeedbackUsingGet1({
        policyId,
    }: {
        /**
         * 정책 ID
         */
        policyId: number,
    }): CancelablePromise<DefaultHttpResponse_WelloFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy/feedback/{policy_id}',
            path: {
                'policy_id': policyId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 신규정책 목록 조회
     * 신규정책 목록을 조회합니다
     * @returns DefaultHttpResponse_LatestMetaPolicyResponse_ OK
     * @throws ApiError
     */
    public static latestMetaPolicyListUsingGet({
        codeProvisionType,
    }: {
        /**
         * 지원유형[C09-XX]
         */
        codeProvisionType?: string,
    }): CancelablePromise<DefaultHttpResponse_LatestMetaPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy/latest',
            query: {
                'code_provision_type': codeProvisionType,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 홈화면 개편 신규정책 목록 조회
     * 홈화면 개편을 위한 신규정책 목록을 조회합니다
     * @returns DefaultHttpResponse_ListLatestPolicyResponse_ OK
     * @throws ApiError
     */
    public static getLatestPolicyListUsingGet(): CancelablePromise<DefaultHttpResponse_ListLatestPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy/latest/new',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책명으로 정책 목록 조회
     * @returns DefaultHttpResponse_ListPolicyByPolicyNameResponse_ OK
     * @throws ApiError
     */
    public static getPolicyListByPolicyNameUsingGet({
        page,
        size,
        policyName,
    }: {
        page: number,
        size: number,
        policyName?: string,
    }): CancelablePromise<DefaultHttpResponse_ListPolicyByPolicyNameResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy/name-search',
            query: {
                'page': page,
                'policyName': policyName,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 가장 많이 본 정책 목록 조회
     * 가장 많이 본 정책 목록을 조회합니다
     * @returns DefaultHttpResponse_ListPopularPolicyResponse_ OK
     * @throws ApiError
     */
    public static popularPolicyListUsingGet({
        codeProvisionType,
        needTypeListYn,
    }: {
        /**
         * 직장코드, 자녀코드, 직장 상세코드
         */
        codeProvisionType?: string,
        /**
         * 타입 리스트 필요 여부
         */
        needTypeListYn?: boolean,
    }): CancelablePromise<DefaultHttpResponse_ListPopularPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy/popular',
            query: {
                'code_provision_type': codeProvisionType,
                'need_type_list_yn': needTypeListYn,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 실시간 핫정책 TOP10 조회
     * 실시간 핫정책 TOP10을 조회합니다
     * @returns DefaultHttpResponse_MetaPolicyRankingResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyTop10ListUsingGet(): CancelablePromise<DefaultHttpResponse_MetaPolicyRankingResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy/top10',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 비회원 정책 상세 조회
     * @returns DefaultHttpResponse_DetailMetaPolicyAllowsResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyUsingGet1({
        metaPolicyIdIdx,
    }: {
        /**
         * 정책번호
         */
        metaPolicyIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailMetaPolicyAllowsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy/{meta_policy_id_idx}',
            path: {
                'meta_policy_id_idx': metaPolicyIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 AI 정보 업데이트
     * @returns DefaultHttpResponse_DetailPolicyAiInfoResponse_ OK
     * @throws ApiError
     */
    public static updatePolicyAiInfoUsingPut({
        metaPolicyIdIdx,
        requestBody,
    }: {
        /**
         * 정책 id idx
         */
        metaPolicyIdIdx: string,
        requestBody?: UpdatePolicyAiInfoRequest,
    }): CancelablePromise<DefaultHttpResponse_DetailPolicyAiInfoResponse_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/allows/policy/{meta_policy_id_idx}/ai',
            path: {
                'meta_policy_id_idx': metaPolicyIdIdx,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 관련 정책 목록 조회(최대 7개)
     * @returns DefaultHttpResponse_ListRelatedMetaPolicyResponse_ OK
     * @throws ApiError
     */
    public static getRelatedMetaPolicyListUsingGet1({
        metaPolicyIdIdx,
    }: {
        /**
         * 정책 id idx
         */
        metaPolicyIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_ListRelatedMetaPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy/{meta_policy_id_idx}/related',
            path: {
                'meta_policy_id_idx': metaPolicyIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책과 관련된 커뮤니티 조회
     * @returns DefaultHttpResponse_ListPolicyRelatedCommunityResponse_ OK
     * @throws ApiError
     */
    public static getPolicyRelatedCommunityListUsingGet({
        policyId,
    }: {
        /**
         * 정책 ID
         */
        policyId: number,
    }): CancelablePromise<DefaultHttpResponse_ListPolicyRelatedCommunityResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy/{policy_id}/community',
            path: {
                'policy_id': policyId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 내 상황에 맞는 정책 카테고리
     * @returns DefaultHttpResponse_ListPolicyCaseCategoryResponse_ OK
     * @throws ApiError
     */
    public static getListPolicyCaseCategoryUsingGet({
        categoryTypeCd,
    }: {
        /**
         * 카테고리 유형 // POLICY_CASE_TYPE
         */
        categoryTypeCd?: string,
    }): CancelablePromise<DefaultHttpResponse_ListPolicyCaseCategoryResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy-case/category',
            query: {
                'category_type_cd': categoryTypeCd,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 지역 좌표 목록 조회
     * @returns DefaultHttpResponse_ListPolicyCodeGeocodingResponse_ OK
     * @throws ApiError
     */
    public static getPolicyCodeGeocodingListUsingGet(): CancelablePromise<DefaultHttpResponse_ListPolicyCodeGeocodingResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy-code-geocoding',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 정책 코드 목록
     * @returns DefaultHttpResponse_ListWelloPolicyCodeResponse_ OK
     * @throws ApiError
     */
    public static welloPolicyCodeListUsingGet({
        level,
        welloPolicyCodeTypeCd,
        keyList,
        userDisplayYn,
        welloPolicyCodeFilterCd,
    }: {
        /**
         * 차수
         */
        level: number,
        /**
         * 웰로 정책 코드 타입 - 코드(WELLO_POLICY_CODE_TYPE)
         */
        welloPolicyCodeTypeCd: string,
        /**
         * 검색 코드 목록(,)
         */
        keyList?: string,
        /**
         * 사용자 노출 여부
         */
        userDisplayYn?: boolean,
        /**
         * 웰로 정책 코드 필터 - 코드(WELLO_POLICY_CODE_FILTER)
         */
        welloPolicyCodeFilterCd?: string,
    }): CancelablePromise<DefaultHttpResponse_ListWelloPolicyCodeResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-policy-code',
            query: {
                'key_list': keyList,
                'level': level,
                'user_display_yn': userDisplayYn,
                'wello_policy_code_filter_cd': welloPolicyCodeFilterCd,
                'wello_policy_code_type_cd': welloPolicyCodeTypeCd,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 정책 코드 전체 목록
     * @returns DefaultHttpResponse_ListWelloPolicyCodeAllResponse_ OK
     * @throws ApiError
     */
    public static welloPolicyCodeAllListUsingGet(): CancelablePromise<DefaultHttpResponse_ListWelloPolicyCodeAllResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-policy-code/all',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 정책 코드 육아생활 목록
     * @returns DefaultHttpResponse_ListWelloPolicyCodeAllResponse_ OK
     * @throws ApiError
     */
    public static welloPolicyCodeBabyListUsingGet(): CancelablePromise<DefaultHttpResponse_ListWelloPolicyCodeAllResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-policy-code/baby',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * IP로 현재 위치의 지역정보 조회
     * @returns DefaultHttpResponse_ReverseGeocodingResponse_ OK
     * @throws ApiError
     */
    public static geolocationUsingGet({
        ip,
    }: {
        /**
         * IP
         */
        ip: string,
    }): CancelablePromise<DefaultHttpResponse_ReverseGeocodingResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-policy-code/geolocation',
            query: {
                'ip': ip,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * KB 정책 코드 목록
     * @returns DefaultHttpResponse_ListWelloPolicyCodeKbResponse_ OK
     * @throws ApiError
     */
    public static kbWelloPolicyCodeAllListUsingGet(): CancelablePromise<DefaultHttpResponse_ListWelloPolicyCodeKbResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-policy-code/kb',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 경도&위도로 현재 위치의 지역정보 조회
     * @returns DefaultHttpResponse_ReverseGeocodingResponse_ OK
     * @throws ApiError
     */
    public static reverseGeocodingUsingGet({
        coords,
    }: {
        /**
         * 입력 좌표
         */
        coords: string,
    }): CancelablePromise<DefaultHttpResponse_ReverseGeocodingResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-policy-code/reverse-geocoding',
            query: {
                'coords': coords,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 비회원 트래킹 정보 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveWelloTrackingUsingPost1({
        requestBody,
    }: {
        requestBody?: SaveWelloTrackRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/wello-track',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
