/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ApiError } from './core/ApiError';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { BenefitNotificationApplicationInfoSaveRequest } from './models/BenefitNotificationApplicationInfoSaveRequest';
export type { BenefitNotificationFitAnalysisResultResponse } from './models/BenefitNotificationFitAnalysisResultResponse';
export type { BestContentsPolicyDto } from './models/BestContentsPolicyDto';
export type { BestContentsWishlistResponse } from './models/BestContentsWishlistResponse';
export type { ChangeCommunityFreeTalkRequest } from './models/ChangeCommunityFreeTalkRequest';
export type { ChangeCommunityPolicyTalkRequest } from './models/ChangeCommunityPolicyTalkRequest';
export type { CheckCertificationByContractRequest } from './models/CheckCertificationByContractRequest';
export type { CommunityFreeTalkFeedbackResponse } from './models/CommunityFreeTalkFeedbackResponse';
export type { CommunityPolicyTalkFeedbackResponse } from './models/CommunityPolicyTalkFeedbackResponse';
export type { CommunityWelloNewsFeedbackResponse } from './models/CommunityWelloNewsFeedbackResponse';
export type { CreateCommunityFreeTalkRequest } from './models/CreateCommunityFreeTalkRequest';
export type { CreateCommunityFreeTalkResponse } from './models/CreateCommunityFreeTalkResponse';
export type { CreateCommunityPolicyTalkRequest } from './models/CreateCommunityPolicyTalkRequest';
export type { CreateCommunityPolicyTalkResponse } from './models/CreateCommunityPolicyTalkResponse';
export type { CreateCommunityReportRequest } from './models/CreateCommunityReportRequest';
export type { CreateMemberFamilyInfoRequest } from './models/CreateMemberFamilyInfoRequest';
export type { CreateWelloMemberFamilyResponse } from './models/CreateWelloMemberFamilyResponse';
export type { DecryptMemberVerificationRequest } from './models/DecryptMemberVerificationRequest';
export type { DecryptMemberVerificationResponse } from './models/DecryptMemberVerificationResponse';
export type { DefaultHttpResponse } from './models/DefaultHttpResponse';
export type { DefaultHttpResponse_BenefitNotificationFitAnalysisResultResponse_ } from './models/DefaultHttpResponse_BenefitNotificationFitAnalysisResultResponse_';
export type { DefaultHttpResponse_BestContentsWishlistResponse_ } from './models/DefaultHttpResponse_BestContentsWishlistResponse_';
export type { DefaultHttpResponse_boolean_ } from './models/DefaultHttpResponse_boolean_';
export type { DefaultHttpResponse_CommunityFreeTalkFeedbackResponse_ } from './models/DefaultHttpResponse_CommunityFreeTalkFeedbackResponse_';
export type { DefaultHttpResponse_CommunityPolicyTalkFeedbackResponse_ } from './models/DefaultHttpResponse_CommunityPolicyTalkFeedbackResponse_';
export type { DefaultHttpResponse_CommunityWelloNewsFeedbackResponse_ } from './models/DefaultHttpResponse_CommunityWelloNewsFeedbackResponse_';
export type { DefaultHttpResponse_CreateCommunityFreeTalkResponse_ } from './models/DefaultHttpResponse_CreateCommunityFreeTalkResponse_';
export type { DefaultHttpResponse_CreateCommunityPolicyTalkResponse_ } from './models/DefaultHttpResponse_CreateCommunityPolicyTalkResponse_';
export type { DefaultHttpResponse_CreateWelloMemberFamilyResponse_ } from './models/DefaultHttpResponse_CreateWelloMemberFamilyResponse_';
export type { DefaultHttpResponse_DecryptMemberVerificationResponse_ } from './models/DefaultHttpResponse_DecryptMemberVerificationResponse_';
export type { DefaultHttpResponse_DetailBenefitNotificationFitAnalysisResponse_ } from './models/DefaultHttpResponse_DetailBenefitNotificationFitAnalysisResponse_';
export type { DefaultHttpResponse_DetailBenefitNotificationMainInfoResponse_ } from './models/DefaultHttpResponse_DetailBenefitNotificationMainInfoResponse_';
export type { DefaultHttpResponse_DetailBenefitNotificationPublicServiceDetailUrlResponse_ } from './models/DefaultHttpResponse_DetailBenefitNotificationPublicServiceDetailUrlResponse_';
export type { DefaultHttpResponse_DetailBenefitNotificationServiceUsageAgreementUrlResponse_ } from './models/DefaultHttpResponse_DetailBenefitNotificationServiceUsageAgreementUrlResponse_';
export type { DefaultHttpResponse_DetailBestContentsResponse_ } from './models/DefaultHttpResponse_DetailBestContentsResponse_';
export type { DefaultHttpResponse_DetailCommunityFreeTalkResponse_ } from './models/DefaultHttpResponse_DetailCommunityFreeTalkResponse_';
export type { DefaultHttpResponse_DetailCommunityPolicyTalkResponse_ } from './models/DefaultHttpResponse_DetailCommunityPolicyTalkResponse_';
export type { DefaultHttpResponse_DetailCommunityWelloNewsResponse_ } from './models/DefaultHttpResponse_DetailCommunityWelloNewsResponse_';
export type { DefaultHttpResponse_DetailDisplayBannerResponse_ } from './models/DefaultHttpResponse_DetailDisplayBannerResponse_';
export type { DefaultHttpResponse_DetailEmergencyRoomResponse_ } from './models/DefaultHttpResponse_DetailEmergencyRoomResponse_';
export type { DefaultHttpResponse_DetailEVoucherProviderResponse_ } from './models/DefaultHttpResponse_DetailEVoucherProviderResponse_';
export type { DefaultHttpResponse_DetailHometownNewsResponse_ } from './models/DefaultHttpResponse_DetailHometownNewsResponse_';
export type { DefaultHttpResponse_DetailInquiryResponse_ } from './models/DefaultHttpResponse_DetailInquiryResponse_';
export type { DefaultHttpResponse_DetailMemberAccountResponse_ } from './models/DefaultHttpResponse_DetailMemberAccountResponse_';
export type { DefaultHttpResponse_DetailMemberActionCountResponse_ } from './models/DefaultHttpResponse_DetailMemberActionCountResponse_';
export type { DefaultHttpResponse_DetailMemberResponse_ } from './models/DefaultHttpResponse_DetailMemberResponse_';
export type { DefaultHttpResponse_DetailMemberTermAgreementResponse_ } from './models/DefaultHttpResponse_DetailMemberTermAgreementResponse_';
export type { DefaultHttpResponse_DetailMemberVerificationCiResponse_ } from './models/DefaultHttpResponse_DetailMemberVerificationCiResponse_';
export type { DefaultHttpResponse_DetailMemberVerificationInfoResponse_ } from './models/DefaultHttpResponse_DetailMemberVerificationInfoResponse_';
export type { DefaultHttpResponse_DetailMemberVerificationResponse_ } from './models/DefaultHttpResponse_DetailMemberVerificationResponse_';
export type { DefaultHttpResponse_DetailMemberWelloLocationServiceConsentResponse_ } from './models/DefaultHttpResponse_DetailMemberWelloLocationServiceConsentResponse_';
export type { DefaultHttpResponse_DetailMemberWelloPrivateDataConsentResponse_ } from './models/DefaultHttpResponse_DetailMemberWelloPrivateDataConsentResponse_';
export type { DefaultHttpResponse_DetailMetaPolicyResponse_ } from './models/DefaultHttpResponse_DetailMetaPolicyResponse_';
export type { DefaultHttpResponse_DetailMyPointResponse_ } from './models/DefaultHttpResponse_DetailMyPointResponse_';
export type { DefaultHttpResponse_DetailPointBalanceResponse_ } from './models/DefaultHttpResponse_DetailPointBalanceResponse_';
export type { DefaultHttpResponse_DetailWelloCommentResponse_ } from './models/DefaultHttpResponse_DetailWelloCommentResponse_';
export type { DefaultHttpResponse_DuplicateMemberInfoResponse_ } from './models/DefaultHttpResponse_DuplicateMemberInfoResponse_';
export type { DefaultHttpResponse_EmergencyRoomWishlistResponse_ } from './models/DefaultHttpResponse_EmergencyRoomWishlistResponse_';
export type { DefaultHttpResponse_EVoucherFeedbackResponse_ } from './models/DefaultHttpResponse_EVoucherFeedbackResponse_';
export type { DefaultHttpResponse_HometownNewsWishlistResponse_ } from './models/DefaultHttpResponse_HometownNewsWishlistResponse_';
export type { DefaultHttpResponse_InterestFilterResponse_ } from './models/DefaultHttpResponse_InterestFilterResponse_';
export type { DefaultHttpResponse_InvitedMemberCountResponse_ } from './models/DefaultHttpResponse_InvitedMemberCountResponse_';
export type { DefaultHttpResponse_ListBestContentsResponse_ } from './models/DefaultHttpResponse_ListBestContentsResponse_';
export type { DefaultHttpResponse_ListChildcareRelatedPolicyResponse_ } from './models/DefaultHttpResponse_ListChildcareRelatedPolicyResponse_';
export type { DefaultHttpResponse_ListCommunityFreeTalkResponse_ } from './models/DefaultHttpResponse_ListCommunityFreeTalkResponse_';
export type { DefaultHttpResponse_ListCommunityPolicyTalkResponse_ } from './models/DefaultHttpResponse_ListCommunityPolicyTalkResponse_';
export type { DefaultHttpResponse_ListCommunityTalkResponse_ } from './models/DefaultHttpResponse_ListCommunityTalkResponse_';
export type { DefaultHttpResponse_ListCommunityWelloNewsResponse_ } from './models/DefaultHttpResponse_ListCommunityWelloNewsResponse_';
export type { DefaultHttpResponse_ListCommunityWishlistResponse_ } from './models/DefaultHttpResponse_ListCommunityWishlistResponse_';
export type { DefaultHttpResponse_ListDislikeResponse_ } from './models/DefaultHttpResponse_ListDislikeResponse_';
export type { DefaultHttpResponse_ListEmergencyRoomResponse_ } from './models/DefaultHttpResponse_ListEmergencyRoomResponse_';
export type { DefaultHttpResponse_ListEVoucherProviderResponse_ } from './models/DefaultHttpResponse_ListEVoucherProviderResponse_';
export type { DefaultHttpResponse_ListHometownNewsResponse_ } from './models/DefaultHttpResponse_ListHometownNewsResponse_';
export type { DefaultHttpResponse_ListInquiryResponse_ } from './models/DefaultHttpResponse_ListInquiryResponse_';
export type { DefaultHttpResponse_ListLatestCommunityResponse_ } from './models/DefaultHttpResponse_ListLatestCommunityResponse_';
export type { DefaultHttpResponse_ListLikeContentsResponse_ } from './models/DefaultHttpResponse_ListLikeContentsResponse_';
export type { DefaultHttpResponse_ListLikeContentsTypeResponse_ } from './models/DefaultHttpResponse_ListLikeContentsTypeResponse_';
export type { DefaultHttpResponse_ListLiveWelloCommentResponse_ } from './models/DefaultHttpResponse_ListLiveWelloCommentResponse_';
export type { DefaultHttpResponse_ListMemberAccountResponse_ } from './models/DefaultHttpResponse_ListMemberAccountResponse_';
export type { DefaultHttpResponse_ListMemberCardResponse_ } from './models/DefaultHttpResponse_ListMemberCardResponse_';
export type { DefaultHttpResponse_ListMetaPolicyResponse_ } from './models/DefaultHttpResponse_ListMetaPolicyResponse_';
export type { DefaultHttpResponse_ListMyCommunityResponse_ } from './models/DefaultHttpResponse_ListMyCommunityResponse_';
export type { DefaultHttpResponse_ListMyCommunityTypeResponse_ } from './models/DefaultHttpResponse_ListMyCommunityTypeResponse_';
export type { DefaultHttpResponse_ListMyMemberWelloBlockResponse_ } from './models/DefaultHttpResponse_ListMyMemberWelloBlockResponse_';
export type { DefaultHttpResponse_ListMyWelloCommentResponse_ } from './models/DefaultHttpResponse_ListMyWelloCommentResponse_';
export type { DefaultHttpResponse_ListNeighborPolicyResponse_ } from './models/DefaultHttpResponse_ListNeighborPolicyResponse_';
export type { DefaultHttpResponse_ListNewBestContentsResponse_ } from './models/DefaultHttpResponse_ListNewBestContentsResponse_';
export type { DefaultHttpResponse_ListPaymentHisResponse_ } from './models/DefaultHttpResponse_ListPaymentHisResponse_';
export type { DefaultHttpResponse_ListPeerGroupPolicyResponse_ } from './models/DefaultHttpResponse_ListPeerGroupPolicyResponse_';
export type { DefaultHttpResponse_ListPointAttendanceResponse_ } from './models/DefaultHttpResponse_ListPointAttendanceResponse_';
export type { DefaultHttpResponse_ListPointHistoryResponse_ } from './models/DefaultHttpResponse_ListPointHistoryResponse_';
export type { DefaultHttpResponse_ListPolicyResponse_ } from './models/DefaultHttpResponse_ListPolicyResponse_';
export type { DefaultHttpResponse_ListPopularBestContentsResponse_ } from './models/DefaultHttpResponse_ListPopularBestContentsResponse_';
export type { DefaultHttpResponse_ListPopularCommunityFreeTalkResponse_ } from './models/DefaultHttpResponse_ListPopularCommunityFreeTalkResponse_';
export type { DefaultHttpResponse_ListPopularCommunityPolicyTalkResponse_ } from './models/DefaultHttpResponse_ListPopularCommunityPolicyTalkResponse_';
export type { DefaultHttpResponse_ListPopularHometownNewsResponse_ } from './models/DefaultHttpResponse_ListPopularHometownNewsResponse_';
export type { DefaultHttpResponse_ListRecentPolicyResponse_ } from './models/DefaultHttpResponse_ListRecentPolicyResponse_';
export type { DefaultHttpResponse_ListRecommendPolicyByRecentResponse_ } from './models/DefaultHttpResponse_ListRecommendPolicyByRecentResponse_';
export type { DefaultHttpResponse_ListRecommendPolicyProvisionTypeResponse_ } from './models/DefaultHttpResponse_ListRecommendPolicyProvisionTypeResponse_';
export type { DefaultHttpResponse_ListRecommendPolicyResponse_ } from './models/DefaultHttpResponse_ListRecommendPolicyResponse_';
export type { DefaultHttpResponse_ListRelatedMetaPolicyResponse_ } from './models/DefaultHttpResponse_ListRelatedMetaPolicyResponse_';
export type { DefaultHttpResponse_ListS3FileUploadResponse_ } from './models/DefaultHttpResponse_ListS3FileUploadResponse_';
export type { DefaultHttpResponse_ListWelloAppPushResponse_ } from './models/DefaultHttpResponse_ListWelloAppPushResponse_';
export type { DefaultHttpResponse_ListWelloCommentResponse_ } from './models/DefaultHttpResponse_ListWelloCommentResponse_';
export type { DefaultHttpResponse_ListWelloMemberFamilyResponse_ } from './models/DefaultHttpResponse_ListWelloMemberFamilyResponse_';
export type { DefaultHttpResponse_ListWelloPolicyApplyResponse_ } from './models/DefaultHttpResponse_ListWelloPolicyApplyResponse_';
export type { DefaultHttpResponse_MemberContentCountResponse_ } from './models/DefaultHttpResponse_MemberContentCountResponse_';
export type { DefaultHttpResponse_MemberFilterResponse_ } from './models/DefaultHttpResponse_MemberFilterResponse_';
export type { DefaultHttpResponse_MemberOAuthResponse_ } from './models/DefaultHttpResponse_MemberOAuthResponse_';
export type { DefaultHttpResponse_MetaPolicyWishlistResponse_ } from './models/DefaultHttpResponse_MetaPolicyWishlistResponse_';
export type { DefaultHttpResponse_OAuthTokenResponse_ } from './models/DefaultHttpResponse_OAuthTokenResponse_';
export type { DefaultHttpResponse_object_ } from './models/DefaultHttpResponse_object_';
export type { DefaultHttpResponse_PointActivityResponse_ } from './models/DefaultHttpResponse_PointActivityResponse_';
export type { DefaultHttpResponse_ProvisionTypeRankResponse_ } from './models/DefaultHttpResponse_ProvisionTypeRankResponse_';
export type { DefaultHttpResponse_RecommendInterestPolicySearchResponse_ } from './models/DefaultHttpResponse_RecommendInterestPolicySearchResponse_';
export type { DefaultHttpResponse_RecommendPolicySearchResponse_ } from './models/DefaultHttpResponse_RecommendPolicySearchResponse_';
export type { DefaultHttpResponse_ReverseGeocodingResponse_ } from './models/DefaultHttpResponse_ReverseGeocodingResponse_';
export type { DefaultHttpResponse_S3FileCopyResponse_ } from './models/DefaultHttpResponse_S3FileCopyResponse_';
export type { DefaultHttpResponse_S3FileUploadResponse_ } from './models/DefaultHttpResponse_S3FileUploadResponse_';
export type { DefaultHttpResponse_SaveMemberFilterResponse_ } from './models/DefaultHttpResponse_SaveMemberFilterResponse_';
export type { DefaultHttpResponse_SaveWelloCommentResponse_ } from './models/DefaultHttpResponse_SaveWelloCommentResponse_';
export type { DefaultHttpResponse_SituationListResponse_ } from './models/DefaultHttpResponse_SituationListResponse_';
export type { DefaultHttpResponse_SituationPolicyListResponse_ } from './models/DefaultHttpResponse_SituationPolicyListResponse_';
export type { DefaultHttpResponse_TotalPaymentHisResponse_ } from './models/DefaultHttpResponse_TotalPaymentHisResponse_';
export type { DefaultHttpResponse_Void_ } from './models/DefaultHttpResponse_Void_';
export type { DefaultHttpResponse_VoucherProviderWishlistResponse_ } from './models/DefaultHttpResponse_VoucherProviderWishlistResponse_';
export type { DefaultHttpResponse_WelloFeedbackResponse_ } from './models/DefaultHttpResponse_WelloFeedbackResponse_';
export type { DefaultHttpResponse_WelloPickListResponse_ } from './models/DefaultHttpResponse_WelloPickListResponse_';
export type { DefaultHttpResponse_WelloPickPolicyListResponse_ } from './models/DefaultHttpResponse_WelloPickPolicyListResponse_';
export type { DefaultHttpResponse_WelloPolicyApplyCountResponse_ } from './models/DefaultHttpResponse_WelloPolicyApplyCountResponse_';
export type { DeleteMemberInfoRequest } from './models/DeleteMemberInfoRequest';
export type { DeleteWelloMemberFamilyRequest } from './models/DeleteWelloMemberFamilyRequest';
export type { DetailBenefitNotificationFitAnalysisResponse } from './models/DetailBenefitNotificationFitAnalysisResponse';
export type { DetailBenefitNotificationMainInfoResponse } from './models/DetailBenefitNotificationMainInfoResponse';
export type { DetailBenefitNotificationPublicServiceDetailUrlResponse } from './models/DetailBenefitNotificationPublicServiceDetailUrlResponse';
export type { DetailBenefitNotificationServiceUsageAgreementUrlResponse } from './models/DetailBenefitNotificationServiceUsageAgreementUrlResponse';
export type { DetailBestContentsResponse } from './models/DetailBestContentsResponse';
export type { DetailCommunityFreeTalkResponse } from './models/DetailCommunityFreeTalkResponse';
export type { DetailCommunityPolicyTalkResponse } from './models/DetailCommunityPolicyTalkResponse';
export type { DetailCommunityWelloNewsResponse } from './models/DetailCommunityWelloNewsResponse';
export type { DetailDisplayBannerResponse } from './models/DetailDisplayBannerResponse';
export type { DetailEmergencyRoomResponse } from './models/DetailEmergencyRoomResponse';
export type { DetailEVoucherProviderResponse } from './models/DetailEVoucherProviderResponse';
export type { DetailHometownNewsDto } from './models/DetailHometownNewsDto';
export type { DetailHometownNewsResponse } from './models/DetailHometownNewsResponse';
export type { DetailInquiryResponse } from './models/DetailInquiryResponse';
export type { DetailMemberAccountResponse } from './models/DetailMemberAccountResponse';
export type { DetailMemberActionCountResponse } from './models/DetailMemberActionCountResponse';
export type { DetailMemberResponse } from './models/DetailMemberResponse';
export type { DetailMemberTermAgreementResponse } from './models/DetailMemberTermAgreementResponse';
export type { DetailMemberVerificationCiResponse } from './models/DetailMemberVerificationCiResponse';
export type { DetailMemberVerificationInfoResponse } from './models/DetailMemberVerificationInfoResponse';
export type { DetailMemberVerificationResponse } from './models/DetailMemberVerificationResponse';
export type { DetailMemberWelloLocationServiceConsentResponse } from './models/DetailMemberWelloLocationServiceConsentResponse';
export type { DetailMemberWelloPrivateDataConsentResponse } from './models/DetailMemberWelloPrivateDataConsentResponse';
export type { DetailMetaPolicyResponse } from './models/DetailMetaPolicyResponse';
export type { DetailMyPointResponse } from './models/DetailMyPointResponse';
export type { DetailPointBalanceResponse } from './models/DetailPointBalanceResponse';
export type { DetailWelloCommentResponse } from './models/DetailWelloCommentResponse';
export type { DuplicateMemberInfoDto } from './models/DuplicateMemberInfoDto';
export type { DuplicateMemberInfoResponse } from './models/DuplicateMemberInfoResponse';
export type { EmergencyRoomTimeDto } from './models/EmergencyRoomTimeDto';
export type { EmergencyRoomWishlistResponse } from './models/EmergencyRoomWishlistResponse';
export type { EVoucherFeedbackResponse } from './models/EVoucherFeedbackResponse';
export type { FileHttpResponse } from './models/FileHttpResponse';
export type { FitSaveMemberFilterRequest } from './models/FitSaveMemberFilterRequest';
export type { HometownNewsWishlistResponse } from './models/HometownNewsWishlistResponse';
export type { InquiryRequest } from './models/InquiryRequest';
export type { InterestFilterResponse } from './models/InterestFilterResponse';
export type { InterestSaveMemberFilterRequest } from './models/InterestSaveMemberFilterRequest';
export type { InvitedMemberCountResponse } from './models/InvitedMemberCountResponse';
export type { KbMemberCheckRequest } from './models/KbMemberCheckRequest';
export type { ListBenefitNotificationFitAnalysisResultDto } from './models/ListBenefitNotificationFitAnalysisResultDto';
export type { ListBestContentsDto } from './models/ListBestContentsDto';
export type { ListBestContentsResponse } from './models/ListBestContentsResponse';
export type { ListCareerDto } from './models/ListCareerDto';
export type { ListChildcareRelatedPolicyDto } from './models/ListChildcareRelatedPolicyDto';
export type { ListChildcareRelatedPolicyResponse } from './models/ListChildcareRelatedPolicyResponse';
export type { ListCommentRelationPolicyDto } from './models/ListCommentRelationPolicyDto';
export type { ListCommunityDto } from './models/ListCommunityDto';
export type { ListCommunityFreeTalkDto } from './models/ListCommunityFreeTalkDto';
export type { ListCommunityFreeTalkResponse } from './models/ListCommunityFreeTalkResponse';
export type { ListCommunityPolicyTalkDto } from './models/ListCommunityPolicyTalkDto';
export type { ListCommunityPolicyTalkRelationPolicyDto } from './models/ListCommunityPolicyTalkRelationPolicyDto';
export type { ListCommunityPolicyTalkResponse } from './models/ListCommunityPolicyTalkResponse';
export type { ListCommunityTalkDto } from './models/ListCommunityTalkDto';
export type { ListCommunityTalkResponse } from './models/ListCommunityTalkResponse';
export type { ListCommunityTypeDto } from './models/ListCommunityTypeDto';
export type { ListCommunityWelloNewsDto } from './models/ListCommunityWelloNewsDto';
export type { ListCommunityWelloNewsResponse } from './models/ListCommunityWelloNewsResponse';
export type { ListCommunityWishlistDto } from './models/ListCommunityWishlistDto';
export type { ListCommunityWishlistResponse } from './models/ListCommunityWishlistResponse';
export type { ListDislikeDto } from './models/ListDislikeDto';
export type { ListDislikeResponse } from './models/ListDislikeResponse';
export type { ListEmergencyRoomDto } from './models/ListEmergencyRoomDto';
export type { ListEmergencyRoomResponse } from './models/ListEmergencyRoomResponse';
export type { ListEmergencyRoomWishlistDto } from './models/ListEmergencyRoomWishlistDto';
export type { ListEVoucherProviderCategoryDto } from './models/ListEVoucherProviderCategoryDto';
export type { ListEVoucherProviderDto } from './models/ListEVoucherProviderDto';
export type { ListEVoucherProviderResponse } from './models/ListEVoucherProviderResponse';
export type { ListHometownNewsDto } from './models/ListHometownNewsDto';
export type { ListHometownNewsResponse } from './models/ListHometownNewsResponse';
export type { ListInquiryDto } from './models/ListInquiryDto';
export type { ListInquiryResponse } from './models/ListInquiryResponse';
export type { ListLatestCommunityResponse } from './models/ListLatestCommunityResponse';
export type { ListLikeContentsDto } from './models/ListLikeContentsDto';
export type { ListLikeContentsResponse } from './models/ListLikeContentsResponse';
export type { ListLikeContentsTypeDto } from './models/ListLikeContentsTypeDto';
export type { ListLikeContentsTypeResponse } from './models/ListLikeContentsTypeResponse';
export type { ListLiveWelloCommentDto } from './models/ListLiveWelloCommentDto';
export type { ListLiveWelloCommentResponse } from './models/ListLiveWelloCommentResponse';
export type { ListMemberAccountDto } from './models/ListMemberAccountDto';
export type { ListMemberAccountResponse } from './models/ListMemberAccountResponse';
export type { ListMemberCardDto } from './models/ListMemberCardDto';
export type { ListMemberCardResponse } from './models/ListMemberCardResponse';
export type { ListMetaPolicyDto } from './models/ListMetaPolicyDto';
export type { ListMetaPolicyResponse } from './models/ListMetaPolicyResponse';
export type { ListMyCommunityResponse } from './models/ListMyCommunityResponse';
export type { ListMyCommunityTypeResponse } from './models/ListMyCommunityTypeResponse';
export type { ListMyMemberWelloBlockDto } from './models/ListMyMemberWelloBlockDto';
export type { ListMyMemberWelloBlockResponse } from './models/ListMyMemberWelloBlockResponse';
export type { ListMyWelloCommentResponse } from './models/ListMyWelloCommentResponse';
export type { ListNeighborPolicyDto } from './models/ListNeighborPolicyDto';
export type { ListNeighborPolicyResponse } from './models/ListNeighborPolicyResponse';
export type { ListNewBestContentsResponse } from './models/ListNewBestContentsResponse';
export type { ListPaymentHisDto } from './models/ListPaymentHisDto';
export type { ListPaymentHisResponse } from './models/ListPaymentHisResponse';
export type { ListPeerGroupPolicyResponse } from './models/ListPeerGroupPolicyResponse';
export type { ListPointAttendanceDto } from './models/ListPointAttendanceDto';
export type { ListPointAttendanceResponse } from './models/ListPointAttendanceResponse';
export type { ListPointHistoryDto } from './models/ListPointHistoryDto';
export type { ListPointHistoryResponse } from './models/ListPointHistoryResponse';
export type { ListPolicyDto } from './models/ListPolicyDto';
export type { ListPolicyResponse } from './models/ListPolicyResponse';
export type { ListPopularBestContentsDto } from './models/ListPopularBestContentsDto';
export type { ListPopularBestContentsResponse } from './models/ListPopularBestContentsResponse';
export type { ListPopularCommunityFreeTalkDto } from './models/ListPopularCommunityFreeTalkDto';
export type { ListPopularCommunityFreeTalkResponse } from './models/ListPopularCommunityFreeTalkResponse';
export type { ListPopularCommunityPolicyTalkDto } from './models/ListPopularCommunityPolicyTalkDto';
export type { ListPopularCommunityPolicyTalkResponse } from './models/ListPopularCommunityPolicyTalkResponse';
export type { ListPopularHometownNewsDto } from './models/ListPopularHometownNewsDto';
export type { ListPopularHometownNewsResponse } from './models/ListPopularHometownNewsResponse';
export type { ListRecentPolicyDto } from './models/ListRecentPolicyDto';
export type { ListRecentPolicyResponse } from './models/ListRecentPolicyResponse';
export type { ListRecommendPolicyByRecentDto } from './models/ListRecommendPolicyByRecentDto';
export type { ListRecommendPolicyByRecentResponse } from './models/ListRecommendPolicyByRecentResponse';
export type { ListRecommendPolicyDto } from './models/ListRecommendPolicyDto';
export type { ListRecommendPolicyProvisionTypeResponse } from './models/ListRecommendPolicyProvisionTypeResponse';
export type { ListRecommendPolicyResponse } from './models/ListRecommendPolicyResponse';
export type { ListRelatedMetaPolicyDto } from './models/ListRelatedMetaPolicyDto';
export type { ListRelatedMetaPolicyResponse } from './models/ListRelatedMetaPolicyResponse';
export type { ListS3FileUploadDto } from './models/ListS3FileUploadDto';
export type { ListS3FileUploadResponse } from './models/ListS3FileUploadResponse';
export type { ListSpoCareerDto } from './models/ListSpoCareerDto';
export type { ListWelloAppPushDto } from './models/ListWelloAppPushDto';
export type { ListWelloAppPushResponse } from './models/ListWelloAppPushResponse';
export type { ListWelloCommentDto } from './models/ListWelloCommentDto';
export type { ListWelloCommentResponse } from './models/ListWelloCommentResponse';
export type { ListWelloMemberFamilyDto } from './models/ListWelloMemberFamilyDto';
export type { ListWelloMemberFamilyResponse } from './models/ListWelloMemberFamilyResponse';
export type { ListWelloPickDto } from './models/ListWelloPickDto';
export type { ListWelloPolicyApplyDto } from './models/ListWelloPolicyApplyDto';
export type { ListWelloPolicyApplyResponse } from './models/ListWelloPolicyApplyResponse';
export type { MemberContentCountResponse } from './models/MemberContentCountResponse';
export type { MemberFilterResponse } from './models/MemberFilterResponse';
export type { MemberOAuthResponse } from './models/MemberOAuthResponse';
export type { MemberOAuthWithdrawRequest } from './models/MemberOAuthWithdrawRequest';
export type { MessageReceiveUpdateRequest } from './models/MessageReceiveUpdateRequest';
export type { MetaCodeDto } from './models/MetaCodeDto';
export type { MetaPolicySearchDto } from './models/MetaPolicySearchDto';
export type { MetaPolicyWishlistResponse } from './models/MetaPolicyWishlistResponse';
export type { NiceSafeKeyRequest } from './models/NiceSafeKeyRequest';
export type { OAuthTokenResponse } from './models/OAuthTokenResponse';
export type { PointActivityResponse } from './models/PointActivityResponse';
export type { ProvisionTypeRankDto } from './models/ProvisionTypeRankDto';
export type { ProvisionTypeRankResponse } from './models/ProvisionTypeRankResponse';
export type { RecommendInterestPolicySearchDto } from './models/RecommendInterestPolicySearchDto';
export type { RecommendInterestPolicySearchResponse } from './models/RecommendInterestPolicySearchResponse';
export type { RecommendPolicySearchDto } from './models/RecommendPolicySearchDto';
export type { RecommendPolicySearchResponse } from './models/RecommendPolicySearchResponse';
export type { ReverseGeocodingResponse } from './models/ReverseGeocodingResponse';
export type { S3FileCopyDto } from './models/S3FileCopyDto';
export type { S3FileCopyRequest } from './models/S3FileCopyRequest';
export type { S3FileCopyResponse } from './models/S3FileCopyResponse';
export type { S3FileDeleteRequest } from './models/S3FileDeleteRequest';
export type { S3FileRenameRequest } from './models/S3FileRenameRequest';
export type { S3FileUploadResponse } from './models/S3FileUploadResponse';
export type { SaveAndDeleteWelloLikeRequest } from './models/SaveAndDeleteWelloLikeRequest';
export type { SaveKbFilterRequest } from './models/SaveKbFilterRequest';
export type { SaveMemberFilterCodeDto } from './models/SaveMemberFilterCodeDto';
export type { SaveMemberFilterResponse } from './models/SaveMemberFilterResponse';
export type { SaveMemberSignUpRequest } from './models/SaveMemberSignUpRequest';
export type { SaveRegionFilterRequest } from './models/SaveRegionFilterRequest';
export type { SaveReportRequest } from './models/SaveReportRequest';
export type { SaveWelloCommentReportRequest } from './models/SaveWelloCommentReportRequest';
export type { SaveWelloCommentRequest } from './models/SaveWelloCommentRequest';
export type { SaveWelloCommentResponse } from './models/SaveWelloCommentResponse';
export type { SaveWelloDeviceInfoRequest } from './models/SaveWelloDeviceInfoRequest';
export type { SaveWelloEventParticipationRequest } from './models/SaveWelloEventParticipationRequest';
export type { SaveWelloHelperRequest } from './models/SaveWelloHelperRequest';
export type { SaveWelloTrackRequest } from './models/SaveWelloTrackRequest';
export type { SendCertificationByCellPhoneRequest } from './models/SendCertificationByCellPhoneRequest';
export type { SituationListDto } from './models/SituationListDto';
export type { SituationListResponse } from './models/SituationListResponse';
export type { SituationPolicyListResponse } from './models/SituationPolicyListResponse';
export type { StreamingResponseBody } from './models/StreamingResponseBody';
export type { TotalPaymentHisResponse } from './models/TotalPaymentHisResponse';
export type { UpdateMemberInfoRequestV3 } from './models/UpdateMemberInfoRequestV3';
export type { UpdateMemberTermAgreementRequest } from './models/UpdateMemberTermAgreementRequest';
export type { UpdateMemberVerificationRequest } from './models/UpdateMemberVerificationRequest';
export type { UpdateMemberWelloLocationServiceConsentRequest } from './models/UpdateMemberWelloLocationServiceConsentRequest';
export type { UpdateMemberWelloPrivateDataConsentRequest } from './models/UpdateMemberWelloPrivateDataConsentRequest';
export type { UpdateWelloAppPushReadRequest } from './models/UpdateWelloAppPushReadRequest';
export type { UpdateWelloCommentRequest } from './models/UpdateWelloCommentRequest';
export type { UpdateWelloMemberFamilyInfoRequest } from './models/UpdateWelloMemberFamilyInfoRequest';
export type { VoucherProviderWishlistResponse } from './models/VoucherProviderWishlistResponse';
export type { WelloCommentDto } from './models/WelloCommentDto';
export type { WelloFeedbackResponse } from './models/WelloFeedbackResponse';
export type { WelloMessageRejectRequest } from './models/WelloMessageRejectRequest';
export type { WelloPickListResponse } from './models/WelloPickListResponse';
export type { WelloPickPolicyListResponse } from './models/WelloPickPolicyListResponse';
export type { WelloPolicyApplyCountResponse } from './models/WelloPolicyApplyCountResponse';
export type { WelloPolicyCodeDto } from './models/WelloPolicyCodeDto';
export type { WishlistRequest } from './models/WishlistRequest';

export { Service } from './services/Service';
export { ApiService } from './services/ApiService';
export { KbPayApiService } from './services/KbPayApiService';
export { NiceService } from './services/NiceService';
export { S3FileApiService } from './services/S3FileApiService';
