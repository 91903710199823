import ky from 'ky';

import { ENV } from '@/constants';

export const jejuApi = ky.create({
  prefixUrl: `${ENV.NEXT_PUBLIC_JEJU_API_DOMAIN}/api/wello`,
  headers: {
    Authorization: `Basic ${ENV.NEXT_PUBLIC_JEJU_YOUTHDREAM_API_KEY}`,
  },
});
