/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckCertificationByContractRequest } from '../models/CheckCertificationByContractRequest';
import type { CreateMemberFamilyInfoRequest } from '../models/CreateMemberFamilyInfoRequest';
import type { DefaultHttpResponse } from '../models/DefaultHttpResponse';
import type { DefaultHttpResponse_BestContentsWishlistResponse_ } from '../models/DefaultHttpResponse_BestContentsWishlistResponse_';
import type { DefaultHttpResponse_boolean_ } from '../models/DefaultHttpResponse_boolean_';
import type { DefaultHttpResponse_CreateWelloMemberFamilyResponse_ } from '../models/DefaultHttpResponse_CreateWelloMemberFamilyResponse_';
import type { DefaultHttpResponse_DetailDisplayBannerResponse_ } from '../models/DefaultHttpResponse_DetailDisplayBannerResponse_';
import type { DefaultHttpResponse_DetailEmergencyRoomResponse_ } from '../models/DefaultHttpResponse_DetailEmergencyRoomResponse_';
import type { DefaultHttpResponse_DetailEVoucherProviderResponse_ } from '../models/DefaultHttpResponse_DetailEVoucherProviderResponse_';
import type { DefaultHttpResponse_DetailMemberActionCountResponse_ } from '../models/DefaultHttpResponse_DetailMemberActionCountResponse_';
import type { DefaultHttpResponse_DetailMemberResponse_ } from '../models/DefaultHttpResponse_DetailMemberResponse_';
import type { DefaultHttpResponse_DetailMemberTermAgreementResponse_ } from '../models/DefaultHttpResponse_DetailMemberTermAgreementResponse_';
import type { DefaultHttpResponse_DetailMemberWelloLocationServiceConsentResponse_ } from '../models/DefaultHttpResponse_DetailMemberWelloLocationServiceConsentResponse_';
import type { DefaultHttpResponse_DetailMemberWelloPrivateDataConsentResponse_ } from '../models/DefaultHttpResponse_DetailMemberWelloPrivateDataConsentResponse_';
import type { DefaultHttpResponse_DetailMyPointResponse_ } from '../models/DefaultHttpResponse_DetailMyPointResponse_';
import type { DefaultHttpResponse_DetailPointBalanceResponse_ } from '../models/DefaultHttpResponse_DetailPointBalanceResponse_';
import type { DefaultHttpResponse_DetailWelloCommentResponse_ } from '../models/DefaultHttpResponse_DetailWelloCommentResponse_';
import type { DefaultHttpResponse_DuplicateMemberInfoResponse_ } from '../models/DefaultHttpResponse_DuplicateMemberInfoResponse_';
import type { DefaultHttpResponse_EmergencyRoomWishlistResponse_ } from '../models/DefaultHttpResponse_EmergencyRoomWishlistResponse_';
import type { DefaultHttpResponse_EVoucherFeedbackResponse_ } from '../models/DefaultHttpResponse_EVoucherFeedbackResponse_';
import type { DefaultHttpResponse_HometownNewsWishlistResponse_ } from '../models/DefaultHttpResponse_HometownNewsWishlistResponse_';
import type { DefaultHttpResponse_InterestFilterResponse_ } from '../models/DefaultHttpResponse_InterestFilterResponse_';
import type { DefaultHttpResponse_InvitedMemberCountResponse_ } from '../models/DefaultHttpResponse_InvitedMemberCountResponse_';
import type { DefaultHttpResponse_ListCommunityWishlistResponse_ } from '../models/DefaultHttpResponse_ListCommunityWishlistResponse_';
import type { DefaultHttpResponse_ListDislikeResponse_ } from '../models/DefaultHttpResponse_ListDislikeResponse_';
import type { DefaultHttpResponse_ListEmergencyRoomResponse_ } from '../models/DefaultHttpResponse_ListEmergencyRoomResponse_';
import type { DefaultHttpResponse_ListEVoucherProviderResponse_ } from '../models/DefaultHttpResponse_ListEVoucherProviderResponse_';
import type { DefaultHttpResponse_ListLikeContentsResponse_ } from '../models/DefaultHttpResponse_ListLikeContentsResponse_';
import type { DefaultHttpResponse_ListLikeContentsTypeResponse_ } from '../models/DefaultHttpResponse_ListLikeContentsTypeResponse_';
import type { DefaultHttpResponse_ListLiveWelloCommentResponse_ } from '../models/DefaultHttpResponse_ListLiveWelloCommentResponse_';
import type { DefaultHttpResponse_ListMyMemberWelloBlockResponse_ } from '../models/DefaultHttpResponse_ListMyMemberWelloBlockResponse_';
import type { DefaultHttpResponse_ListMyWelloCommentResponse_ } from '../models/DefaultHttpResponse_ListMyWelloCommentResponse_';
import type { DefaultHttpResponse_ListNeighborPolicyResponse_ } from '../models/DefaultHttpResponse_ListNeighborPolicyResponse_';
import type { DefaultHttpResponse_ListPeerGroupPolicyResponse_ } from '../models/DefaultHttpResponse_ListPeerGroupPolicyResponse_';
import type { DefaultHttpResponse_ListPointAttendanceResponse_ } from '../models/DefaultHttpResponse_ListPointAttendanceResponse_';
import type { DefaultHttpResponse_ListPointHistoryResponse_ } from '../models/DefaultHttpResponse_ListPointHistoryResponse_';
import type { DefaultHttpResponse_ListRecentPolicyResponse_ } from '../models/DefaultHttpResponse_ListRecentPolicyResponse_';
import type { DefaultHttpResponse_ListRecommendPolicyByRecentResponse_ } from '../models/DefaultHttpResponse_ListRecommendPolicyByRecentResponse_';
import type { DefaultHttpResponse_ListWelloAppPushResponse_ } from '../models/DefaultHttpResponse_ListWelloAppPushResponse_';
import type { DefaultHttpResponse_ListWelloCommentResponse_ } from '../models/DefaultHttpResponse_ListWelloCommentResponse_';
import type { DefaultHttpResponse_ListWelloMemberFamilyResponse_ } from '../models/DefaultHttpResponse_ListWelloMemberFamilyResponse_';
import type { DefaultHttpResponse_MemberContentCountResponse_ } from '../models/DefaultHttpResponse_MemberContentCountResponse_';
import type { DefaultHttpResponse_MemberFilterResponse_ } from '../models/DefaultHttpResponse_MemberFilterResponse_';
import type { DefaultHttpResponse_MemberOAuthResponse_ } from '../models/DefaultHttpResponse_MemberOAuthResponse_';
import type { DefaultHttpResponse_MetaPolicyWishlistResponse_ } from '../models/DefaultHttpResponse_MetaPolicyWishlistResponse_';
import type { DefaultHttpResponse_OAuthTokenResponse_ } from '../models/DefaultHttpResponse_OAuthTokenResponse_';
import type { DefaultHttpResponse_PointActivityResponse_ } from '../models/DefaultHttpResponse_PointActivityResponse_';
import type { DefaultHttpResponse_ProvisionTypeRankResponse_ } from '../models/DefaultHttpResponse_ProvisionTypeRankResponse_';
import type { DefaultHttpResponse_RecommendInterestPolicySearchResponse_ } from '../models/DefaultHttpResponse_RecommendInterestPolicySearchResponse_';
import type { DefaultHttpResponse_RecommendPolicySearchResponse_ } from '../models/DefaultHttpResponse_RecommendPolicySearchResponse_';
import type { DefaultHttpResponse_SaveMemberFilterResponse_ } from '../models/DefaultHttpResponse_SaveMemberFilterResponse_';
import type { DefaultHttpResponse_SaveWelloCommentResponse_ } from '../models/DefaultHttpResponse_SaveWelloCommentResponse_';
import type { DefaultHttpResponse_SituationListResponse_ } from '../models/DefaultHttpResponse_SituationListResponse_';
import type { DefaultHttpResponse_SituationPolicyListResponse_ } from '../models/DefaultHttpResponse_SituationPolicyListResponse_';
import type { DefaultHttpResponse_Void_ } from '../models/DefaultHttpResponse_Void_';
import type { DefaultHttpResponse_VoucherProviderWishlistResponse_ } from '../models/DefaultHttpResponse_VoucherProviderWishlistResponse_';
import type { DefaultHttpResponse_WelloPickListResponse_ } from '../models/DefaultHttpResponse_WelloPickListResponse_';
import type { DefaultHttpResponse_WelloPickPolicyListResponse_ } from '../models/DefaultHttpResponse_WelloPickPolicyListResponse_';
import type { DeleteMemberInfoRequest } from '../models/DeleteMemberInfoRequest';
import type { DeleteWelloMemberFamilyRequest } from '../models/DeleteWelloMemberFamilyRequest';
import type { FitSaveMemberFilterRequest } from '../models/FitSaveMemberFilterRequest';
import type { InterestSaveMemberFilterRequest } from '../models/InterestSaveMemberFilterRequest';
import type { KbMemberCheckRequest } from '../models/KbMemberCheckRequest';
import type { MemberOAuthWithdrawRequest } from '../models/MemberOAuthWithdrawRequest';
import type { MessageReceiveUpdateRequest } from '../models/MessageReceiveUpdateRequest';
import type { SaveAndDeleteWelloLikeRequest } from '../models/SaveAndDeleteWelloLikeRequest';
import type { SaveKbFilterRequest } from '../models/SaveKbFilterRequest';
import type { SaveMemberSignUpRequest } from '../models/SaveMemberSignUpRequest';
import type { SaveRegionFilterRequest } from '../models/SaveRegionFilterRequest';
import type { SaveWelloCommentReportRequest } from '../models/SaveWelloCommentReportRequest';
import type { SaveWelloCommentRequest } from '../models/SaveWelloCommentRequest';
import type { SaveWelloDeviceInfoRequest } from '../models/SaveWelloDeviceInfoRequest';
import type { SaveWelloHelperRequest } from '../models/SaveWelloHelperRequest';
import type { SendCertificationByCellPhoneRequest } from '../models/SendCertificationByCellPhoneRequest';
import type { UpdateMemberInfoRequestV3 } from '../models/UpdateMemberInfoRequestV3';
import type { UpdateMemberTermAgreementRequest } from '../models/UpdateMemberTermAgreementRequest';
import type { UpdateMemberVerificationRequest } from '../models/UpdateMemberVerificationRequest';
import type { UpdateMemberWelloLocationServiceConsentRequest } from '../models/UpdateMemberWelloLocationServiceConsentRequest';
import type { UpdateMemberWelloPrivateDataConsentRequest } from '../models/UpdateMemberWelloPrivateDataConsentRequest';
import type { UpdateWelloAppPushReadRequest } from '../models/UpdateWelloAppPushReadRequest';
import type { UpdateWelloCommentRequest } from '../models/UpdateWelloCommentRequest';
import type { UpdateWelloMemberFamilyInfoRequest } from '../models/UpdateWelloMemberFamilyInfoRequest';
import type { WelloMessageRejectRequest } from '../models/WelloMessageRejectRequest';
import type { WishlistRequest } from '../models/WishlistRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ApiService {
    /**
     * 노출할 배너 타입 조회
     * 상단에 노출할 배너 타입을 조회합니다.
     * @returns DefaultHttpResponse_DetailDisplayBannerResponse_ OK
     * @throws ApiError
     */
    public static getDisplayBannerTypeUsingGet(): CancelablePromise<DefaultHttpResponse_DetailDisplayBannerResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/banner',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 사용자 로그아웃
     * @returns DefaultHttpResponse_boolean_ OK
     * @throws ApiError
     */
    public static logoutMemberUsingGet(): CancelablePromise<DefaultHttpResponse_boolean_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/common/member-oauth/logout',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 회원 상세
     * @returns DefaultHttpResponse_DetailMemberResponse_ OK
     * @throws ApiError
     */
    public static getMemberUsingGet(): CancelablePromise<DefaultHttpResponse_DetailMemberResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/common/member-oauth/my-info',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 제외정책 목록조회
     * 제외정책 목록을 조회합니다
     * @returns DefaultHttpResponse_ListDislikeResponse_ OK
     * @throws ApiError
     */
    public static dislikeUsingGet({
        codeProvisionType,
    }: {
        /**
         * 지원유형[C09-XX]
         */
        codeProvisionType?: string,
    }): CancelablePromise<DefaultHttpResponse_ListDislikeResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy/dislike',
            query: {
                'code_provision_type': codeProvisionType,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 제외정책 등록
     * 제외정책을 등록합니다
     * @returns DefaultHttpResponse OK
     * @throws ApiError
     */
    public static saveWishlistUsingPost({
        policyId,
    }: {
        /**
         * 정책 번호
         */
        policyId: number,
    }): CancelablePromise<DefaultHttpResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/policy/dislike/{policy_id}',
            path: {
                'policy_id': policyId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 제외정책-삭제
     * 제외정책을 삭제합니다
     * @returns DefaultHttpResponse OK
     * @throws ApiError
     */
    public static removeWishlistUsingDelete({
        policyId,
    }: {
        /**
         * 정책 번호
         */
        policyId: number,
    }): CancelablePromise<DefaultHttpResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wello/v2/auth/policy/dislike/{policy_id}',
            path: {
                'policy_id': policyId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 위경도 기반 제공기관 검색
     * @returns DefaultHttpResponse_ListEVoucherProviderResponse_ OK
     * @throws ApiError
     */
    public static getEVoucherProviderListUsingGet({
        categoryId,
        ip,
        latitude,
        longitude,
        page,
        radius,
        size,
    }: {
        /**
         * 카테고리 아이디
         */
        categoryId: number,
        /**
         * 유저 IP
         */
        ip?: string,
        /**
         * 중심지 위도
         */
        latitude?: number,
        /**
         * 중심지 경도
         */
        longitude?: number,
        /**
         * 페이지
         */
        page?: number,
        /**
         * 반경
         */
        radius?: number,
        /**
         * 사이즈
         */
        size?: number,
    }): CancelablePromise<DefaultHttpResponse_ListEVoucherProviderResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/voucher/provider',
            query: {
                'category_id': categoryId,
                'ip': ip,
                'latitude': latitude,
                'longitude': longitude,
                'page': page,
                'radius': radius,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 위경도 기반 제공기관 전체 검색
     * @returns DefaultHttpResponse_ListEVoucherProviderResponse_ OK
     * @throws ApiError
     */
    public static getEVoucherProviderListAllUsingGet({
        categoryId,
        page,
        size,
        ip,
        latitude,
        longitude,
    }: {
        /**
         * 카테고리 아이디
         */
        categoryId: number,
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 유저 IP
         */
        ip?: string,
        /**
         * 중심지 위도
         */
        latitude?: number,
        /**
         * 중심지 경도
         */
        longitude?: number,
    }): CancelablePromise<DefaultHttpResponse_ListEVoucherProviderResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/voucher/provider/all',
            query: {
                'category_id': categoryId,
                'ip': ip,
                'latitude': latitude,
                'longitude': longitude,
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 제공기관 피드백 조회
     * @returns DefaultHttpResponse_EVoucherFeedbackResponse_ OK
     * @throws ApiError
     */
    public static getEVoucherProviderFeedbackUsingGet({
        id,
    }: {
        /**
         * 바우처 id
         */
        id: number,
    }): CancelablePromise<DefaultHttpResponse_EVoucherFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/voucher/provider/feedback/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 제공기관 상세 조회
     * @returns DefaultHttpResponse_DetailEVoucherProviderResponse_ OK
     * @throws ApiError
     */
    public static getEVoucherProviderUsingGet({
        providerUuid,
    }: {
        /**
         * 바우처 UUID(idIdx)
         */
        providerUuid: string,
    }): CancelablePromise<DefaultHttpResponse_DetailEVoucherProviderResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/voucher/provider/{provider_uuid}',
            path: {
                'provider_uuid': providerUuid,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 병원 상세 조회
     * @returns DefaultHttpResponse_ListEmergencyRoomResponse_ OK
     * @throws ApiError
     */
    public static getEmergencyRoomListUsingGet({
        emergencyRoomYn,
        latitude,
        longitude,
        page,
        size,
        emergencyRoomCodeList,
        emergencyRoomDayType,
        radius,
        time,
    }: {
        /**
         * 응급실 검색 여부, true - 응급실, false - 일반
         */
        emergencyRoomYn: boolean,
        /**
         * 위도
         */
        latitude: number,
        /**
         * 경도
         */
        longitude: number,
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 증상 코드 목록
         */
        emergencyRoomCodeList?: Array<string>,
        /**
         * 요일 코드 목록
         */
        emergencyRoomDayType?: 'HOLIDAY' | 'WEEKDAY' | 'WEEKEND',
        /**
         * 반경
         */
        radius?: number,
        /**
         * 시간
         */
        time?: number,
    }): CancelablePromise<DefaultHttpResponse_ListEmergencyRoomResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/emergency-room',
            query: {
                'emergencyRoomCodeList': emergencyRoomCodeList,
                'emergencyRoomDayType': emergencyRoomDayType,
                'emergencyRoomYN': emergencyRoomYn,
                'latitude': latitude,
                'longitude': longitude,
                'page': page,
                'radius': radius,
                'size': size,
                'time': time,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 병원 상세 조회
     * @returns DefaultHttpResponse_DetailEmergencyRoomResponse_ OK
     * @throws ApiError
     */
    public static getEmergencyRoomUsingGet({
        emergencyRoomId,
    }: {
        /**
         * emergencyRoomId
         */
        emergencyRoomId: number,
    }): CancelablePromise<DefaultHttpResponse_DetailEmergencyRoomResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/emergency-room/{emergencyRoomId}',
            path: {
                'emergencyRoomId': emergencyRoomId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 맞춤 정보 저장
     * @returns DefaultHttpResponse_SaveMemberFilterResponse_ OK
     * @throws ApiError
     */
    public static putFitFilterUsingPost({
        requestBody,
    }: {
        requestBody?: FitSaveMemberFilterRequest,
    }): CancelablePromise<DefaultHttpResponse_SaveMemberFilterResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-filter/fit',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 회원가입 맞춤 정보 저장
     * @returns DefaultHttpResponse_SaveMemberFilterResponse_ OK
     * @throws ApiError
     */
    public static saveFitFilterUsingPost({
        requestBody,
    }: {
        requestBody?: FitSaveMemberFilterRequest,
    }): CancelablePromise<DefaultHttpResponse_SaveMemberFilterResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-filter/fit-new',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 관심 필터 조회
     * @returns DefaultHttpResponse_InterestFilterResponse_ OK
     * @throws ApiError
     */
    public static interestFilterUsingGet({
        welloMemberFamilyId,
    }: {
        /**
         * wello_member_family_id
         */
        welloMemberFamilyId?: number,
    }): CancelablePromise<DefaultHttpResponse_InterestFilterResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-filter/interest',
            query: {
                'wello_member_family_id': welloMemberFamilyId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 관심 필터 코드 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveInterestFilterUsingPost({
        requestBody,
    }: {
        requestBody?: InterestSaveMemberFilterRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-filter/interest',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     *  KB 기본 필터 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveKbFilterUsingPost({
        requestBody,
    }: {
        requestBody?: SaveKbFilterRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-filter/kb',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 지역 필터 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static noticeListUsingPost({
        requestBody,
    }: {
        requestBody?: SaveRegionFilterRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-filter/region',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 필터 조회(관심필터 제외)
     * @returns DefaultHttpResponse_MemberFilterResponse_ OK
     * @throws ApiError
     */
    public static requiredFilterUsingGet({
        welloMemberFamilyId,
    }: {
        /**
         * wello_member_family_id
         */
        welloMemberFamilyId?: number,
    }): CancelablePromise<DefaultHttpResponse_MemberFilterResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-filter/required',
            query: {
                'wello_member_family_id': welloMemberFamilyId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 유저 프로필 이미지 업로드
     * 유저 프로필 이미지를 업로드 한다.
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static fileUploadUsingPost({
        formData,
    }: {
        formData?: {
            /**
             * profile_image
             */
            profile_image: Blob;
        },
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 회원 탈퇴
     * 회원 탈퇴요청을 처리합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static resignMemberUsingDelete({
        requestBody,
    }: {
        requestBody?: MemberOAuthWithdrawRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wello/v2/auth/member',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 유저의 행동 관련 수치 조회
     * 유저의 맞춤 정책, 스크랩 목록 수를 조회합니다.
     * @returns DefaultHttpResponse_DetailMemberActionCountResponse_ OK
     * @throws ApiError
     */
    public static getActionCountUsingGet(): CancelablePromise<DefaultHttpResponse_DetailMemberActionCountResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/action-count',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 메인 페이지 멤버 컨텐츠 별 건수
     * @returns DefaultHttpResponse_MemberContentCountResponse_ OK
     * @throws ApiError
     */
    public static memberContentCountUsingGet(): CancelablePromise<DefaultHttpResponse_MemberContentCountResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/content-count',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 회원 관심 분야별 맞춤정책 조회
     * @returns DefaultHttpResponse_RecommendInterestPolicySearchResponse_ OK
     * @throws ApiError
     */
    public static memberInterestPolicyListUsingGet({
        codeProvisionType,
    }: {
        /**
         * 관심유형(C14-01)
         */
        codeProvisionType?: string,
    }): CancelablePromise<DefaultHttpResponse_RecommendInterestPolicySearchResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/interest/policy',
            query: {
                'code_provision_type': codeProvisionType,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 유저가 초대한 회원 수 조회
     * @returns DefaultHttpResponse_InvitedMemberCountResponse_ OK
     * @throws ApiError
     */
    public static getInvitedMemberCountUsingGet(): CancelablePromise<DefaultHttpResponse_InvitedMemberCountResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/invited-member-count',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * KB 연동 체크
     * @returns DefaultHttpResponse_OAuthTokenResponse_ OK
     * @throws ApiError
     */
    public static kbMemberCheckUsingPost({
        requestBody,
    }: {
        requestBody?: KbMemberCheckRequest,
    }): CancelablePromise<DefaultHttpResponse_OAuthTokenResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member/kb-member-check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 알림수신여부 수정
     * 알림수신 여부를 수정합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static messageReceiveUsingPut({
        requestBody,
    }: {
        requestBody?: MessageReceiveUpdateRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/member/message-receive',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 우리 동네 이웃이 많이 본 정책 조회
     * @returns DefaultHttpResponse_ListNeighborPolicyResponse_ OK
     * @throws ApiError
     */
    public static memberNeighborPolicyListUsingGet(): CancelablePromise<DefaultHttpResponse_ListNeighborPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/neighbor/policy',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 내 또래가 많이 본 정책 조회
     * @returns DefaultHttpResponse_ListPeerGroupPolicyResponse_ OK
     * @throws ApiError
     */
    public static memberPeerGroupPolicyListUsingGet(): CancelablePromise<DefaultHttpResponse_ListPeerGroupPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/peer-group/policy',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 지원유형별 맞춤정책 건수
     * 지원유형별 맞춤정책 건수를 조회합니다
     * @returns DefaultHttpResponse_ProvisionTypeRankResponse_ OK
     * @throws ApiError
     */
    public static memberPolicyListUsingGet(): CancelablePromise<DefaultHttpResponse_ProvisionTypeRankResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/policy/provision-type-count',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 유저 프로필 조회
     * 가입된 유저의 프로필을 조회합니다
     * @returns DefaultHttpResponse_MemberOAuthResponse_ OK
     * @throws ApiError
     */
    public static memberUsingGet(): CancelablePromise<DefaultHttpResponse_MemberOAuthResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/profile',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 유저 정보 수정
     * 가입된 유저 정보를 수정합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateMemberNickNameUsingPut({
        requestBody,
    }: {
        requestBody?: UpdateMemberInfoRequestV3,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/member/profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 유저 가입정보 등록
     * @returns DefaultHttpResponse_PointActivityResponse_ OK
     * @throws ApiError
     */
    public static saveMemberNickNameUsingPost({
        requestBody,
    }: {
        requestBody?: SaveMemberSignUpRequest,
    }): CancelablePromise<DefaultHttpResponse_PointActivityResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member/profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 최근 본 정책 조회
     * @returns DefaultHttpResponse_ListRecentPolicyResponse_ OK
     * @throws ApiError
     */
    public static memberRecentPolicyListUsingGet(): CancelablePromise<DefaultHttpResponse_ListRecentPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/recent/policy',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 내가 본 정책과 비슷한 정책 조회
     * @returns DefaultHttpResponse_ListRecommendPolicyByRecentResponse_ OK
     * @throws ApiError
     */
    public static memberRecommendPolicyByRecentListUsingGet(): CancelablePromise<DefaultHttpResponse_ListRecommendPolicyByRecentResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/recent/recommend/policy',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 회원 신규 맞춤정책 조회
     * @returns DefaultHttpResponse_RecommendPolicySearchResponse_ OK
     * @throws ApiError
     */
    public static memberNewPolicyListUsingGet({
        page,
        size,
        codeProvisionType,
        welloMemberFamilyId,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 지원유형(C09)
         */
        codeProvisionType?: string,
        /**
         * 가족 ID
         */
        welloMemberFamilyId?: number,
    }): CancelablePromise<DefaultHttpResponse_RecommendPolicySearchResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/recommend/policy',
            query: {
                'code_provision_type': codeProvisionType,
                'page': page,
                'size': size,
                'wello_member_family_id': welloMemberFamilyId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 연동된 소셜계정 목록
     * @returns DefaultHttpResponse_DuplicateMemberInfoResponse_ OK
     * @throws ApiError
     */
    public static memberSocialAccountsUsingGet(): CancelablePromise<DefaultHttpResponse_DuplicateMemberInfoResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/social-accounts',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 연동된 소셜계정 해제
     * @returns DefaultHttpResponse_DuplicateMemberInfoResponse_ OK
     * @throws ApiError
     */
    public static deleteMemberSocialAccountsUsingDelete({
        requestBody,
    }: {
        requestBody?: DeleteMemberInfoRequest,
    }): CancelablePromise<DefaultHttpResponse_DuplicateMemberInfoResponse_> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wello/v2/auth/member/social-accounts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 본인인증 ID로 유저정보 수정
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateMemberVerificationUsingPut({
        requestBody,
    }: {
        requestBody?: UpdateMemberVerificationRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/member/verification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 약관 동의 여부 조회
     * @returns DefaultHttpResponse_DetailMemberTermAgreementResponse_ OK
     * @throws ApiError
     */
    public static getMemberTermAgreementByAgreementTypeUsingGet({
        agreementTypeCd,
        termCategoryId,
    }: {
        /**
         * 동의 유형 code
         */
        agreementTypeCd: string,
        /**
         * 약관 카테고리 아이디
         */
        termCategoryId?: string,
    }): CancelablePromise<DefaultHttpResponse_DetailMemberTermAgreementResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-term-agreement',
            query: {
                'agreementTypeCd': agreementTypeCd,
                'termCategoryId': termCategoryId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 약관 동의 저장/수정
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveOrUpdateMemberTermAgreementUsingPost({
        requestBody,
    }: {
        requestBody?: UpdateMemberTermAgreementRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-term-agreement',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 사용자 차단 목록 조회
     * @returns DefaultHttpResponse_ListMyMemberWelloBlockResponse_ OK
     * @throws ApiError
     */
    public static getWelloMemberBlockListUsingGet({
        page,
        size,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
    }): CancelablePromise<DefaultHttpResponse_ListMyMemberWelloBlockResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-wello/block',
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 사용자 차단 - By Community
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static blockWelloMemberByCommunityUsingPost({
        communityId,
    }: {
        /**
         * 커뮤니티 ID
         */
        communityId: number,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-wello/block/by-community/{community_id}',
            path: {
                'community_id': communityId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 사용자 차단 해제 - 삭제
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static deleteMemberWelloBlockUsingDelete({
        blockedMemberId,
    }: {
        /**
         * 차단된 고객 ID
         */
        blockedMemberId: number,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wello/v2/auth/member-wello/block/{blocked_member_id}',
            path: {
                'blocked_member_id': blockedMemberId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 휴대전화번호 인증 발송
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static sendCertificationCellphoneUsingPost({
        requestBody,
    }: {
        requestBody?: SendCertificationByCellPhoneRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/certification/cellphone',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 인증 번호 확인
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static certificationCheckUsingPost({
        requestBody,
    }: {
        requestBody?: CheckCertificationByContractRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/certification/check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 위치 기반 동의 여부 조회
     * @returns DefaultHttpResponse_DetailMemberWelloLocationServiceConsentResponse_ OK
     * @throws ApiError
     */
    public static getLocationServiceConsentUsingGet(): CancelablePromise<DefaultHttpResponse_DetailMemberWelloLocationServiceConsentResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-wello/location-service-consent',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 위치 기반 동의 여부 수정
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateLocationServiceConsentUsingPut({
        requestBody,
    }: {
        requestBody?: UpdateMemberWelloLocationServiceConsentRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/member-wello/location-service-consent',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 개인 정보 수집 동의 여부 조회
     * @returns DefaultHttpResponse_DetailMemberWelloPrivateDataConsentResponse_ OK
     * @throws ApiError
     */
    public static getPrivateDataConsentUsingGet(): CancelablePromise<DefaultHttpResponse_DetailMemberWelloPrivateDataConsentResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-wello/private_data_consent',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 개인 정보 수집 동의 여부 수정
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updatePrivateDataConsentUsingPut({
        requestBody,
    }: {
        requestBody?: UpdateMemberWelloPrivateDataConsentRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/member-wello/private_data_consent',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 멤버 메세지 수신거부
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static rejectMessageUsingPut({
        requestBody,
    }: {
        requestBody?: WelloMessageRejectRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/message-reject/member-wello',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 포인트 정보 조회
     * 포인트 잔액, 출금 가능 포인트를 조회합니다.
     * @returns DefaultHttpResponse_DetailPointBalanceResponse_ OK
     * @throws ApiError
     */
    public static getDetailPointBalanceUsingGet(): CancelablePromise<DefaultHttpResponse_DetailPointBalanceResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/point',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 출석 내역 조회
     * 출석 내역 목록을 조회합니다.
     * @returns DefaultHttpResponse_ListPointAttendanceResponse_ OK
     * @throws ApiError
     */
    public static getListPointAttendanceUsingGet(): CancelablePromise<DefaultHttpResponse_ListPointAttendanceResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/point/attendance',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 출석 체크
     * 오늘 날짜로 출석 내역을 저장합니다.
     * @returns DefaultHttpResponse_PointActivityResponse_ OK
     * @throws ApiError
     */
    public static saveTodayAttendanceUsingPost(): CancelablePromise<DefaultHttpResponse_PointActivityResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/point/attendance',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 포인트 이벤트 정보 조회
     * 포인트 이벤트 관련 정보를 조회합니다.
     * @returns DefaultHttpResponse_DetailMyPointResponse_ OK
     * @throws ApiError
     */
    public static getPointBudgetUsingGet(): CancelablePromise<DefaultHttpResponse_DetailMyPointResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/point/event',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 포인트 사용, 출금, 차감 내역 조회
     * 포인트 사용, 출금, 차감 내역 목록을 조회합니다.
     * @returns DefaultHttpResponse_ListPointHistoryResponse_ OK
     * @throws ApiError
     */
    public static getListPointHistoryUsingGet({
        page,
        size,
        activityType,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 리워드 활동 유형
         */
        activityType?: 'ACCUMULATE' | 'RECLAIMED' | 'USE',
    }): CancelablePromise<DefaultHttpResponse_ListPointHistoryResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/point/history',
            query: {
                'activity_type': activityType,
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 출금 신청
     * 원하는 금액의 출금을 신청합니다.
     * @returns DefaultHttpResponse_PointActivityResponse_ OK
     * @throws ApiError
     */
    public static savePointWithdrawalUsingPost({
        point,
    }: {
        /**
         * 출금 요청 포인트
         */
        point: number,
    }): CancelablePromise<DefaultHttpResponse_PointActivityResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/point/withdrawal',
            query: {
                'point': point,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 꾸러미 목록 조회
     * 정책 꾸러미 목록을 조회합니다
     * @returns DefaultHttpResponse_SituationListResponse_ OK
     * @throws ApiError
     */
    public static situationListUsingGet(): CancelablePromise<DefaultHttpResponse_SituationListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/contents/situation',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 꾸러미 정책 목록 조회
     * 정책 꾸러미 정책 목록을 조회합니다
     * @returns DefaultHttpResponse_SituationPolicyListResponse_ OK
     * @throws ApiError
     */
    public static getSituationPolicyListUsingGet({
        situationId,
    }: {
        /**
         * 정책 꾸러미 번호
         */
        situationId: number,
    }): CancelablePromise<DefaultHttpResponse_SituationPolicyListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/contents/situation/{situation_id}',
            path: {
                'situation_id': situationId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 앱 푸시 목록 조회
     * @returns DefaultHttpResponse_ListWelloAppPushResponse_ OK
     * @throws ApiError
     */
    public static getWebWelloAppPushListUsingGet({
        fcmToken,
        page,
        size,
        welloAppPushCategoryId,
        welloAppPushParentCategoryTypeCd,
    }: {
        /**
         * 디바이스 토큰
         */
        fcmToken: string,
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈(10~)
         */
        size: number,
        /**
         * 앱 푸시 카테고리 ID
         */
        welloAppPushCategoryId?: number,
        /**
         * 앱 푸시 상위 카테고리 코드
         */
        welloAppPushParentCategoryTypeCd?: string,
    }): CancelablePromise<DefaultHttpResponse_ListWelloAppPushResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/wello-app-push',
            query: {
                'fcm_token': fcmToken,
                'page': page,
                'size': size,
                'wello_app_push_category_id': welloAppPushCategoryId,
                'wello_app_push_parent_category_type_cd': welloAppPushParentCategoryTypeCd,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 앱 푸시 읽음 처리
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateWelloAppPushReadUsingPut({
        requestBody,
    }: {
        requestBody?: UpdateWelloAppPushReadRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/wello-app-push',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 신청한 바로신청 목록 조회
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static getWelloApplyPolicyListUsingGet(): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/apply-policy',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 댓글 목록 조회
     * @returns DefaultHttpResponse_ListWelloCommentResponse_ OK
     * @throws ApiError
     */
    public static getWelloCommentListUsingGet({
        commentTypeCd,
        contentId,
        page,
        size,
        commentSort,
        parentCommentId,
    }: {
        /**
         * 댓글 타입 cd - (Parent Code) WELLO_COMMENT_TYPE
         */
        commentTypeCd: string,
        /**
         * 컨텐츠 ID - policy_id, best_contents_id, hometown_news_id
         */
        contentId: number,
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 정렬순서 - POPULARITY, ENROLL, LATEST
         */
        commentSort?: 'ENROLL' | 'LATEST' | 'POPULARITY',
        /**
         * 부모 댓글 아이디 - 댓글의 답글 조회용
         */
        parentCommentId?: number,
    }): CancelablePromise<DefaultHttpResponse_ListWelloCommentResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/wello-comment',
            query: {
                'comment_sort': commentSort,
                'comment_type_cd': commentTypeCd,
                'content_id': contentId,
                'page': page,
                'parent_comment_id': parentCommentId,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 댓글 등록
     * @returns DefaultHttpResponse_SaveWelloCommentResponse_ OK
     * @throws ApiError
     */
    public static saveWelloCommentUsingPost({
        requestBody,
    }: {
        requestBody?: SaveWelloCommentRequest,
    }): CancelablePromise<DefaultHttpResponse_SaveWelloCommentResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/wello-comment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * @deprecated
     * 웰로 실시간 정책 의견
     * @returns DefaultHttpResponse_ListLiveWelloCommentResponse_ OK
     * @throws ApiError
     */
    public static getLiveWelloCommentListUsingGet({
        commentTypeCd,
        size,
    }: {
        /**
         * 댓글 타입 cd - (Parent Code) WELLO_COMMENT_TYPE
         */
        commentTypeCd?: string,
        /**
         * 사이즈 (default 3)
         */
        size?: number,
    }): CancelablePromise<DefaultHttpResponse_ListLiveWelloCommentResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/wello-comment/live',
            query: {
                'comment_type_cd': commentTypeCd,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 유저가 작성한 댓글 목록
     * @returns DefaultHttpResponse_ListMyWelloCommentResponse_ OK
     * @throws ApiError
     */
    public static getMyWelloCommentListUsingGet({
        page,
        size,
        commentSort,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 정렬순서 - POPULARITY, ENROLL, LATEST
         */
        commentSort?: 'ENROLL' | 'LATEST' | 'POPULARITY',
    }): CancelablePromise<DefaultHttpResponse_ListMyWelloCommentResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/wello-comment/my',
            query: {
                'comment_sort': commentSort,
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 댓글 상세 조회
     * @returns DefaultHttpResponse_DetailWelloCommentResponse_ OK
     * @throws ApiError
     */
    public static getMetaPolicyCommentListUsingGet({
        welloCommentId,
    }: {
        /**
         * 댓글 ID
         */
        welloCommentId: number,
    }): CancelablePromise<DefaultHttpResponse_DetailWelloCommentResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/wello-comment/{welloCommentId}',
            path: {
                'welloCommentId': welloCommentId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 댓글 수정
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateWelloCommentUsingPut({
        welloCommentId,
        requestBody,
    }: {
        /**
         * 댓글 ID
         */
        welloCommentId: number,
        requestBody?: UpdateWelloCommentRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/wello-comment/{wello_comment_id}',
            path: {
                'wello_comment_id': welloCommentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 댓글 삭제
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static deleteWelloCommentUsingPut({
        welloCommentId,
    }: {
        /**
         * 댓글 ID
         */
        welloCommentId: number,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/wello-comment/{wello_comment_id}/delete',
            path: {
                'wello_comment_id': welloCommentId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 댓글 신고 등록
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveWelloCommentReportUsingPost({
        requestBody,
    }: {
        requestBody?: SaveWelloCommentReportRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/wello-comment-report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 가입 유저용 웰로 디바이스 정보 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveWelloDeviceInfoUsingPost({
        requestBody,
    }: {
        requestBody?: SaveWelloDeviceInfoRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/wello-device-info',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 헬퍼 등록
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveWelloHelperUsingPost({
        requestBody,
    }: {
        requestBody?: SaveWelloHelperRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/wello-helper',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 좋아요 등록
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveWelloLikeUsingPost({
        requestBody,
    }: {
        requestBody?: SaveAndDeleteWelloLikeRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/wello-like',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 좋아요 삭제
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static deleteWelloLikeUsingDelete({
        requestBody,
    }: {
        requestBody?: SaveAndDeleteWelloLikeRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wello/v2/auth/wello-like',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 내가 좋아요한 글 목록
     * @returns DefaultHttpResponse_ListLikeContentsResponse_ OK
     * @throws ApiError
     */
    public static getListLikeContentsUsingGet({
        contentsType,
        page,
        size,
        likeSort,
    }: {
        /**
         * 좋아요 유형
         */
        contentsType: string,
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 정렬순서 - POPULARITY, LATEST
         */
        likeSort?: 'LATEST' | 'POPULARITY',
    }): CancelablePromise<DefaultHttpResponse_ListLikeContentsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/wello-like/my',
            query: {
                'contents_type': contentsType,
                'like_sort': likeSort,
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 내가 좋아요한 글 유형 목록
     * @returns DefaultHttpResponse_ListLikeContentsTypeResponse_ OK
     * @throws ApiError
     */
    public static getListLikeContentsTypeUsingGet(): CancelablePromise<DefaultHttpResponse_ListLikeContentsTypeResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/wello-like/my/type',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로멤버 가족 프로필 삭제
     * 웰로멤버 삭제요청을 처리합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static deleteWelloMemberFamilyUsingDelete({
        requestBody,
    }: {
        requestBody?: DeleteWelloMemberFamilyRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wello/v2/auth/family',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로멤버 가족 목록 조회
     * 웰로멤버 가족 목록을 조회합니다
     * @returns DefaultHttpResponse_ListWelloMemberFamilyResponse_ OK
     * @throws ApiError
     */
    public static getWelloMemberFamilyUsingGet(): CancelablePromise<DefaultHttpResponse_ListWelloMemberFamilyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/family/profile',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로멤버 가족 프로필 수정
     * 웰로멤버 가족 프로필 정보를 수정합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateWelloMemberFamilyUsingPut({
        requestBody,
    }: {
        requestBody?: UpdateWelloMemberFamilyInfoRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/family/profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로멤버 가족 프로필 추가
     * 웰로멤버 가족 프로필을 추가합니다
     * @returns DefaultHttpResponse_CreateWelloMemberFamilyResponse_ OK
     * @throws ApiError
     */
    public static saveWelloMemberFamilyUsingPost({
        requestBody,
    }: {
        requestBody?: CreateMemberFamilyInfoRequest,
    }): CancelablePromise<DefaultHttpResponse_CreateWelloMemberFamilyResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/family/profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 대상별 정책 - 대상 조회
     * 웰로 대상별 정책 - 대상 정보를 조회합니다
     * @returns DefaultHttpResponse_WelloPickListResponse_ OK
     * @throws ApiError
     */
    public static welloPickListUsingGet(): CancelablePromise<DefaultHttpResponse_WelloPickListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/contents/pick',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 대상별 정책 조회
     * 웰로 대상별 정책 정보를 조회합니다
     * @returns DefaultHttpResponse_WelloPickPolicyListResponse_ OK
     * @throws ApiError
     */
    public static welloPickPolicyListUsingGet({
        welloPickId,
    }: {
        /**
         * 웰로 대상별 정책 - 대상 번호
         */
        welloPickId: number,
    }): CancelablePromise<DefaultHttpResponse_WelloPickPolicyListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/contents/pick/{wello_pick_id}',
            path: {
                'wello_pick_id': welloPickId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 스크랩 등록
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveWishlistUsingPost1({
        requestBody,
    }: {
        requestBody?: WishlistRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member/wishlist',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 스크랩 삭제
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static deleteWishlistUsingDelete({
        requestBody,
    }: {
        requestBody?: WishlistRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wello/v2/auth/member/wishlist',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책소식 스크랩 목록 조회
     * @returns DefaultHttpResponse_BestContentsWishlistResponse_ OK
     * @throws ApiError
     */
    public static bestContentsWishlistUsingGet(): CancelablePromise<DefaultHttpResponse_BestContentsWishlistResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/wishlist/best-contents',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 스크랩 목록 조회
     * @returns DefaultHttpResponse_ListCommunityWishlistResponse_ OK
     * @throws ApiError
     */
    public static getCommunityWishlistUsingGet(): CancelablePromise<DefaultHttpResponse_ListCommunityWishlistResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/wishlist/community',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 응급실 스크랩 목록 조회
     * @returns DefaultHttpResponse_EmergencyRoomWishlistResponse_ OK
     * @throws ApiError
     */
    public static getEmergencyRoomWishlistUsingGet(): CancelablePromise<DefaultHttpResponse_EmergencyRoomWishlistResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/wishlist/emergency-room',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 동네소식 스크랩 목록 조회
     * @returns DefaultHttpResponse_HometownNewsWishlistResponse_ OK
     * @throws ApiError
     */
    public static hometownNewsWishlistUsingGet(): CancelablePromise<DefaultHttpResponse_HometownNewsWishlistResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/wishlist/hometown-news',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 스크랩 목록 조회
     * @returns DefaultHttpResponse_MetaPolicyWishlistResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyWishlistUsingGet(): CancelablePromise<DefaultHttpResponse_MetaPolicyWishlistResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/wishlist/policy',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 바우처 사용처 스크랩 목록 조회
     * @returns DefaultHttpResponse_VoucherProviderWishlistResponse_ OK
     * @throws ApiError
     */
    public static voucherProviderWishlistUsingGet(): CancelablePromise<DefaultHttpResponse_VoucherProviderWishlistResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member/wishlist/voucher-provider',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
