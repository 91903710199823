import type { BANNER_CONTENTS_TYPE } from './types';

import { normalizeServerRequest } from '@common/utils';

import { ENV } from '@/constants';
import {
  OpenAPI as AuthOpenApi,
  ApiService as AuthApiService,
  Service as AuthService,
  NiceService as AuthNiceService,
  S3FileApiService as AuthS3FileApiService,
  KbPayApiService as AuthKbPayApiService,
} from '@generated/api/auth';
import {
  OpenAPI as DpgOpenApi,
  Service as DpgService,
  ApiService as DpgApiService,
} from '@generated/api/dpg';
import {
  OpenAPI as UnauthOpenApi,
  FaqApiService as UnauthFaqApiService,
  WelloSeoService as UnauthWelloSeoService,
  ApiService as UnauthApiService,
  Service as UnauthService,
  MemberOauthApiService as UnauthMemberOauthApiService,
  NiceService as UnauthNiceService,
  KbPayApiService as KbPayUnauthApiService,
} from '@generated/api/unauth';

import {
  fetchJejuApplyList,
  fetchJejuCommon,
  fetchJejuSchedule,
  fetchJejuTermList,
  postJejuApply,
  postJejuCancelConsult,
} from './jeju-youthdream';

const { NEXT_PUBLIC_API_DOMAIN, NEXT_PUBLIC_DPG_API_DOMAIN } = ENV;

AuthOpenApi.BASE = NEXT_PUBLIC_API_DOMAIN;
UnauthOpenApi.BASE = NEXT_PUBLIC_API_DOMAIN;
DpgOpenApi.BASE = NEXT_PUBLIC_DPG_API_DOMAIN;

/** 인증이 필요하지 않은 API */
export const UNAUTH_API = {
  getBannerList: normalizeServerRequest(
    (contentsTypeCd: BANNER_CONTENTS_TYPE) =>
      UnauthApiService.bannerListUsingGet({
        contentsTypeCd,
      }),
  ),

  //* 로그인 관련 API
  getUnifiedAccount: normalizeServerRequest(
    UnauthMemberOauthApiService.welloBeforeLoginUsingPost,
  ),
  getAccessToken: normalizeServerRequest(
    UnauthMemberOauthApiService.loginWelloSnsMemberUsingPost,
  ),
  getRefreshToken: normalizeServerRequest(
    UnauthMemberOauthApiService.snsRefreshTokenUsingGet,
  ),
  //* 💰 KB PAY 관련 API
  getKbMemberLoginType: normalizeServerRequest(
    UnauthMemberOauthApiService.getKbMemberLoginTypeListUsingGet,
  ),
  getKbPolicyFilterMetaCode: normalizeServerRequest(
    UnauthService.kbWelloPolicyCodeAllListUsingGet,
  ),
  //! 이벤트 끝나서 안씀
  joinKbEventPost: normalizeServerRequest(
    KbPayUnauthApiService.joinEventUsingPost,
  ),
  postJoinKbResult: normalizeServerRequest(
    AuthKbPayApiService.sendPinkeyUsingPost,
  ),

  //* 정책 소식 관련 API
  getPolicyInfoList: normalizeServerRequest(
    UnauthService.bestContentsListUsingGet1,
  ),
  getPolicyInfoDetail: normalizeServerRequest(
    UnauthService.getBestContentsUsingGet,
  ),
  getPolicyInfoFeedback: normalizeServerRequest(
    UnauthService.bestContentsFeedbackUsingGet1,
  ),

  //* 대상별 정책 API
  getWelloPickList: normalizeServerRequest(
    UnauthApiService.welloPickListUsingGet1,
  ),

  getPickPolicyList: normalizeServerRequest(
    UnauthApiService.welloPickPolicyListUsingGet1,
  ),

  //* 정책 관련 API
  getPolicyTop10List: normalizeServerRequest(
    UnauthService.metaPolicyTop10ListUsingGet,
  ),
  getPolicyDetail: normalizeServerRequest(UnauthService.metaPolicyUsingGet1),
  getPolicyFeedback: normalizeServerRequest(
    UnauthService.metaPolicyFeedbackUsingGet1,
  ),
  getPolicyCount: normalizeServerRequest(UnauthService.metaPolicyCountUsingGet),
  getPopularPolicyList: normalizeServerRequest(
    UnauthService.popularPolicyListUsingGet,
  ),
  getHomeLatestNewPolicyList: normalizeServerRequest(
    UnauthService.getLatestPolicyListUsingGet,
  ),
  getPolicyDictionaryList: normalizeServerRequest(
    UnauthApiService.getPolicyDictionaryListUsingGet,
  ),
  updatePolicyAiInfo: normalizeServerRequest(
    UnauthService.updatePolicyAiInfoUsingPut,
  ),
  getPolicyRelatedCommunityList: normalizeServerRequest(
    UnauthService.getPolicyRelatedCommunityListUsingGet,
  ),

  //* 내 상황에 맞는 정책 API
  getListPolicyCaseCategory: normalizeServerRequest(
    UnauthService.getListPolicyCaseCategoryUsingGet,
  ),
  getListChildcarePolicyBudget: normalizeServerRequest(
    UnauthService.getListChildcarePolicyBudgetUsingGet,
  ),
  getListChildcareRelatedPolicy: normalizeServerRequest(
    UnauthService.getListChildcareRelatedPolicyUsingGet1,
  ),

  //* 정책코드 관련 API
  getWelloPolicyCodeList: normalizeServerRequest(
    UnauthService.welloPolicyCodeListUsingGet,
  ),

  getWelloPolicyCodeAllList: normalizeServerRequest(
    UnauthService.welloPolicyCodeAllListUsingGet,
  ),

  getWelloPolicyCodeBabyList: normalizeServerRequest(
    UnauthService.welloPolicyCodeBabyListUsingGet,
  ),

  //* 연관 정책 노출 리스트 API
  getRelatedMetaPolicyList: normalizeServerRequest(
    UnauthService.getRelatedMetaPolicyListUsingGet1,
  ),

  //* 정책 꾸러미 API
  getSituationList: normalizeServerRequest(
    UnauthApiService.situationListUsingGet1,
  ),

  getSituationPolicyList: normalizeServerRequest(
    UnauthApiService.getSituationPolicyListUsingGet1,
  ),

  //* 댓글 관련 API
  getCommentList: normalizeServerRequest(
    UnauthApiService.getMetaPolicyCommentListUsingGet2,
  ),
  getCommentDetail: normalizeServerRequest(
    UnauthApiService.getMetaPolicyCommentListUsingGet1,
  ),

  getGeolocation: normalizeServerRequest(UnauthService.geolocationUsingGet),
  getGeocoding: normalizeServerRequest(UnauthService.reverseGeocodingUsingGet),
  getGeocodingByRegionCode: normalizeServerRequest(
    UnauthService.getNCloudGeocodingByCodeUsingGet,
  ),

  getLiveCommentList: normalizeServerRequest(
    UnauthApiService.getLiveWelloCommentListUsingGet1,
  ),

  //* 동네 소식 콘텐츠 관련 API
  getHometownList: normalizeServerRequest(
    UnauthService.hometownNewsListUsingGet1,
  ),
  getHometownNewsDetail: normalizeServerRequest(
    UnauthService.hometownNewsUsingGet1,
  ),
  getHometownNewsFeedback: normalizeServerRequest(
    UnauthService.hometownNewsFeedbackUsingGet1,
  ),
  getRelatedHometownNewsList: normalizeServerRequest(
    UnauthService.getRelatedHometownNewsListUsingGet,
  ),
  getPopularHometownNews: normalizeServerRequest(
    UnauthService.getPopularHometownNewsListUsingGet1,
  ),

  //* 메타코드 관련 API
  getMetaCode: normalizeServerRequest(UnauthApiService.metaCodeAllUsingGet),
  getMetaRegionLocation: normalizeServerRequest(
    UnauthService.getPolicyCodeGeocodingListUsingGet,
  ),

  //* FAQ 관련 API
  getFaq: normalizeServerRequest(UnauthFaqApiService.faqListUsingGet),

  //* 공지사항 관련 API
  getNotice: normalizeServerRequest(UnauthApiService.noticeListUsingGet1),
  getNoticeDetail: normalizeServerRequest(UnauthApiService.noticeUsingGet),

  //* 웰로 멤버 API
  getNickname: normalizeServerRequest(UnauthApiService.noticeListUsingGet),

  //* 약관 API
  getTerm: normalizeServerRequest(UnauthApiService.termUsingGet),
  getTermsList: normalizeServerRequest(UnauthApiService.termHistoryUsingGet),

  //* 푸시 API
  getAppPushCount: normalizeServerRequest(
    UnauthApiService.newWelloAppPushCntUsingGet,
  ),
  getAppPushList: normalizeServerRequest(
    UnauthApiService.welloAppPushListUsingGet,
  ),
  putAppPush: normalizeServerRequest(
    UnauthApiService.updateWelloAppPushReadUsingPut1,
  ),

  //*SEO 관련 API
  getSeoList: normalizeServerRequest(
    UnauthWelloSeoService.metaPolicyWishlistUsingGet1,
  ),
  getSeoTotalCount: normalizeServerRequest(
    UnauthWelloSeoService.metaPolicyWishlistUsingGet2,
  ),

  //* 검색 관련 API
  getSearchRecommendPolicyList: normalizeServerRequest(
    UnauthService.metaPolicyListUsingGet1,
  ),
  getSearchPolicyListByPolicyName: normalizeServerRequest(
    UnauthService.getPolicyListByPolicyNameUsingGet,
  ),

  //* Push 설정
  saveDeviceToken: normalizeServerRequest(
    UnauthApiService.saveAllowsWelloDeviceInfoUsingPost,
  ),

  //* NICE API
  checkNiceSuccess: normalizeServerRequest(
    UnauthNiceService.checkPlusSuccessUsingGet,
  ),

  checkAccessTokenStatus: normalizeServerRequest(
    UnauthMemberOauthApiService.tokenStatusCheckUsingPost,
  ),

  //* 커뮤니티
  getCommunityNewsList: normalizeServerRequest(
    UnauthService.getCommunityWelloNewsListUsingGet1,
  ),
  getCommunityPolicyTalkList: normalizeServerRequest(
    UnauthService.getCommunityPolicyTalkListUsingGet1,
  ),
  getCommunitySubCategoryList: normalizeServerRequest(
    UnauthService.getCommunitySubcategoryListByCodeUsingGet,
  ),
  getCommunityFreeTalkList: normalizeServerRequest(
    UnauthService.getCommunityFreeTalkListUsingGet1,
  ),
  getCommunityNewsDetail: normalizeServerRequest(
    UnauthService.getCommunityWelloNewsUsingGet1,
  ),
  getCommunityFreeTalkDetail: normalizeServerRequest(
    UnauthService.getCommunityFreeTalkUsingGet1,
  ),
  getCommunityPolicyTalkDetail: normalizeServerRequest(
    UnauthService.getCommunityPolicyTalkUsingGet1,
  ),

  getCommunityFreeTalkFeedback: normalizeServerRequest(
    UnauthService.feedbackCommunityFreeTalkUsingGet1,
  ),
  getCommunityPolicyTalkFeedback: normalizeServerRequest(
    UnauthService.feedbackCommunityPolicyTalkUsingGet1,
  ),
  getCommunityWelloNewsFeedback: normalizeServerRequest(
    UnauthService.feedbackCommunityWelloNewsUsingGet1,
  ),
  getLatestCommunityList: normalizeServerRequest(
    UnauthService.getLatestCommunityListUsingGet1,
  ),
  getCommunityFreeTalkHot: normalizeServerRequest(
    UnauthService.getCommunityFreeTalkHotListUsingGet1,
  ),
  getCommunityPolicyTalkHot: normalizeServerRequest(
    UnauthService.getCommunityPolicyTalkHotListUsingGet1,
  ),
  getRollingBannerList: normalizeServerRequest(
    UnauthService.getCommunityBannerListUsingGet,
  ),
  getNewPolicyInfo: normalizeServerRequest(
    UnauthService.getNewBestContentsListUsingGet1,
  ),
  getPolicyTalkPopular: normalizeServerRequest(
    UnauthService.getPopularCommunityPolicyTalkListUsingGet1,
  ),
  getCommunityHometownNewsPopular: normalizeServerRequest(
    UnauthService.getPopularHometownNewsListForCommunityUsingGet1,
  ),
  getPolicyInfoPopular: normalizeServerRequest(
    UnauthService.getPopularBestContentsListUsingGet1,
  ),
  getFreeTalkPopular: normalizeServerRequest(
    UnauthService.getPopularCommunityFreeTalkListUsingGet1,
  ),

  /** 바우처 관련 */
  getVoucherCategoryList: normalizeServerRequest(
    UnauthApiService.getVoucherCategoryListUsingGet,
  ),
  getVoucherCategoryPolicyList: normalizeServerRequest(
    UnauthApiService.getVoucherCategoryPolicyListUsingGet,
  ),
  getVoucherCardList: normalizeServerRequest(
    UnauthApiService.getEVoucherCardListUsingGet,
  ),
  getEVoucherProviderRegionList: normalizeServerRequest(
    UnauthApiService.getEVoucherProviderRegionListUsingGet,
  ),
  getVoucherListByLocation: normalizeServerRequest(
    UnauthApiService.getEVoucherProviderListUsingGet1,
  ),
  getVoucherListAll: normalizeServerRequest(
    UnauthApiService.getEVoucherProviderListAllUsingGet1,
  ),
  getEVoucherProvider: normalizeServerRequest(
    UnauthApiService.getEVoucherProviderUsingGet1,
  ),
  getVoucherBannerList: normalizeServerRequest(
    UnauthApiService.getVoucherBannerListUsingGet,
  ),
  getEVoucherProviderCardList: normalizeServerRequest(
    UnauthApiService.getEVoucherProviderCardListUsingGet,
  ),
  getEVoucherProviderFeedback: normalizeServerRequest(
    UnauthApiService.getEVoucherProviderFeedbackUsingGet1,
  ),
  getVoucherOnlineList: normalizeServerRequest(
    UnauthApiService.getVoucherOnlineListUsingGet,
  ),
  getVoucherOnlineCategoryList: normalizeServerRequest(
    UnauthApiService.getVoucherOnlineCategoryListUsingGet,
  ),

  //* 응급실 관련 API
  getEmergencyRoomCodeList: normalizeServerRequest(
    UnauthApiService.getEmergencyRoomCodeListUsingGet,
  ),
  getEmergencyRoomList: normalizeServerRequest(
    UnauthApiService.getEmergencyRoomListUsingGet1,
  ),
  getEmergencyRoomDetail: normalizeServerRequest(
    UnauthApiService.getEmergencyRoomUsingGet1,
  ),
  getEmergencyRoomLive: normalizeServerRequest(
    UnauthApiService.getEmergencyRoomLiveUsingGet,
  ),
  getMedicalPolicyList: normalizeServerRequest(
    UnauthService.allowsInterestPolicyListUsingGet,
  ),
  getEmergencyRoomCountOfRegion: normalizeServerRequest(
    UnauthApiService.getEmergencyRoomRegionListUsingGet,
  ),

  getBankList: normalizeServerRequest(UnauthService.bankListUsingGet),
  //* 트래킹 관련 API
  postWelloTrack: normalizeServerRequest(
    UnauthService.saveWelloTrackingUsingPost1,
  ),

  //* 포인트 관련 API
  getPointBudget: normalizeServerRequest(
    UnauthApiService.getPointBudgetUsingGet1,
  ),
  getPointPrice: normalizeServerRequest(
    UnauthApiService.getPointEarningUsingGet,
  ),

  //* 웰로픽 API
  getWelloPick: normalizeServerRequest(
    UnauthApiService.getWelloPickBannerListUsingGet,
  ),

  //* 링크 URL
  getExpositionLinkUrlList: normalizeServerRequest(
    UnauthService.expositionLinkUrlListUsingGet,
  ),
};

/** 인증이 필요한 API */
export const AUTH_API = {
  //* 로그인 관련 API
  getMyInfo: normalizeServerRequest(AuthApiService.memberUsingGet),
  getSocialAccounts: normalizeServerRequest(
    AuthApiService.memberSocialAccountsUsingGet,
  ),
  deleteSocialAccounts: normalizeServerRequest(
    AuthApiService.deleteMemberSocialAccountsUsingDelete,
  ),
  postImageUpload: normalizeServerRequest(AuthApiService.fileUploadUsingPost),
  putProfileChange: normalizeServerRequest(
    AuthApiService.updateMemberNickNameUsingPut,
  ),
  putMessageReceive: normalizeServerRequest(
    AuthApiService.messageReceiveUsingPut,
  ),
  postMemberVerification: normalizeServerRequest(
    AuthService.decryptMemberVerificationUsingPost,
  ),
  putVerification: normalizeServerRequest(
    AuthApiService.updateMemberVerificationUsingPut,
  ),

  resignMember: normalizeServerRequest(AuthApiService.resignMemberUsingDelete),

  //* CI 정보 관련 API
  getMemberVerification: normalizeServerRequest(
    AuthService.getLatestCompletedCiUsingPost,
  ),

  logout: normalizeServerRequest(AuthApiService.logoutMemberUsingGet),

  //* 푸시 관련 API
  getAppPush: normalizeServerRequest(
    AuthApiService.getWebWelloAppPushListUsingGet,
  ),
  putAppPush: normalizeServerRequest(
    AuthApiService.updateWelloAppPushReadUsingPut,
  ),

  //* 회원가입 관련 API
  postProfile: normalizeServerRequest(
    AuthApiService.saveMemberNickNameUsingPost,
  ),
  postRegionData: normalizeServerRequest(AuthApiService.noticeListUsingPost),

  //! 이벤트 끝나서 안씀
  postJoinKbResult: normalizeServerRequest(
    AuthKbPayApiService.sendPinkeyUsingPost,
  ),

  //* 회원 신규 맞춤정책 조회 API
  getRecommendPolicyList: normalizeServerRequest(
    AuthApiService.memberNewPolicyListUsingGet,
  ),

  //* 회원 관심 분야별 맞춤정책 조회 API
  getInterestPolicyList: normalizeServerRequest(
    AuthApiService.memberInterestPolicyListUsingGet,
  ),

  //* 회원 최근 본 정책 조회 API
  getRecentPolicyList: normalizeServerRequest(
    AuthApiService.memberRecentPolicyListUsingGet,
  ),

  //* 회원 최근 본 정책과 유사한 정책 조회 API
  getRecentSimilarPolicy: normalizeServerRequest(
    AuthApiService.memberRecommendPolicyByRecentListUsingGet,
  ),

  //* 우리동네 이웃이 많이 본 정책 조회 API
  getHometownPopularPolicy: normalizeServerRequest(
    AuthApiService.memberNeighborPolicyListUsingGet,
  ),

  //* 정책
  getPolicyFeedback: normalizeServerRequest(
    AuthService.metaPolicyFeedbackUsingGet,
  ),
  getPeerGroupPolicy: normalizeServerRequest(
    AuthApiService.memberPeerGroupPolicyListUsingGet,
  ),

  //* 연관 정책 노출 리스트 API
  getRelatedMetaPolicyList: normalizeServerRequest(
    AuthService.getRelatedMetaPolicyListUsingGet,
  ),

  //* 대상별 정책 API
  getPickPolicyList: normalizeServerRequest(
    AuthApiService.welloPickPolicyListUsingGet,
  ),

  //* 정책 꾸러미 API
  getSituationPolicyList: normalizeServerRequest(
    AuthApiService.getSituationPolicyListUsingGet,
  ),

  //* 정책 소식 관련 API
  getPolicyInfoList: normalizeServerRequest(
    AuthService.bestContentsListUsingGet,
  ),
  getPolicyInfoDetail: normalizeServerRequest(AuthService.bestContentsUsingGet),

  getPolicyAuthDetail: normalizeServerRequest(AuthService.metaPolicyUsingGet),

  getPolicyInfoFeedback: normalizeServerRequest(
    AuthService.bestContentsFeedbackUsingGet,
  ),

  //* 내 상황에 맞는 정책
  getListChildcareRelatedPolicy: normalizeServerRequest(
    AuthService.getListChildcareRelatedPolicyUsingGet,
  ),

  //* 댓글 관련 API
  getCommentList: normalizeServerRequest(
    AuthApiService.getWelloCommentListUsingGet,
  ),
  postComment: normalizeServerRequest(AuthApiService.saveWelloCommentUsingPost),
  putComment: normalizeServerRequest(AuthApiService.updateWelloCommentUsingPut),
  getMyCommentList: normalizeServerRequest(
    AuthApiService.getMyWelloCommentListUsingGet,
  ),
  getLiveCommentList: normalizeServerRequest(
    AuthApiService.getLiveWelloCommentListUsingGet,
  ),
  putDeleteComment: normalizeServerRequest(
    AuthApiService.deleteWelloCommentUsingPut,
  ),
  postCommentReport: normalizeServerRequest(
    AuthApiService.saveWelloCommentReportUsingPost,
  ),
  postPick: normalizeServerRequest(AuthService.saveCommentPickUsingPost),

  //* 좋아요 관련 API
  postLike: normalizeServerRequest(AuthApiService.saveWelloLikeUsingPost),
  deleteLike: normalizeServerRequest(AuthApiService.deleteWelloLikeUsingDelete),
  getListLikeContentsType: normalizeServerRequest(
    AuthApiService.getListLikeContentsTypeUsingGet,
  ),
  getListLikeContents: normalizeServerRequest(
    AuthApiService.getListLikeContentsUsingGet,
  ),

  //* 북마크 관련 API
  postBookmark: normalizeServerRequest(AuthApiService.saveWishlistUsingPost1),
  deleteBookmark: normalizeServerRequest(
    AuthApiService.deleteWishlistUsingDelete,
  ),

  //* 가족 관련 API
  getFamilyList: normalizeServerRequest(
    AuthApiService.getWelloMemberFamilyUsingGet,
  ),

  postFamily: normalizeServerRequest(
    AuthApiService.saveWelloMemberFamilyUsingPost,
  ),

  putFamily: normalizeServerRequest(
    AuthApiService.updateWelloMemberFamilyUsingPut,
  ),

  deleteFamily: normalizeServerRequest(
    AuthApiService.deleteWelloMemberFamilyUsingDelete,
  ),

  //* 동네 소식 콘텐츠 관련 API
  getHometownNewsFeedback: normalizeServerRequest(
    AuthService.hometownNewsFeedbackUsingGet,
  ),
  getHometownList: normalizeServerRequest(AuthService.hometownNewsListUsingGet),
  getHometownNewsDetail: normalizeServerRequest(
    AuthService.hometownNewsUsingGet,
  ),

  //* 스크랩 관련 API
  getBestContentsWishlist: normalizeServerRequest(
    AuthApiService.bestContentsWishlistUsingGet,
  ),
  getHometownNewsWishlist: normalizeServerRequest(
    AuthApiService.hometownNewsWishlistUsingGet,
  ),
  getPolicyWishlist: normalizeServerRequest(
    AuthApiService.metaPolicyWishlistUsingGet,
  ),
  getVoucherProviderWishlist: normalizeServerRequest(
    AuthApiService.voucherProviderWishlistUsingGet,
  ),
  getEmergencyRoomWishlist: normalizeServerRequest(
    AuthApiService.getEmergencyRoomWishlistUsingGet,
  ),
  getCommunityWishlist: normalizeServerRequest(
    AuthApiService.getCommunityWishlistUsingGet,
  ),

  //* 문의하기 관련 API
  getInquiryList: normalizeServerRequest(AuthService.inquiryListUsingGet),

  postInquiry: normalizeServerRequest(AuthService.saveInquiryUsingPost),

  getInquiry: normalizeServerRequest(AuthService.getInquiryUsingGet),

  //* 멤버 인증 관련 API
  postCertificationCellphone: normalizeServerRequest(
    AuthApiService.sendCertificationCellphoneUsingPost,
  ),
  postCertificationCheck: normalizeServerRequest(
    AuthApiService.certificationCheckUsingPost,
  ),

  //* 맞춤 필터 관련 API
  getCustomPolicyFilterData: normalizeServerRequest(
    AuthApiService.requiredFilterUsingGet,
  ),
  getInterestedFilterData: normalizeServerRequest(
    AuthApiService.interestFilterUsingGet,
  ),
  postInterestedFilterData: normalizeServerRequest(
    AuthApiService.saveInterestFilterUsingPost,
  ),
  //TODO: 추후 회원 정보 수정 변경 시 삭제
  postCustomPolicyFilterData: normalizeServerRequest(
    AuthApiService.putFitFilterUsingPost,
  ),
  postPolicyFilter: normalizeServerRequest(
    AuthApiService.saveFitFilterUsingPost,
  ),

  //* 검색 관련 API
  getSearchRecommendPolicyList: normalizeServerRequest(
    AuthService.metaPolicyListUsingGet,
  ),

  //* PEEC
  postApplyEvent: normalizeServerRequest(
    AuthService.saveWelloParticipationUsingPost,
  ),

  //* Push 설정
  saveDeviceToken: normalizeServerRequest(
    AuthApiService.saveWelloDeviceInfoUsingPost,
  ),

  //* 정책 바로신청 API
  getPolicyApplyHistoryList: normalizeServerRequest(
    AuthService.getWelloPolicyApplyListUsingGet,
  ),
  getPolicyApplyHistoryCount: normalizeServerRequest(
    AuthService.getWelloPolicyApplyCountUsingGet,
  ),
  postPolicyApply: normalizeServerRequest(AuthService.saveReportUsingPost),

  //* NICE API
  sendNiceMessage: normalizeServerRequest(
    AuthNiceService.sendNiceSafeKeyUsingPost,
  ),

  getVerificationId: normalizeServerRequest(
    AuthService.getMemberVerificationIdUsingGet,
  ),

  //* 커뮤니티
  getCommunityNewsList: normalizeServerRequest(
    AuthService.getCommunityWelloNewsListUsingGet,
  ),
  getCommunityPolicyTalkList: normalizeServerRequest(
    AuthService.getCommunityPolicyTalkListUsingGet,
  ),
  getCommunityFreeTalkList: normalizeServerRequest(
    AuthService.getCommunityFreeTalkListUsingGet,
  ),
  getCommunityNewsDetail: normalizeServerRequest(
    AuthService.getCommunityWelloNewsUsingGet,
  ),
  getCommunityFreeTalkDetail: normalizeServerRequest(
    AuthService.getCommunityFreeTalkUsingGet,
  ),
  getCommunityPolicyTalkDetail: normalizeServerRequest(
    AuthService.getCommunityPolicyTalkUsingGet,
  ),
  getMyCommunityTypeListUsingGet: normalizeServerRequest(
    AuthService.getMyCommunityTypeListUsingGet,
  ),
  getMyCommunityList: normalizeServerRequest(
    AuthService.getMyCommunityListUsingGet,
  ),

  postPolicyTalk: normalizeServerRequest(
    AuthService.createCommunityPolicyTalkUsingPost,
  ),

  putPolicyTalk: normalizeServerRequest(
    AuthService.updateCommunityPolicyTalkUsingPut,
  ),

  postFreeTalk: normalizeServerRequest(
    AuthService.createCommunityFreeTalkUsingPost,
  ),

  putFreeTalk: normalizeServerRequest(
    AuthService.updateCommunityFreeTalkUsingPut,
  ),

  uploadFile: normalizeServerRequest(AuthS3FileApiService.fileUploadUsingPost1),
  deleteCommunityFreeTalkDetail: normalizeServerRequest(
    AuthService.deleteCommunityFreeTalkUsingPut,
  ),
  deleteCommunityPolicyTalkDetail: normalizeServerRequest(
    AuthService.deleteCommunityPolicyTalkUsingPut,
  ),
  postBlockCommunityMember: normalizeServerRequest(
    AuthApiService.blockWelloMemberByCommunityUsingPost,
  ),
  postCommunityReport: normalizeServerRequest(
    AuthService.reportCommunityUsingPost,
  ),

  getCommunityFreeTalkFeedback: normalizeServerRequest(
    AuthService.feedbackCommunityFreeTalkUsingGet,
  ),
  getCommunityPolicyTalkFeedback: normalizeServerRequest(
    AuthService.feedbackCommunityPolicyTalkUsingGet,
  ),
  getCommunityWelloNewsFeedback: normalizeServerRequest(
    AuthService.feedbackCommunityWelloNewsUsingGet,
  ),

  //* 💰 KB PAY 관련 API
  checkKbPayMember: normalizeServerRequest(
    AuthApiService.kbMemberCheckUsingPost,
  ),
  getLatestCommunityList: normalizeServerRequest(
    AuthService.getLatestCommunityListUsingGet,
  ),
  postKbPolicyFilter: normalizeServerRequest(
    AuthApiService.saveKbFilterUsingPost,
  ),

  getDisplayBannerType: normalizeServerRequest(
    AuthApiService.getDisplayBannerTypeUsingGet,
  ),

  //* 약관 관련 API
  getLocationServiceConsent: normalizeServerRequest(
    AuthApiService.getLocationServiceConsentUsingGet,
  ),
  updateLocationServiceConsent: normalizeServerRequest(
    AuthApiService.updateLocationServiceConsentUsingPut,
  ),
  getPrivateDataConsent: normalizeServerRequest(
    AuthApiService.getPrivateDataConsentUsingGet,
  ),
  updatePrivateDataConsent: normalizeServerRequest(
    AuthApiService.updatePrivateDataConsentUsingPut,
  ),

  //* 바우처
  getVoucherListByLocation: normalizeServerRequest(
    AuthApiService.getEVoucherProviderListUsingGet,
  ),
  getVoucherListAll: normalizeServerRequest(
    AuthApiService.getEVoucherProviderListAllUsingGet,
  ),
  getEVoucherProviderFeedback: normalizeServerRequest(
    AuthApiService.getEVoucherProviderFeedbackUsingGet,
  ),

  //* 응급실 관련 정책
  getEmergencyRoomDetail: normalizeServerRequest(
    AuthApiService.getEmergencyRoomUsingGet,
  ),
  getMedicalPolicyList: normalizeServerRequest(
    AuthService.getInterestPolicyListUsingGet,
  ),

  //* 포인트
  getDetailPointBalance: normalizeServerRequest(
    AuthApiService.getDetailPointBalanceUsingGet,
  ),
  getListPointAttendance: normalizeServerRequest(
    AuthApiService.getListPointAttendanceUsingGet,
  ),
  getInvitedMemberCount: normalizeServerRequest(
    AuthApiService.getInvitedMemberCountUsingGet,
  ),
  saveTodayAttendance: normalizeServerRequest(
    AuthApiService.saveTodayAttendanceUsingPost,
  ),
  getPointHistoryList: normalizeServerRequest(
    AuthApiService.getListPointHistoryUsingGet,
  ),
  postMemberAccount: normalizeServerRequest(
    AuthService.saveMemberAccountUsingPost,
  ),
  getMemberAccount: normalizeServerRequest(
    AuthService.getMemberAccountUsingGet,
  ),
  postPointWithdrawal: normalizeServerRequest(
    AuthApiService.savePointWithdrawalUsingPost,
  ),
  getPointBudget: normalizeServerRequest(AuthApiService.getPointBudgetUsingGet),

  //* 웰퍼
  postWelloHelper: normalizeServerRequest(
    AuthApiService.saveWelloHelperUsingPost,
  ),

  //* 트래킹 관련 API
  postWelloTrack: normalizeServerRequest(
    AuthService.saveWelloTrackingUsingPost,
  ),

  getUserActionCount: normalizeServerRequest(
    AuthApiService.getActionCountUsingGet,
  ),
};

/** 외부(장학재단) API */
export const DPG_API = {
  postEligibilityCheck: DpgService.getEligibilityCheck,
  getNotice: DpgService.getNotice,
  getLoanInterestList: DpgService.getLoanInterestList,
  getAgreement: DpgService.getAgreement,
  getReportHistoryList: DpgService.getReportHistoryList,
  getReportHistory: DpgService.getReportHistory,
  postReport: DpgService.saveReport,
  getAddress: DpgService.getAddress,
};

/** 제주청년드림 API */
export const JEJU_DREAM_API = {
  fetchJejuCommon,
  fetchJejuTermList,
  fetchJejuApplyList,
  postJejuCancelConsult,
  fetchJejuSchedule,
  postJejuApply,
  postJejuYouthdreamApply: DpgApiService.sendJejuYouthDreamService,
};

export const CARD_API = {
  getAvailCardList: normalizeServerRequest(UnauthService.availCardListUsingGet),
  getCardList: normalizeServerRequest(UnauthService.cardListUsingGet),
  getBankList: normalizeServerRequest(UnauthService.bankListUsingGet),
  postBankAccount: normalizeServerRequest(
    AuthService.saveMemberAccountUsingPost,
  ),
  postMemberCard: normalizeServerRequest(AuthService.saveMemberCardUsingPost),
  postMemberVerificationInfo: normalizeServerRequest(
    AuthService.getLatestCompletedUsingPost,
  ),
  getPaymentHistory: normalizeServerRequest(AuthService.paymentHisListUsingGet),
  getPaymentHistoryCount: normalizeServerRequest(
    AuthService.totalPaymentHisListUsingGet,
  ),
  getIssuanceProgress: normalizeServerRequest(
    AuthService.memberCardListUsingGet,
  ),
  postUpdateMemberCardApply: normalizeServerRequest(
    AuthService.updateIssueAtUsingPut,
  ),

  postCardImageUpload: normalizeServerRequest(
    AuthService.uploadCardImageUsingPut,
  ),
};

export * from './types';
export { AuthOpenApi };
