export enum BANNER_CONTENTS_TYPE {
  CONTENTS = 'BANNER_CONTENTS_TYPE_CONTENTS',
  EVENT = 'BANNER_CONTENTS_TYPE_EVENT',
  COMMUNITY_MAIN_TOP = 'BANNER_CONTENTS_TYPE_COMMUNITY_MAIN_TOP',
  COMMUNITY_MAIN_MIDDLE = 'BANNER_CONTENTS_TYPE_COMMUNITY_MAIN_MIDDLE',
  HOME_LOWER_BIG = 'BANNER_CONTENTS_TYPE_HOME_LOWER_BIG',
  HOME_UPPER_BIG = 'BANNER_CONTENTS_TYPE_HOME_UPPER_BIG',
  HOME_LOWER = 'BANNER_CONTENTS_TYPE_HOME_LOWER',
  HOME_MIDDLE = 'BANNER_CONTENTS_TYPE_HOME_MIDDLE',
  HOME_UPPER = 'BANNER_CONTENTS_TYPE_HOME_UPPER',
  HOMETOWN_TOP = 'BANNER_CONTENTS_TYPE_HOMETOWN_TOP',
  HOMETOWN_FLOATING = 'BANNER_CONTENTS_TYPE_HOMETOWN_FLOATING',
  HOMETOWN_BOTTOM_SHEET = 'BANNER_CONTENTS_TYPE_HOMETOWN_BOTTOM_SHEET',
}

export enum LOGIN_TYPE {
  EMAIL = 'MEMBER_LOGIN_TYPE_EMAIL',
  GOOGLE = 'MEMBER_LOGIN_TYPE_GOOGLE',
  NAVER = 'MEMBER_LOGIN_TYPE_NAVER',
  KAKAO = 'MEMBER_LOGIN_TYPE_KAKAO',
  APPLE = 'MEMBER_LOGIN_TYPE_APPLE',
}

export enum COMMUNITY_POST_SORT_TYPE {
  LATEST = 'LATEST',
  POPULARITY = 'POPULARITY',
}

export enum MY_LIKED_SORT_TYPE {
  LATEST = 'LATEST',
  POPULARITY = 'POPULARITY',
}

export enum COMMENT_TYPE {
  POLICY = 'WELLO_COMMENT_TYPE_POLICY',
  POLICY_INFO = 'WELLO_COMMENT_TYPE_BEST_CONTENTS',
  HOMETOWN_NEWS = 'WELLO_COMMENT_TYPE_HOMETOWN_NEWS',
  COMMUNITY = 'WELLO_COMMENT_TYPE_COMMUNITY',
}

export enum COMMENT_SORT_TYPE {
  POPULARITY = 'POPULARITY',
  ENROLL = 'ENROLL',
  LATEST = 'LATEST',
}

export enum COMMENT_STATUS {
  DELETED = 'WELLO_COMMENT_STATUS_DELETE',
  DISPLAY = 'WELLO_COMMENT_STATUS_DISPLAY',
  NON_DISPLAY = 'WELLO_COMMENT_STATUS_NON_DISPLAY',
}

export enum PROVISION_TYPE {
  'C09-00' = '현금',
  'C09-01' = '현물',
  'C09-02' = '이용권',
  'C09-03' = '서비스',
  'C09-04' = '의료지원',
  'C09-05' = '상담/법률지원',
  'C09-06' = '문화/여가지원',
  'C09-07' = '민원',
  'C09-08' = '봉사/기부',
  'C09-10' = '교육지원',
  'C09-11' = '주거지원',
  'C09-09' = '기타',
}

export enum MY_LIKED_CONTENTS_TYPE {
  POLICY_TALK = 'POLICY_TALK',
  FREE_TALK = 'FREE_TALK',
  HOMETOWN_NEWS = 'WELLO_LIKE_TYPE_HOMETOWN_NEWS',
  BEST_CONTENTS = 'WELLO_LIKE_TYPE_BEST_CONTENTS',
  POLICY = 'WELLO_LIKE_TYPE_POLICY',
  WELLO_NEWS = 'WELLO_NEWS',
}

export enum LIKE_TYPE {
  /** 정책 */
  POLICY = 'WELLO_LIKE_TYPE_POLICY',
  /** 정책 소식 */
  POLICY_INFO = 'WELLO_LIKE_TYPE_BEST_CONTENTS',
  /** 댓글 */
  COMMENT = 'WELLO_LIKE_TYPE_COMMENT',
  /** 댓글 */
  HOMETOWN_NEWS = 'WELLO_LIKE_TYPE_HOMETOWN_NEWS',
  /** 커뮤니티 (웰로소식, 정책수다, 자유수다) */
  COMMUNITY = 'WELLO_LIKE_TYPE_COMMUNITY',
}

export enum BOOKMARK_TYPE {
  /** 정책 */
  POLICY = 'WELLO_WISHLIST_CONTENT_TYPE_POLICY',
  /** 정책 소식 */
  POLICY_INFO = 'WELLO_WISHLIST_CONTENT_TYPE_BEST_CONTENTS',
  /** 동네 소식 */
  HOMETOWN_NEWS = 'WELLO_WISHLIST_CONTENT_TYPE_HOMETOWN_NEWS',

  /** 바우처 제공처 */
  VOUCHER_PROVIDER = 'WELLO_WISHLIST_CONTENT_TYPE_VOUCHER_PROVIDER',

  /** 응급의료기관 */
  EMERGENCY_ROOM = 'WELLO_WISHLIST_CONTENT_TYPE_EMERGENCY_ROOM',

  /** 커뮤니티 */
  COMMUNITY = 'WELLO_WISHLIST_CONTENT_TYPE_COMMUNITY',
}

export enum SEARCH_POLICY_CODE {
  C01 = 'C01',
  C02 = 'C02',
  C03 = 'C03',
  C04 = 'C04',
  C05 = 'C05',
  C06 = 'C06',
  C07 = 'C07',
  C12 = 'C12',
}

export enum WELLO_POLICY_CODE_TYPE {
  ETC = 'WELLO_POLICY_CODE_TYPE_ETC',
  INTEREST = 'WELLO_POLICY_CODE_TYPE_INTEREST',
  REQUIRED = 'WELLO_POLICY_CODE_TYPE_REQUIRED',
}

export enum WELLO_POLICY_CODE_FILTER {
  ADD = 'WELLO_POLICY_CODE_FILTER_ADD',
  BASIC = 'WELLO_POLICY_CODE_FILTER_BASIC',
}

export const INQUIRY_CATEGORY = {
  CONCIERGE: { code: 'INQUIRY_CATEGORY_POLICY', value: '컨시어지문의' },
  POLICY: { code: 'INQUIRY_CATEGORY_SERVICE', value: '정책문의' },
  SERVICE: { code: 'INQUIRY_CATEGORY_ERROR', value: '서비스제안' },
  ERROR: { code: 'INQUIRY_CATEGORY_USE', value: '오류신고' },
  USE: { code: 'INQUIRY_CATEGORY_USE', value: '이용문의' },
  ETC: { code: 'INQUIRY_CATEGORY_ETC', value: '기타' },
} as const;

export const INQUIRY_STATUS = {
  COMPLETE: {
    code: 'INQUIRY_STATUS_COMPLETE',
    value: '답변 완료',
  },
  WAITING: {
    code: 'INQUIRY_STATUS_WAITING',
    value: '답변 대기',
  },
} as const;

export enum MEMBER_JOIN_STATUS {
  /** 닉네임 없음 */
  NO_NICKNAME = 'WELLO_MEMBER_FILTER_SAVE_STATUS_NICK',
  /** 필터 아예 없음 */
  EMPTY_FILTER = 'WELLO_MEMBER_FILTER_SAVE_STATUS_EMPTY',
  /**
   * 전화번호 없음
   * @description 실제 MEMBER_FILTER_SAVE_STATUS는 아니지만 전화번호가 없으면 완료가 아님
   */
  NO_PHONE = 'MEMBER_STATUS_MISSING_CELLPHONE',
  /** 기본 필터 저장 완료 */
  COMPLETE = 'WELLO_MEMBER_FILTER_SAVE_STATUS_COMPLETE',
}

export enum TERM_CATEGORY {
  /** 서비스 이용약관 */
  SERVICE_TERMS = 'use_service',
  /** 개인정보 처리방침 */
  PRIVACY_POLICY = 'privacy_policy',
  /** 마케팅 이용동의 */
  MARKETING_TERMS = 'marketing',
  /** 공모전 참여 서약 및 저작권 안내 */
  EVENT_PLEDGE = 'wello_event_pledge',
  /** 공모전 개인정보 처리방침 */
  EVENT_PRIVACY_POLICY = 'wello_event_privacy_policy',
  /** 개인정보 수집 ･ 이용 동의(맞춤정책) (선택) */
  FIT_PRIVACY_USE_POLICY_SELECT = 'fit_privacy_use_policy_select',
  PRIVACY_USE_POLICY = 'privacy_use_policy',
  /** 위치기반서비스 이용약관 동의 (선택) */
  LOCATION_POLICY_SELECT = 'location_policy_select',
  /** KB 개인정보 수집 동의 (맞춤정책용) */
  KB_PRIVACY_USE_POLICY = 'kb_privacy_use_policy',
  /** 개인정보 제3자 제공 동의(한국장학재단) */
  KOSAF_PROVIDE_PRIVACY_SELECT = 'kosaf_provide_privacy_select',
  /** 개인정보 제3자 제공 동의(제주청년센터) */
  JEJU_PROVIDE_PRIVACY_SELECT = 'jeju_provide_privacy_select',
}

export enum VERIFICATION_TYPE {
  SIGN_UP = 'VERIFICATION_TYPE_WELLO_SIGN_UP',
  PROFILE_UPDATE = 'VERIFICATION_TYPE_WELLO_PROFILE_UPDATE',
  POLICY_APPLY = 'VERIFICATION_TYPE_WELLO_POLICY_APPLY',
}

export enum EASY_LOGIN_USAGE_CODE {
  /** 회원가입 */
  SIGN_UP = '01001',
  /** 정보변경 */
  UPDATE_INFO = '01002',
  /** ID 찾기 */
  FIND_ID = '01003',
  /** 비밀번호 찾기 */
  FIND_PASSWORD = '01004',
  /** 본인 확인용 */
  VERIFICATION = '01005',
  /** 성인 인증 */
  ADULT_VERIFICATION = '01006',
  /** 상품 구매/결제 */
  PURCHASE_PAYMENT = '01007',
  /** 기타 */
  OTHER = '01999',
}

// * API가 나오고 수정될 수 있음
export enum COMMUNITY_CATEGORY {
  /** 웰로소식 */
  NEWS = 'WELLO_NEWS',
  /** 정책수다 */
  POLICY_TALK = 'POLICY_TALK',
  /** 자유수다 */
  FREE_TALK = 'FREE_TALK',
  /** 동네소식 */
  HOMETOWN_NEWS = 'HOMETOWN_NEWS',
  /** 정책소식 */
  POLICY_INFO = 'POLICY_INFO',
}

export enum WELLO_APP_PUSH_PARENT_CATEGORY_TYPE {
  POLICY = 'WELLO_APP_PUSH_PARENT_CATEGORY_TYPE_POLICY',
  COMMUNITY = 'WELLO_APP_PUSH_PARENT_CATEGORY_TYPE_COMMUNITY',
  SERVICE = 'WELLO_APP_PUSH_PARENT_CATEGORY_TYPE_SERVICE',
}

//* POLICY_CASE_CATEGORY 의 타입 (부모 카테고리)
export enum POLICY_CASE_TYPE {
  /** 육아 */
  BABY = 'POLICY_CASE_TYPE_BABY',
  /** 의료 */
  EMERGENCY = 'POLICY_CASE_TYPE_EMERGENCY',
  /** 오픈 예정 */
  SCHEDULE = 'POLICY_CASE_TYPE_SCHEDULE',
}

export enum POLICY_CASE_CATEGORY {
  /** 육아생활 */
  BABY = 'BABY',
  /** 응급 */
  EMERGENCY = 'EMERGENCY',
}

export enum POLICY_CASE_BABY_CHILD_COUNT {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
  FIFTH = 5,
}

export enum AVAIL_CARD_TYPE {
  K_PASS = 'AVAIL_CARD_TYPE_K_PASS',
  HAPPINESS = 'AVAIL_CARD_TYPE_HAPPINESS',
  CEO = 'AVAIL_CARD_TYPE_CEO',
}

export enum VERIFICATION_SERVICE_TYPE {
  MOBILE_OK = 'VERIFICATION_SERVICE_TYPE_MOBILE_OK',
  EZI_OK = 'VERIFICATION_SERVICE_TYPE_EZI_OK',
}

export enum GPT_PROMPT_TYPE {
  KEYWORD = 'KEYWORD_PROMPT',
  SUMMARY = 'SUMMARY_PROMPT',
}

export enum POINT_ACTIVITY {
  BEST_CONTENTS_COMMENT_WRITE = 'ACTIVITY_DETAIL_TYPE_BEST_CONTENTS_COMMENT_WRITE',
  FREE_TALK_COMMENT_WRITE = 'ACTIVITY_DETAIL_TYPE_FREE_TALK_COMMENT_WRITE',
  FREE_TALK_WRITE = 'ACTIVITY_DETAIL_TYPE_FREE_TALK_WRITE',
  HOMETOWN_NEWS_COMMENT_WRITE = 'ACTIVITY_DETAIL_TYPE_HOMETOWN_NEWS_COMMENT_WRITE',
  INVITATION = 'ACTIVITY_DETAIL_TYPE_INVITATION',
  JOIN = 'ACTIVITY_DETAIL_TYPE_JOIN',
  MEMBER_FILTER = 'ACTIVITY_DETAIL_TYPE_MEMBER_FILTER',
  POLICY_TALK_COMMENT_WRITE = 'ACTIVITY_DETAIL_TYPE_POLICY_TALK_COMMENT_WRITE',
  POLICY_TALK_WRITE = 'ACTIVITY_DETAIL_TYPE_POLICY_TALK_WRITE',
  WELPER_COMMENT_WRITE = 'ACTIVITY_DETAIL_TYPE_WELPER_COMMENT_WRITE',
  //! 백엔드에 없는값
  ATTENDANCE_CHECK = 'ACTIVITY_DETAIL_TYPE_ATTENDANCE_CHECK',
}
