import { type ServerResponse } from '@common/utils';
import { CustomError } from '@common/utils/normalizeServerRequest';

import { jejuApi } from './jejuApi';

interface TermDetails {
  id: number;
  termsId: string;
  termsName: string;
  termsContent: string;
  termsStartDate: string;
}

interface TermApiResponse extends ServerResponse {
  context: TermDetails[];
}

export const fetchJejuTermList = async () => {
  try {
    const { context } = await jejuApi.get('/api001').json<TermApiResponse>();

    return context;
  } catch (error) {
    throw new CustomError();
  }
};
