import { CustomError, type ServerResponse } from '@common/utils';

import { jejuApi } from './jejuApi';

export interface ScheduleDetail {
  id: { adviceFormDate: string; adviceFormTime: string };
  adviceFormMaxCount: number;
  isOpen: boolean;
  isHoliday: string;
  formCount: number;
}

interface ScheduleApiResponse extends ServerResponse {
  context: ScheduleDetail[];
}

export const fetchJejuSchedule = async (startDate: string) => {
  try {
    const { context } = await jejuApi
      .get('/api100', {
        searchParams: {
          startDate: startDate,
          endDate: startDate,
        },
      })
      .json<ScheduleApiResponse>();

    return context;
  } catch (error) {
    throw new CustomError();
  }
};
