import { CustomError, type ServerResponse } from '@common/utils';

import { jejuApi } from './jejuApi';

interface LabeledCode {
  code: string;
  value: string;
}

type CommonFields =
  | 'userParticipationTypes'
  | 'userInflowTypes'
  | 'youthPolicySupportCategoryTypes'
  | 'adviceSpaceTypes'
  | 'userWorkTypeTypes'
  | 'adviceEducationTypes'
  | 'adviceParticipationTypes'
  | 'adviceResidenceTypes'
  | 'userImmigrantPeriodTypes'
  | 'userImmigrantTypes'
  | 'userChildTypes'
  | 'adviceWelfareTypes'
  | 'adviceCultureTypes'
  | 'userEducationTypes'
  | 'adviceFinanceTypes'
  | 'adviceWorkTypes'
  | 'userMarriageTypes';

interface CommonApiResponse extends ServerResponse {
  context: Record<CommonFields, LabeledCode[]>;
}

export const fetchJejuCommon = async () => {
  try {
    const { context } = await jejuApi.get<CommonApiResponse>('/api000').json();

    return context;
  } catch (error) {
    throw new CustomError();
  }
};
