import { CustomError, type ServerResponse } from '@common/utils';

import { jejuApi } from './jejuApi';

// 제주청년 API
interface User {
  uniqId: string;
  name: string;
  phone: string;
  email: string;
  adres: string;
  detailAdres: string;
  birth1: string;
  birth2: string;
}

interface Data {
  adviceFormDate: string;
  adviceFormTime: string;
  adviceUserMemo: string;
  adviceCategory1: string;
  adviceCategory2: string;
  adviceCategory3: string;
  userEducation: string;
  userWorkType: string;
  userImmigrant: string;
  userMarriage: string;
  userChild: string;
  userParticipation: string;
  userInflowTypes: string[];
}

interface JejuPostData {
  user: User;
  data: Data;
}

export const postJejuApply = async (postData: JejuPostData) => {
  try {
    const data = await jejuApi
      .post('/api102', {
        json: postData,
      })
      .json<ServerResponse>();

    return data;
  } catch (error) {
    throw new CustomError();
  }
};
