import type { Region } from '@/types';

import { create } from 'zustand';

import { type MEMBER_JOIN_STATUS } from '@/api';

export interface MyInfo {
  nickName?: string;
  email?: string;
  id?: number;
  age?: number;
  gender?: string;
  /** 개인 식별 코드 */
  memberCode?: string;
  isVerification?: boolean;
  profileImage?: string;
  birthDate?: string;
  cellPhone?: string;
  memberJoinStatus?: MEMBER_JOIN_STATUS;

  region?: string;
  subRegion?: string;
  codeRegion?: string;
  codeSubRegion?: string;
  memberOauth?: number;
  salary?: number;
  code_gender?: string;
  marketingMessageReceiverUpdateAt?: string;

  notificationOptions: {
    //* 채널별 알림 설정
    popular_policy_receive_yn?: boolean;
    suggest_policy_receive_yn?: boolean;
    hometown_news_receive_yn?: boolean;
    policy_apply_message_receive_yn?: boolean;
    best_contents_receive_yn?: boolean;
    marketing_message_receive_yn?: boolean;
    community_yn?: boolean;

    //* 댓글 알림 설정
    like_comment_yn?: boolean;
    reply_comment_yn?: boolean;
    report_comment_yn?: boolean;
  };
}

interface AuthStore {
  /** undefined이면 아직 로그인 시도 하지 않은것 */
  isLogin?: boolean;
  setIsLogin: (isLogin?: boolean) => void;

  myInfo?: MyInfo | null;
  setMyInfo: (myInfo: MyInfo | null) => void;

  region: Region[] | null;
  setRegion: (region: Region) => void;

  isDisabledAccessControl?: boolean;
  setIsDisabledAccessControl: (isDisabledAccessControl: boolean) => void;
}

export const useAuthStore = create<AuthStore>((set) => ({
  setIsLogin: (isLogin) => set({ isLogin }),

  setMyInfo: (myInfo) => set({ myInfo }),

  region: null,
  setRegion: (newRegion) =>
    set((state) => {
      const filteredRegions =
        state.region?.filter(
          (region) => region.codeSubRegion !== newRegion.codeSubRegion,
        ) || [];

      const updatedRegions = [newRegion, ...filteredRegions];

      const finalRegions =
        updatedRegions.length > 3 ? updatedRegions.slice(0, 3) : updatedRegions;

      return { region: finalRegions };
    }),

  isDisabledAccessControl: false,
  setIsDisabledAccessControl: (isDisabledAccessControl) =>
    set({ isDisabledAccessControl }),
}));
