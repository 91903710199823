/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultHttpResponse_ListWelcomeMonitorDisplayResponse_ } from '../models/DefaultHttpResponse_ListWelcomeMonitorDisplayResponse_';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AllowsApiService {
    /**
     * 웰컴 모니터 노출 대상 조회
     * @returns DefaultHttpResponse_ListWelcomeMonitorDisplayResponse_ OK
     * @throws ApiError
     */
    public static getWelcomeMonitorDisplayListUsingGet({
        displayPosition,
    }: {
        /**
         * 전시 위치 (example: HEAD7, HEAD8)
         */
        displayPosition: string,
    }): CancelablePromise<DefaultHttpResponse_ListWelcomeMonitorDisplayResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/welcome-monitor',
            query: {
                'displayPosition': displayPosition,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
