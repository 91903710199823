/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultHttpResponse_DuplicateMemberInfoResponse_ } from '../models/DefaultHttpResponse_DuplicateMemberInfoResponse_';
import type { DefaultHttpResponse_KbMemberLoginTypeResponse_ } from '../models/DefaultHttpResponse_KbMemberLoginTypeResponse_';
import type { DefaultHttpResponse_OAuthTokenResponse_ } from '../models/DefaultHttpResponse_OAuthTokenResponse_';
import type { DefaultHttpResponse_TokenStatusCheckResponse_ } from '../models/DefaultHttpResponse_TokenStatusCheckResponse_';
import type { SnsLoginRequest } from '../models/SnsLoginRequest';
import type { TokenStatusCheckRequest } from '../models/TokenStatusCheckRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MemberOauthApiService {
    /**
     * admin Token Refresh
     * @returns DefaultHttpResponse_OAuthTokenResponse_ OK
     * @throws ApiError
     */
    public static adminRefreshTokenUsingGet({
        refreshToken,
    }: {
        /**
         * refresh_token
         */
        refreshToken: string,
    }): CancelablePromise<DefaultHttpResponse_OAuthTokenResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/member-oauth/admin/refresh_token',
            query: {
                'refresh_token': refreshToken,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 before SNS 로그인
     * @returns DefaultHttpResponse_DuplicateMemberInfoResponse_ OK
     * @throws ApiError
     */
    public static welloBeforeLoginUsingPost({
        requestBody,
    }: {
        requestBody?: SnsLoginRequest,
    }): CancelablePromise<DefaultHttpResponse_DuplicateMemberInfoResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/before/sns/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * kb 연동된 로그인 타입 목록 조회
     * @returns DefaultHttpResponse_KbMemberLoginTypeResponse_ OK
     * @throws ApiError
     */
    public static getKbMemberLoginTypeListUsingGet({
        externalMemberId,
    }: {
        /**
         * kb 식별자 ID
         */
        externalMemberId: string,
    }): CancelablePromise<DefaultHttpResponse_KbMemberLoginTypeResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/member-oauth/kb-member-login-type',
            query: {
                'external_member_id': externalMemberId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 sns 로그인
     * @returns DefaultHttpResponse_OAuthTokenResponse_ OK
     * @throws ApiError
     */
    public static loginWelloSnsMemberUsingPost({
        requestBody,
    }: {
        requestBody?: SnsLoginRequest,
    }): CancelablePromise<DefaultHttpResponse_OAuthTokenResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/sns/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 Token Refresh
     * @returns DefaultHttpResponse_OAuthTokenResponse_ OK
     * @throws ApiError
     */
    public static snsRefreshTokenUsingGet({
        refreshToken,
    }: {
        /**
         * refresh_token
         */
        refreshToken: string,
    }): CancelablePromise<DefaultHttpResponse_OAuthTokenResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/member-oauth/sns/refresh_token',
            query: {
                'refresh_token': refreshToken,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * Token 유효성 체크
     * @returns DefaultHttpResponse_TokenStatusCheckResponse_ OK
     * @throws ApiError
     */
    public static tokenStatusCheckUsingPost({
        requestBody,
    }: {
        requestBody?: TokenStatusCheckRequest,
    }): CancelablePromise<DefaultHttpResponse_TokenStatusCheckResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/member-oauth/token-status-check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
