/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckCertificationCodeByTokenRequest } from '../models/CheckCertificationCodeByTokenRequest';
import type { DefaultHttpResponse } from '../models/DefaultHttpResponse';
import type { DefaultHttpResponse_DetailEmergencyRoomLiveResponse_ } from '../models/DefaultHttpResponse_DetailEmergencyRoomLiveResponse_';
import type { DefaultHttpResponse_DetailEmergencyRoomResponse_ } from '../models/DefaultHttpResponse_DetailEmergencyRoomResponse_';
import type { DefaultHttpResponse_DetailEVoucherProviderResponse_ } from '../models/DefaultHttpResponse_DetailEVoucherProviderResponse_';
import type { DefaultHttpResponse_DetailMemberWelloNicknameResponse_ } from '../models/DefaultHttpResponse_DetailMemberWelloNicknameResponse_';
import type { DefaultHttpResponse_DetailPointBudgetResponse_ } from '../models/DefaultHttpResponse_DetailPointBudgetResponse_';
import type { DefaultHttpResponse_DetailPointEarningResponse_ } from '../models/DefaultHttpResponse_DetailPointEarningResponse_';
import type { DefaultHttpResponse_DetailTermResponse_ } from '../models/DefaultHttpResponse_DetailTermResponse_';
import type { DefaultHttpResponse_DetailWelloCommentResponse_ } from '../models/DefaultHttpResponse_DetailWelloCommentResponse_';
import type { DefaultHttpResponse_DetailWelloLandingResponse_ } from '../models/DefaultHttpResponse_DetailWelloLandingResponse_';
import type { DefaultHttpResponse_DeviceInfoMessageReceiveResponse_ } from '../models/DefaultHttpResponse_DeviceInfoMessageReceiveResponse_';
import type { DefaultHttpResponse_EmergencyRoomCountResponse_ } from '../models/DefaultHttpResponse_EmergencyRoomCountResponse_';
import type { DefaultHttpResponse_EVoucherFeedbackResponse_ } from '../models/DefaultHttpResponse_EVoucherFeedbackResponse_';
import type { DefaultHttpResponse_ListBannerResponse_ } from '../models/DefaultHttpResponse_ListBannerResponse_';
import type { DefaultHttpResponse_ListEmergencyRoomCodeResponse_ } from '../models/DefaultHttpResponse_ListEmergencyRoomCodeResponse_';
import type { DefaultHttpResponse_ListEmergencyRoomRegionResponse_ } from '../models/DefaultHttpResponse_ListEmergencyRoomRegionResponse_';
import type { DefaultHttpResponse_ListEmergencyRoomResponse_ } from '../models/DefaultHttpResponse_ListEmergencyRoomResponse_';
import type { DefaultHttpResponse_ListEVoucherProviderCardResponse_ } from '../models/DefaultHttpResponse_ListEVoucherProviderCardResponse_';
import type { DefaultHttpResponse_ListEVoucherProviderRegionResponse_ } from '../models/DefaultHttpResponse_ListEVoucherProviderRegionResponse_';
import type { DefaultHttpResponse_ListEVoucherProviderResponse_ } from '../models/DefaultHttpResponse_ListEVoucherProviderResponse_';
import type { DefaultHttpResponse_ListLiveWelloCommentResponse_ } from '../models/DefaultHttpResponse_ListLiveWelloCommentResponse_';
import type { DefaultHttpResponse_ListLogTermResponse_ } from '../models/DefaultHttpResponse_ListLogTermResponse_';
import type { DefaultHttpResponse_ListNoticeResponse_ } from '../models/DefaultHttpResponse_ListNoticeResponse_';
import type { DefaultHttpResponse_ListPolicyDictionaryResponse_ } from '../models/DefaultHttpResponse_ListPolicyDictionaryResponse_';
import type { DefaultHttpResponse_ListVoucherBannerResponse_ } from '../models/DefaultHttpResponse_ListVoucherBannerResponse_';
import type { DefaultHttpResponse_ListVoucherCardResponse_ } from '../models/DefaultHttpResponse_ListVoucherCardResponse_';
import type { DefaultHttpResponse_ListVoucherCategoryPolicyResponse_ } from '../models/DefaultHttpResponse_ListVoucherCategoryPolicyResponse_';
import type { DefaultHttpResponse_ListVoucherCategoryResponse_ } from '../models/DefaultHttpResponse_ListVoucherCategoryResponse_';
import type { DefaultHttpResponse_ListVoucherOnlineResponse_ } from '../models/DefaultHttpResponse_ListVoucherOnlineResponse_';
import type { DefaultHttpResponse_ListWelloAppPushResponse_ } from '../models/DefaultHttpResponse_ListWelloAppPushResponse_';
import type { DefaultHttpResponse_ListWelloCommentResponse_ } from '../models/DefaultHttpResponse_ListWelloCommentResponse_';
import type { DefaultHttpResponse_ListWelloPickBannerResponse_ } from '../models/DefaultHttpResponse_ListWelloPickBannerResponse_';
import type { DefaultHttpResponse_MetaCodeAllResponse_ } from '../models/DefaultHttpResponse_MetaCodeAllResponse_';
import type { DefaultHttpResponse_NewWelloAppPushCntDto_ } from '../models/DefaultHttpResponse_NewWelloAppPushCntDto_';
import type { DefaultHttpResponse_NoticeDto_ } from '../models/DefaultHttpResponse_NoticeDto_';
import type { DefaultHttpResponse_SituationListResponse_ } from '../models/DefaultHttpResponse_SituationListResponse_';
import type { DefaultHttpResponse_SituationPolicyListResponse_ } from '../models/DefaultHttpResponse_SituationPolicyListResponse_';
import type { DefaultHttpResponse_Void_ } from '../models/DefaultHttpResponse_Void_';
import type { DefaultHttpResponse_WelloPickListResponse_ } from '../models/DefaultHttpResponse_WelloPickListResponse_';
import type { DefaultHttpResponse_WelloPickPolicyListResponse_ } from '../models/DefaultHttpResponse_WelloPickPolicyListResponse_';
import type { MessageReceiveUpdateRequest } from '../models/MessageReceiveUpdateRequest';
import type { SaveLogAppLoginRequest } from '../models/SaveLogAppLoginRequest';
import type { SaveWelloDeviceInfoRequest } from '../models/SaveWelloDeviceInfoRequest';
import type { SaveWelloNonmemberRegionRequest } from '../models/SaveWelloNonmemberRegionRequest';
import type { SendCertificationCodeByTokenRequest } from '../models/SendCertificationCodeByTokenRequest';
import type { SendExtAppPushRequest } from '../models/SendExtAppPushRequest';
import type { UpdateWelloAppPushReadRequest } from '../models/UpdateWelloAppPushReadRequest';
import type { WelloAllowsMessageRejectRequest } from '../models/WelloAllowsMessageRejectRequest';
import type { WelloMembeWithdrawRequest } from '../models/WelloMembeWithdrawRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ApiService {
    /**
     * 회원 탈퇴(관리자용)
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static resignMemberUsingDelete1({
        requestBody,
    }: {
        requestBody?: WelloMembeWithdrawRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/wello/v2/allows/member',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 배너 목록 조회
     * 배너 목록을 조회합니다
     * @returns DefaultHttpResponse_ListBannerResponse_ OK
     * @throws ApiError
     */
    public static bannerListUsingGet({
        contentsTypeCd,
    }: {
        /**
         * 콘텐츠 타입 코드 - (Parent Code) BANNER_CONTENTS_TYPE
         */
        contentsTypeCd: string,
    }): CancelablePromise<DefaultHttpResponse_ListBannerResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/banner',
            query: {
                'contents_type_cd': contentsTypeCd,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 블록리스트 초기화
     * 블록리스트 추가된 경우 초기화 합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static commonCodeInitUsingPost({
        password,
    }: {
        /**
         * password
         */
        password: string,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/blocklist/reset',
            query: {
                'password': password,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 공통코드 초기화
     * 공통코드가 추가된 경우 초기화 합니다
     * @returns DefaultHttpResponse OK
     * @throws ApiError
     */
    public static commonCodeInitUsingPost1({
        password,
    }: {
        /**
         * password
         */
        password: string,
    }): CancelablePromise<DefaultHttpResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/common/meta-code/reset',
            query: {
                'password': password,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 배너 목록 조회
     * @returns DefaultHttpResponse_ListVoucherBannerResponse_ OK
     * @throws ApiError
     */
    public static getVoucherBannerListUsingGet(): CancelablePromise<DefaultHttpResponse_ListVoucherBannerResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/voucher/banner',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 카드사 목록 조회
     * @returns DefaultHttpResponse_ListVoucherCardResponse_ OK
     * @throws ApiError
     */
    public static getEVoucherCardListUsingGet(): CancelablePromise<DefaultHttpResponse_ListVoucherCardResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/voucher/card',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 카테고리 목록 조회
     * @returns DefaultHttpResponse_ListVoucherCategoryResponse_ OK
     * @throws ApiError
     */
    public static getVoucherCategoryListUsingGet({
        level,
        parentId,
    }: {
        /**
         * 카테고리 레벨 1 : 그룹, 2 : 전체
         */
        level?: number,
        /**
         * 상위 그룹 카테고리 아이디
         */
        parentId?: number,
    }): CancelablePromise<DefaultHttpResponse_ListVoucherCategoryResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/voucher/category',
            query: {
                'level': level,
                'parent_id': parentId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 온라인 판매처 카테고리 목록 조회
     * @returns DefaultHttpResponse_ListVoucherCategoryResponse_ OK
     * @throws ApiError
     */
    public static getVoucherOnlineCategoryListUsingGet(): CancelablePromise<DefaultHttpResponse_ListVoucherCategoryResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/voucher/category/online',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 카테고리 상세
     * @returns DefaultHttpResponse_ListVoucherCategoryPolicyResponse_ OK
     * @throws ApiError
     */
    public static getVoucherCategoryPolicyListUsingGet({
        categoryId,
        page,
        size,
    }: {
        /**
         * 바우처 카테고리 아이디
         */
        categoryId: number,
        /**
         * 페이지
         */
        page?: number,
        /**
         * 페이지 사이즈
         */
        size?: number,
    }): CancelablePromise<DefaultHttpResponse_ListVoucherCategoryPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/voucher/category/{category_id}/policy',
            path: {
                'category_id': categoryId,
            },
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 온라인 판매처 목록 조회
     * @returns DefaultHttpResponse_ListVoucherOnlineResponse_ OK
     * @throws ApiError
     */
    public static getVoucherOnlineListUsingGet({
        categoryId,
    }: {
        /**
         * 카테고리 아이디
         */
        categoryId: number,
    }): CancelablePromise<DefaultHttpResponse_ListVoucherOnlineResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/voucher/online',
            query: {
                'category_id': categoryId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 위경도 기반 제공기관 검색
     * @returns DefaultHttpResponse_ListEVoucherProviderResponse_ OK
     * @throws ApiError
     */
    public static getEVoucherProviderListUsingGet1({
        categoryId,
        ip,
        latitude,
        longitude,
        page,
        radius,
        size,
    }: {
        /**
         * 카테고리 아이디
         */
        categoryId: number,
        /**
         * 유저 IP
         */
        ip?: string,
        /**
         * 중심지 위도
         */
        latitude?: number,
        /**
         * 중심지 경도
         */
        longitude?: number,
        /**
         * 페이지
         */
        page?: number,
        /**
         * 반경
         */
        radius?: number,
        /**
         * 사이즈
         */
        size?: number,
    }): CancelablePromise<DefaultHttpResponse_ListEVoucherProviderResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/voucher/provider',
            query: {
                'category_id': categoryId,
                'ip': ip,
                'latitude': latitude,
                'longitude': longitude,
                'page': page,
                'radius': radius,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 위경도 기반 제공기관 전체 검색
     * @returns DefaultHttpResponse_ListEVoucherProviderResponse_ OK
     * @throws ApiError
     */
    public static getEVoucherProviderListAllUsingGet1({
        categoryId,
        page,
        size,
        ip,
        latitude,
        longitude,
    }: {
        /**
         * 카테고리 아이디
         */
        categoryId: number,
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 유저 IP
         */
        ip?: string,
        /**
         * 중심지 위도
         */
        latitude?: number,
        /**
         * 중심지 경도
         */
        longitude?: number,
    }): CancelablePromise<DefaultHttpResponse_ListEVoucherProviderResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/voucher/provider/all',
            query: {
                'category_id': categoryId,
                'ip': ip,
                'latitude': latitude,
                'longitude': longitude,
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 바우처 제공처 지원 카드 검색
     * @returns DefaultHttpResponse_ListEVoucherProviderCardResponse_ OK
     * @throws ApiError
     */
    public static getEVoucherProviderCardListUsingGet({
        categoryId,
        storeTypeCd,
    }: {
        /**
         * 카테고리 아이디
         */
        categoryId: number,
        /**
         * 스토어 타입
         */
        storeTypeCd?: string,
    }): CancelablePromise<DefaultHttpResponse_ListEVoucherProviderCardResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/voucher/provider/card',
            query: {
                'category_id': categoryId,
                'store_type_cd': storeTypeCd,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 제공기관 피드백 조회
     * @returns DefaultHttpResponse_EVoucherFeedbackResponse_ OK
     * @throws ApiError
     */
    public static getEVoucherProviderFeedbackUsingGet1({
        id,
    }: {
        /**
         * 바우처 id
         */
        id: number,
    }): CancelablePromise<DefaultHttpResponse_EVoucherFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/voucher/provider/feedback/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 위경도 기반 제공기관 지역별 갯수 검색
     * @returns DefaultHttpResponse_ListEVoucherProviderRegionResponse_ OK
     * @throws ApiError
     */
    public static getEVoucherProviderRegionListUsingGet({
        categoryId,
    }: {
        /**
         * 카테고리 아이디
         */
        categoryId: number,
    }): CancelablePromise<DefaultHttpResponse_ListEVoucherProviderRegionResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/voucher/provider/region',
            query: {
                'category_id': categoryId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 제공기관 상세 조회
     * @returns DefaultHttpResponse_DetailEVoucherProviderResponse_ OK
     * @throws ApiError
     */
    public static getEVoucherProviderUsingGet1({
        providerUuid,
    }: {
        /**
         * 바우처 UUID(idIdx)
         */
        providerUuid: string,
    }): CancelablePromise<DefaultHttpResponse_DetailEVoucherProviderResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/voucher/provider/{provider_uuid}',
            path: {
                'provider_uuid': providerUuid,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 응급실 목록 조회
     * @returns DefaultHttpResponse_ListEmergencyRoomResponse_ OK
     * @throws ApiError
     */
    public static getEmergencyRoomListUsingGet1({
        emergencyRoomYn,
        latitude,
        longitude,
        page,
        size,
        emergencyRoomCodeList,
        emergencyRoomDayType,
        radius,
        time,
    }: {
        /**
         * 응급실 검색 여부, true - 응급실, false - 일반
         */
        emergencyRoomYn: boolean,
        /**
         * 위도
         */
        latitude: number,
        /**
         * 경도
         */
        longitude: number,
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 증상 코드 목록
         */
        emergencyRoomCodeList?: Array<string>,
        /**
         * 요일 코드 목록
         */
        emergencyRoomDayType?: 'HOLIDAY' | 'WEEKDAY' | 'WEEKEND',
        /**
         * 반경
         */
        radius?: number,
        /**
         * 시간
         */
        time?: number,
    }): CancelablePromise<DefaultHttpResponse_ListEmergencyRoomResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/emergency-room',
            query: {
                'emergencyRoomCodeList': emergencyRoomCodeList,
                'emergencyRoomDayType': emergencyRoomDayType,
                'emergencyRoomYN': emergencyRoomYn,
                'latitude': latitude,
                'longitude': longitude,
                'page': page,
                'radius': radius,
                'size': size,
                'time': time,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 응급실 관련 코드 목록 조회
     * @returns DefaultHttpResponse_ListEmergencyRoomCodeResponse_ OK
     * @throws ApiError
     */
    public static getEmergencyRoomCodeListUsingGet(): CancelablePromise<DefaultHttpResponse_ListEmergencyRoomCodeResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/emergency-room/code',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 응급실 목록 전체 개수 조회
     * @returns DefaultHttpResponse_EmergencyRoomCountResponse_ OK
     * @throws ApiError
     */
    public static getEmergencyRoomListCountUsingGet({
        emergencyRoomYn,
        latitude,
        longitude,
        page,
        size,
        emergencyRoomCodeList,
        emergencyRoomDayType,
        radius,
        time,
    }: {
        /**
         * 응급실 검색 여부, true - 응급실, false - 일반
         */
        emergencyRoomYn: boolean,
        /**
         * 위도
         */
        latitude: number,
        /**
         * 경도
         */
        longitude: number,
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 증상 코드 목록
         */
        emergencyRoomCodeList?: Array<string>,
        /**
         * 요일 코드 목록
         */
        emergencyRoomDayType?: 'HOLIDAY' | 'WEEKDAY' | 'WEEKEND',
        /**
         * 반경
         */
        radius?: number,
        /**
         * 시간
         */
        time?: number,
    }): CancelablePromise<DefaultHttpResponse_EmergencyRoomCountResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/emergency-room/count',
            query: {
                'emergencyRoomCodeList': emergencyRoomCodeList,
                'emergencyRoomDayType': emergencyRoomDayType,
                'emergencyRoomYN': emergencyRoomYn,
                'latitude': latitude,
                'longitude': longitude,
                'page': page,
                'radius': radius,
                'size': size,
                'time': time,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 응급실 지역별 갯수 조회
     * @returns DefaultHttpResponse_ListEmergencyRoomRegionResponse_ OK
     * @throws ApiError
     */
    public static getEmergencyRoomRegionListUsingGet({
        emergencyRoomYn,
        emergencyRoomCodeList,
        emergencyRoomDayType,
        time,
    }: {
        /**
         * 응급실 검색 여부, true - 응급실, false - 일반
         */
        emergencyRoomYn: boolean,
        /**
         * 증상 코드 목록
         */
        emergencyRoomCodeList?: Array<string>,
        /**
         * 요일 코드 목록
         */
        emergencyRoomDayType?: 'HOLIDAY' | 'WEEKDAY' | 'WEEKEND',
        /**
         * 시간
         */
        time?: number,
    }): CancelablePromise<DefaultHttpResponse_ListEmergencyRoomRegionResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/emergency-room/region',
            query: {
                'emergencyRoomCodeList': emergencyRoomCodeList,
                'emergencyRoomDayType': emergencyRoomDayType,
                'emergencyRoomYN': emergencyRoomYn,
                'time': time,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 응급실 상세 조회
     * @returns DefaultHttpResponse_DetailEmergencyRoomResponse_ OK
     * @throws ApiError
     */
    public static getEmergencyRoomUsingGet1({
        emergencyRoomId,
    }: {
        /**
         * emergencyRoomId
         */
        emergencyRoomId: number,
    }): CancelablePromise<DefaultHttpResponse_DetailEmergencyRoomResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/emergency-room/{emergencyRoomId}',
            path: {
                'emergencyRoomId': emergencyRoomId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 응급실 실시간 데이터 조회
     * @returns DefaultHttpResponse_DetailEmergencyRoomLiveResponse_ OK
     * @throws ApiError
     */
    public static getEmergencyRoomLiveUsingGet({
        emergencyRoomId,
    }: {
        /**
         * emergencyRoomId
         */
        emergencyRoomId: number,
    }): CancelablePromise<DefaultHttpResponse_DetailEmergencyRoomLiveResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/emergency-room/{emergencyRoomId}/live',
            path: {
                'emergencyRoomId': emergencyRoomId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 앱 로그인 요청결과 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveLoginResultUsingPost({
        requestBody,
    }: {
        requestBody?: SaveLogAppLoginRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/log-app-login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 약관 변경이력 조회
     * 약관 변경 이력을 조회합니다.
     * @returns DefaultHttpResponse_ListLogTermResponse_ OK
     * @throws ApiError
     */
    public static termHistoryUsingGet({
        termCategory,
    }: {
        /**
         * term_category
         */
        termCategory: string,
    }): CancelablePromise<DefaultHttpResponse_ListLogTermResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/log-term/{term_category}',
            path: {
                'term_category': termCategory,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 신규 닉네임 조회
     * 신규 닉네임을 조회합니다
     * @returns DefaultHttpResponse_DetailMemberWelloNicknameResponse_ OK
     * @throws ApiError
     */
    public static noticeListUsingGet(): CancelablePromise<DefaultHttpResponse_DetailMemberWelloNicknameResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/member-wello/nickname',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 멤버 메세지 수신거부
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static rejectMessageUsingPut1({
        requestBody,
    }: {
        requestBody?: WelloAllowsMessageRejectRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/allows/message-reject/member-wello',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 메타코드 정보 조회
     * 메타코드 및 메타코드의 하위 코드까지 조회합니다
     * @returns DefaultHttpResponse_MetaCodeAllResponse_ OK
     * @throws ApiError
     */
    public static metaCodeAllUsingGet({
        metaCode,
        step,
    }: {
        /**
         * 메타코드
         */
        metaCode: string,
        /**
         * 스탭
         */
        step?: number,
    }): CancelablePromise<DefaultHttpResponse_MetaCodeAllResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/meta-code',
            query: {
                'meta_code': metaCode,
                'step': step,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 공통코드 초기화
     * 공통코드가 추가된 경우 초기화 합니다
     * @returns DefaultHttpResponse OK
     * @throws ApiError
     */
    public static commonCodeInitUsingPost2({
        password,
    }: {
        /**
         * password
         */
        password: string,
    }): CancelablePromise<DefaultHttpResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/meta-code/reset',
            query: {
                'password': password,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 공지사항 조회
     * 공지사항을 조회합니다
     * @returns DefaultHttpResponse_ListNoticeResponse_ OK
     * @throws ApiError
     */
    public static noticeListUsingGet1(): CancelablePromise<DefaultHttpResponse_ListNoticeResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/cs/notice',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 공지사항 조회
     * 공지사항을 조회합니다
     * @returns DefaultHttpResponse_NoticeDto_ OK
     * @throws ApiError
     */
    public static noticeUsingGet({
        noticeId,
    }: {
        /**
         * 공지사항 번호
         */
        noticeId: number,
    }): CancelablePromise<DefaultHttpResponse_NoticeDto_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/cs/notice/{notice_id}',
            path: {
                'notice_id': noticeId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 포인트 정보 조회
     * 포인트 잔액, 출금 가능 포인트를 조회합니다.
     * @returns DefaultHttpResponse_DetailPointBudgetResponse_ OK
     * @throws ApiError
     */
    public static getPointBudgetUsingGet1(): CancelablePromise<DefaultHttpResponse_DetailPointBudgetResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/point/budget',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 포인트 획득 조회
     * 유저 액션 별 획득 가능 포인트를 조회합니다.
     * @returns DefaultHttpResponse_DetailPointEarningResponse_ OK
     * @throws ApiError
     */
    public static getPointEarningUsingGet(): CancelablePromise<DefaultHttpResponse_DetailPointEarningResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/point/earning',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 사전 목록 조회
     * @returns DefaultHttpResponse_ListPolicyDictionaryResponse_ OK
     * @throws ApiError
     */
    public static getPolicyDictionaryListUsingGet(): CancelablePromise<DefaultHttpResponse_ListPolicyDictionaryResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/policy-dictionary',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 꾸러미 목록 조회
     * 정책 꾸러미 목록을 조회합니다
     * @returns DefaultHttpResponse_SituationListResponse_ OK
     * @throws ApiError
     */
    public static situationListUsingGet1(): CancelablePromise<DefaultHttpResponse_SituationListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/contents/situation',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 꾸러미 정책 목록 조회
     * 정책 꾸러미 정책 목록을 조회합니다
     * @returns DefaultHttpResponse_SituationPolicyListResponse_ OK
     * @throws ApiError
     */
    public static getSituationPolicyListUsingGet1({
        situationId,
    }: {
        /**
         * 정책 꾸러미 번호
         */
        situationId: number,
    }): CancelablePromise<DefaultHttpResponse_SituationPolicyListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/contents/situation/{situation_id}',
            path: {
                'situation_id': situationId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 약관 조회
     * 약관을 조회합니다.
     * @returns DefaultHttpResponse_DetailTermResponse_ OK
     * @throws ApiError
     */
    public static termUsingGet({
        termCategory,
    }: {
        /**
         * term_category
         */
        termCategory: string,
    }): CancelablePromise<DefaultHttpResponse_DetailTermResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/term/{term_category}',
            path: {
                'term_category': termCategory,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 앱 푸시 목록 조회
     * @returns DefaultHttpResponse_ListWelloAppPushResponse_ OK
     * @throws ApiError
     */
    public static welloAppPushListUsingGet({
        fcmToken,
        page,
        size,
        welloAppPushCategoryId,
        welloAppPushParentCategoryTypeCd,
    }: {
        /**
         * 디바이스 토큰
         */
        fcmToken: string,
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈(10~)
         */
        size: number,
        /**
         * 앱 푸시 카테고리 ID
         */
        welloAppPushCategoryId?: number,
        /**
         * 앱 푸시 상위 카테고리 코드
         */
        welloAppPushParentCategoryTypeCd?: string,
    }): CancelablePromise<DefaultHttpResponse_ListWelloAppPushResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-app-push',
            query: {
                'fcm_token': fcmToken,
                'page': page,
                'size': size,
                'wello_app_push_category_id': welloAppPushCategoryId,
                'wello_app_push_parent_category_type_cd': welloAppPushParentCategoryTypeCd,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 앱 푸시 읽음 처리
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateWelloAppPushReadUsingPut1({
        requestBody,
    }: {
        requestBody?: UpdateWelloAppPushReadRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/allows/wello-app-push',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 앱 푸시 알림 건수 조회
     * @returns DefaultHttpResponse_NewWelloAppPushCntDto_ OK
     * @throws ApiError
     */
    public static newWelloAppPushCntUsingGet({
        fcmToken,
    }: {
        /**
         * fcm_token
         */
        fcmToken: string,
    }): CancelablePromise<DefaultHttpResponse_NewWelloAppPushCntDto_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-app-push/count',
            query: {
                'fcm_token': fcmToken,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 앱 푸시(외부) 요청
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static sendExtAppPushUsingPost({
        requestBody,
    }: {
        requestBody?: SendExtAppPushRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/wello-app-push/ext',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 앱 푸시 트래킹&읽음 처리
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateWelloAppPushReadUsingPost({
        welloAppPushReceiverId,
    }: {
        /**
         * wello_app_push_receiver_id
         */
        welloAppPushReceiverId: number,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/wello-app-push/{wello_app_push_receiver_id}',
            path: {
                'wello_app_push_receiver_id': welloAppPushReceiverId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 댓글 목록 조회
     * @returns DefaultHttpResponse_ListWelloCommentResponse_ OK
     * @throws ApiError
     */
    public static getMetaPolicyCommentListUsingGet2({
        commentTypeCd,
        contentId,
        page,
        size,
        commentSort,
        parentCommentId,
    }: {
        /**
         * 댓글 타입 cd - (Parent Code) WELLO_COMMENT_TYPE
         */
        commentTypeCd: string,
        /**
         * 컨텐츠 ID - policy_id, best_contents_id, hometown_news_id
         */
        contentId: number,
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 정렬순서 - POPULARITY, ENROLL, LATEST
         */
        commentSort?: 'ENROLL' | 'LATEST' | 'POPULARITY',
        /**
         * 부모 댓글 아이디 - 댓글의 답글 조회용
         */
        parentCommentId?: number,
    }): CancelablePromise<DefaultHttpResponse_ListWelloCommentResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-comment',
            query: {
                'comment_sort': commentSort,
                'comment_type_cd': commentTypeCd,
                'content_id': contentId,
                'page': page,
                'parent_comment_id': parentCommentId,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * @deprecated
     * 웰로 실시간 정책 의견
     * @returns DefaultHttpResponse_ListLiveWelloCommentResponse_ OK
     * @throws ApiError
     */
    public static getLiveWelloCommentListUsingGet1({
        commentTypeCd,
        size,
    }: {
        /**
         * 댓글 타입 cd - (Parent Code) WELLO_COMMENT_TYPE
         */
        commentTypeCd?: string,
        /**
         * 사이즈 (default 3)
         */
        size?: number,
    }): CancelablePromise<DefaultHttpResponse_ListLiveWelloCommentResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-comment/live',
            query: {
                'comment_type_cd': commentTypeCd,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 댓글 상세 조회
     * @returns DefaultHttpResponse_DetailWelloCommentResponse_ OK
     * @throws ApiError
     */
    public static getMetaPolicyCommentListUsingGet1({
        welloCommentId,
    }: {
        /**
         * 댓글 ID
         */
        welloCommentId: number,
    }): CancelablePromise<DefaultHttpResponse_DetailWelloCommentResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-comment/{welloCommentId}',
            path: {
                'welloCommentId': welloCommentId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 디바이스 정보 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveAllowsWelloDeviceInfoUsingPost({
        requestBody,
    }: {
        requestBody?: SaveWelloDeviceInfoRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/wello-device-info',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 알림수신여부 조회
     * 알림수신 여부를 조회합니다
     * @returns DefaultHttpResponse_DeviceInfoMessageReceiveResponse_ OK
     * @throws ApiError
     */
    public static messageReceiveUsingGet({
        fcmToken,
        os,
    }: {
        /**
         * 디바이스 토큰
         */
        fcmToken: string,
        /**
         * 디바이스 OS
         */
        os: string,
    }): CancelablePromise<DefaultHttpResponse_DeviceInfoMessageReceiveResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-device-info/message-receive',
            query: {
                'fcm_token': fcmToken,
                'os': os,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 알림수신여부 수정
     * 알림수신 여부를 수정합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateMessageReceiveUsingPut({
        requestBody,
    }: {
        requestBody?: MessageReceiveUpdateRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/allows/wello-device-info/message-receive',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 랜딩페이지 데이터 조회
     * @returns DefaultHttpResponse_DetailWelloLandingResponse_ OK
     * @throws ApiError
     */
    public static detailWelloLandingUsingGet(): CancelablePromise<DefaultHttpResponse_DetailWelloLandingResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/wello-landing',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 휴대전화번호 인증 발송
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static sendCertificationCellphoneUsingPost1({
        requestBody,
    }: {
        requestBody?: SendCertificationCodeByTokenRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/certification/cellphone',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 인증 번호 확인
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static certificationCheckUsingPost1({
        requestBody,
    }: {
        requestBody?: CheckCertificationCodeByTokenRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/certification/check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 비회원 대상지역 정보 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveWelloNonmemberRegionUsingPost({
        requestBody,
    }: {
        requestBody?: SaveWelloNonmemberRegionRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/allows/wello-nonmember-region',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 대상별 정책 - 대상 조회
     * 웰로 대상별 정책 - 대상 정보를 조회합니다
     * @returns DefaultHttpResponse_WelloPickListResponse_ OK
     * @throws ApiError
     */
    public static welloPickListUsingGet1(): CancelablePromise<DefaultHttpResponse_WelloPickListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/contents/pick',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 대상별 정책 조회
     * 웰로 대상별 정책 정보를 조회합니다
     * @returns DefaultHttpResponse_WelloPickPolicyListResponse_ OK
     * @throws ApiError
     */
    public static welloPickPolicyListUsingGet1({
        welloPickId,
    }: {
        /**
         * 웰로 대상별 정책 - 대상 번호
         */
        welloPickId: number,
    }): CancelablePromise<DefaultHttpResponse_WelloPickPolicyListResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/contents/pick/{wello_pick_id}',
            path: {
                'wello_pick_id': welloPickId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로픽! 요즘 뜨는 정책 목록 조회
     * 웰로픽 - 메인 정책 큐레이션 목록을 조회합니다
     * @returns DefaultHttpResponse_ListWelloPickBannerResponse_ OK
     * @throws ApiError
     */
    public static getWelloPickBannerListUsingGet(): CancelablePromise<DefaultHttpResponse_ListWelloPickBannerResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/allows/pick',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
