import { CustomError, type ServerResponse } from '@common/utils';

import { jejuApi } from './jejuApi';

interface cancelConsultProps {
  id: number;
}

export const postJejuCancelConsult = async ({ id }: cancelConsultProps) => {
  try {
    const { result_code } = await jejuApi
      .post('/api103', {
        json: {
          id,
        },
      })
      .json<ServerResponse>();

    return result_code;
  } catch (error) {
    throw new CustomError();
  }
};
