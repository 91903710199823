/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BenefitNotificationApplicationInfoSaveRequest } from '../models/BenefitNotificationApplicationInfoSaveRequest';
import type { ChangeCommunityFreeTalkRequest } from '../models/ChangeCommunityFreeTalkRequest';
import type { ChangeCommunityPolicyTalkRequest } from '../models/ChangeCommunityPolicyTalkRequest';
import type { CreateCommunityFreeTalkRequest } from '../models/CreateCommunityFreeTalkRequest';
import type { CreateCommunityPolicyTalkRequest } from '../models/CreateCommunityPolicyTalkRequest';
import type { CreateCommunityReportRequest } from '../models/CreateCommunityReportRequest';
import type { DecryptMemberVerificationRequest } from '../models/DecryptMemberVerificationRequest';
import type { DefaultHttpResponse_BenefitNotificationFitAnalysisResultResponse_ } from '../models/DefaultHttpResponse_BenefitNotificationFitAnalysisResultResponse_';
import type { DefaultHttpResponse_CommunityFreeTalkFeedbackResponse_ } from '../models/DefaultHttpResponse_CommunityFreeTalkFeedbackResponse_';
import type { DefaultHttpResponse_CommunityPolicyTalkFeedbackResponse_ } from '../models/DefaultHttpResponse_CommunityPolicyTalkFeedbackResponse_';
import type { DefaultHttpResponse_CommunityWelloNewsFeedbackResponse_ } from '../models/DefaultHttpResponse_CommunityWelloNewsFeedbackResponse_';
import type { DefaultHttpResponse_CreateCommunityFreeTalkResponse_ } from '../models/DefaultHttpResponse_CreateCommunityFreeTalkResponse_';
import type { DefaultHttpResponse_CreateCommunityPolicyTalkResponse_ } from '../models/DefaultHttpResponse_CreateCommunityPolicyTalkResponse_';
import type { DefaultHttpResponse_DecryptMemberVerificationResponse_ } from '../models/DefaultHttpResponse_DecryptMemberVerificationResponse_';
import type { DefaultHttpResponse_DetailBenefitNotificationFitAnalysisResponse_ } from '../models/DefaultHttpResponse_DetailBenefitNotificationFitAnalysisResponse_';
import type { DefaultHttpResponse_DetailBenefitNotificationMainInfoResponse_ } from '../models/DefaultHttpResponse_DetailBenefitNotificationMainInfoResponse_';
import type { DefaultHttpResponse_DetailBenefitNotificationPublicServiceDetailUrlResponse_ } from '../models/DefaultHttpResponse_DetailBenefitNotificationPublicServiceDetailUrlResponse_';
import type { DefaultHttpResponse_DetailBenefitNotificationServiceUsageAgreementUrlResponse_ } from '../models/DefaultHttpResponse_DetailBenefitNotificationServiceUsageAgreementUrlResponse_';
import type { DefaultHttpResponse_DetailBestContentsResponse_ } from '../models/DefaultHttpResponse_DetailBestContentsResponse_';
import type { DefaultHttpResponse_DetailCommunityFreeTalkResponse_ } from '../models/DefaultHttpResponse_DetailCommunityFreeTalkResponse_';
import type { DefaultHttpResponse_DetailCommunityPolicyTalkResponse_ } from '../models/DefaultHttpResponse_DetailCommunityPolicyTalkResponse_';
import type { DefaultHttpResponse_DetailCommunityWelloNewsResponse_ } from '../models/DefaultHttpResponse_DetailCommunityWelloNewsResponse_';
import type { DefaultHttpResponse_DetailHometownNewsResponse_ } from '../models/DefaultHttpResponse_DetailHometownNewsResponse_';
import type { DefaultHttpResponse_DetailInquiryResponse_ } from '../models/DefaultHttpResponse_DetailInquiryResponse_';
import type { DefaultHttpResponse_DetailMemberAccountResponse_ } from '../models/DefaultHttpResponse_DetailMemberAccountResponse_';
import type { DefaultHttpResponse_DetailMemberVerificationCiResponse_ } from '../models/DefaultHttpResponse_DetailMemberVerificationCiResponse_';
import type { DefaultHttpResponse_DetailMemberVerificationInfoResponse_ } from '../models/DefaultHttpResponse_DetailMemberVerificationInfoResponse_';
import type { DefaultHttpResponse_DetailMemberVerificationResponse_ } from '../models/DefaultHttpResponse_DetailMemberVerificationResponse_';
import type { DefaultHttpResponse_DetailMetaPolicyResponse_ } from '../models/DefaultHttpResponse_DetailMetaPolicyResponse_';
import type { DefaultHttpResponse_ListBestContentsResponse_ } from '../models/DefaultHttpResponse_ListBestContentsResponse_';
import type { DefaultHttpResponse_ListChildcareRelatedPolicyResponse_ } from '../models/DefaultHttpResponse_ListChildcareRelatedPolicyResponse_';
import type { DefaultHttpResponse_ListCommunityFreeTalkResponse_ } from '../models/DefaultHttpResponse_ListCommunityFreeTalkResponse_';
import type { DefaultHttpResponse_ListCommunityPolicyTalkResponse_ } from '../models/DefaultHttpResponse_ListCommunityPolicyTalkResponse_';
import type { DefaultHttpResponse_ListCommunityTalkResponse_ } from '../models/DefaultHttpResponse_ListCommunityTalkResponse_';
import type { DefaultHttpResponse_ListCommunityWelloNewsResponse_ } from '../models/DefaultHttpResponse_ListCommunityWelloNewsResponse_';
import type { DefaultHttpResponse_ListHometownNewsResponse_ } from '../models/DefaultHttpResponse_ListHometownNewsResponse_';
import type { DefaultHttpResponse_ListInquiryResponse_ } from '../models/DefaultHttpResponse_ListInquiryResponse_';
import type { DefaultHttpResponse_ListLatestCommunityResponse_ } from '../models/DefaultHttpResponse_ListLatestCommunityResponse_';
import type { DefaultHttpResponse_ListMemberAccountResponse_ } from '../models/DefaultHttpResponse_ListMemberAccountResponse_';
import type { DefaultHttpResponse_ListMemberCardResponse_ } from '../models/DefaultHttpResponse_ListMemberCardResponse_';
import type { DefaultHttpResponse_ListMetaPolicyResponse_ } from '../models/DefaultHttpResponse_ListMetaPolicyResponse_';
import type { DefaultHttpResponse_ListMyCommunityResponse_ } from '../models/DefaultHttpResponse_ListMyCommunityResponse_';
import type { DefaultHttpResponse_ListMyCommunityTypeResponse_ } from '../models/DefaultHttpResponse_ListMyCommunityTypeResponse_';
import type { DefaultHttpResponse_ListNewBestContentsResponse_ } from '../models/DefaultHttpResponse_ListNewBestContentsResponse_';
import type { DefaultHttpResponse_ListPaymentHisResponse_ } from '../models/DefaultHttpResponse_ListPaymentHisResponse_';
import type { DefaultHttpResponse_ListPolicyResponse_ } from '../models/DefaultHttpResponse_ListPolicyResponse_';
import type { DefaultHttpResponse_ListPopularBestContentsResponse_ } from '../models/DefaultHttpResponse_ListPopularBestContentsResponse_';
import type { DefaultHttpResponse_ListPopularCommunityFreeTalkResponse_ } from '../models/DefaultHttpResponse_ListPopularCommunityFreeTalkResponse_';
import type { DefaultHttpResponse_ListPopularCommunityPolicyTalkResponse_ } from '../models/DefaultHttpResponse_ListPopularCommunityPolicyTalkResponse_';
import type { DefaultHttpResponse_ListPopularHometownNewsResponse_ } from '../models/DefaultHttpResponse_ListPopularHometownNewsResponse_';
import type { DefaultHttpResponse_ListRecommendPolicyProvisionTypeResponse_ } from '../models/DefaultHttpResponse_ListRecommendPolicyProvisionTypeResponse_';
import type { DefaultHttpResponse_ListRecommendPolicyResponse_ } from '../models/DefaultHttpResponse_ListRecommendPolicyResponse_';
import type { DefaultHttpResponse_ListRelatedMetaPolicyResponse_ } from '../models/DefaultHttpResponse_ListRelatedMetaPolicyResponse_';
import type { DefaultHttpResponse_ListWelloPolicyApplyResponse_ } from '../models/DefaultHttpResponse_ListWelloPolicyApplyResponse_';
import type { DefaultHttpResponse_ReverseGeocodingResponse_ } from '../models/DefaultHttpResponse_ReverseGeocodingResponse_';
import type { DefaultHttpResponse_TotalPaymentHisResponse_ } from '../models/DefaultHttpResponse_TotalPaymentHisResponse_';
import type { DefaultHttpResponse_Void_ } from '../models/DefaultHttpResponse_Void_';
import type { DefaultHttpResponse_WelloFeedbackResponse_ } from '../models/DefaultHttpResponse_WelloFeedbackResponse_';
import type { DefaultHttpResponse_WelloPolicyApplyCountResponse_ } from '../models/DefaultHttpResponse_WelloPolicyApplyCountResponse_';
import type { InquiryRequest } from '../models/InquiryRequest';
import type { SaveReportRequest } from '../models/SaveReportRequest';
import type { SaveWelloEventParticipationRequest } from '../models/SaveWelloEventParticipationRequest';
import type { SaveWelloTrackRequest } from '../models/SaveWelloTrackRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class Service {
    /**
     * 혜택 알리미 - 신청 정보 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveBenefitNotificationApplicationInfoUsingPost({
        requestBody,
    }: {
        requestBody?: BenefitNotificationApplicationInfoSaveRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/benefit-notification/application-info',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 혜택 알리미 - 신청 결과 목록 화면 URL 조회
     * @returns DefaultHttpResponse_DetailBenefitNotificationServiceUsageAgreementUrlResponse_ OK
     * @throws ApiError
     */
    public static getBenefitNotificationApplicationResultsUrlUsingGet({
        callbackUrl,
    }: {
        /**
         * 콜백 URL
         */
        callbackUrl: string,
    }): CancelablePromise<DefaultHttpResponse_DetailBenefitNotificationServiceUsageAgreementUrlResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/benefit-notification/application-results-url',
            query: {
                'callbackUrl': callbackUrl,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 혜택 알리미 - 맞춤 분석 요청
     * @returns DefaultHttpResponse_DetailBenefitNotificationFitAnalysisResponse_ OK
     * @throws ApiError
     */
    public static getBenefitNotificationFitAnalysisUsingGet(): CancelablePromise<DefaultHttpResponse_DetailBenefitNotificationFitAnalysisResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/benefit-notification/fit-analysis',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 혜택 알리미 - 맞춤 맞춤분석 요청 결과 조회
     * @returns DefaultHttpResponse_BenefitNotificationFitAnalysisResultResponse_ OK
     * @throws ApiError
     */
    public static getBenefitNotificationFitAnalysisResultUsingGet({
        fixesGuidanceRequstSeq,
    }: {
        /**
         * 맞춤 안내 요청 일렬번호
         */
        fixesGuidanceRequstSeq: string,
    }): CancelablePromise<DefaultHttpResponse_BenefitNotificationFitAnalysisResultResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/benefit-notification/fit-analysis-result',
            query: {
                'fixesGuidanceRequstSeq': fixesGuidanceRequstSeq,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 혜택 알리미 - 혜택알리미 메인 정보 조회
     * @returns DefaultHttpResponse_DetailBenefitNotificationMainInfoResponse_ OK
     * @throws ApiError
     */
    public static getBenefitNotificationMainInfoUsingGet(): CancelablePromise<DefaultHttpResponse_DetailBenefitNotificationMainInfoResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/benefit-notification/main-info',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 혜택 알리미 - 공공서비스 상세 화면 URL 조회
     * @returns DefaultHttpResponse_DetailBenefitNotificationPublicServiceDetailUrlResponse_ OK
     * @throws ApiError
     */
    public static getBenefitNotificationPublicServiceDetailUrlUsingGet({
        callbackUrl,
        reqstInfoSavUrl,
        reqstUrlCallback,
        svcSeq,
    }: {
        /**
         * 콜백 URL
         */
        callbackUrl: string,
        /**
         * 신청정보저장 URL
         */
        reqstInfoSavUrl: string,
        /**
         * 신청서 URL 콜백
         */
        reqstUrlCallback: string,
        /**
         * 서비스 일련번호
         */
        svcSeq: string,
    }): CancelablePromise<DefaultHttpResponse_DetailBenefitNotificationPublicServiceDetailUrlResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/benefit-notification/public-service-detail-url',
            query: {
                'callbackUrl': callbackUrl,
                'reqstInfoSavUrl': reqstInfoSavUrl,
                'reqstUrlCallback': reqstUrlCallback,
                'svcSeq': svcSeq,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 혜택 알리미 - 서비스 이용 동의 화면 URL 조회
     * @returns DefaultHttpResponse_DetailBenefitNotificationServiceUsageAgreementUrlResponse_ OK
     * @throws ApiError
     */
    public static getBenefitNotificationServiceUsageAgreementUrlUsingGet({
        callbackUrl,
        completeUrl,
    }: {
        /**
         * 콜백 URL(혜택 알리미 내에서 뒤로가기, 닫기 이동 url)
         */
        callbackUrl: string,
        /**
         * 완료 후 URL
         */
        completeUrl: string,
    }): CancelablePromise<DefaultHttpResponse_DetailBenefitNotificationServiceUsageAgreementUrlResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/benefit-notification/service-usage-agreement-url',
            query: {
                'callbackUrl': callbackUrl,
                'completeUrl': completeUrl,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 소식 목록 조회
     * 정책 소식 목록을 조회합니다
     * @returns DefaultHttpResponse_ListBestContentsResponse_ OK
     * @throws ApiError
     */
    public static bestContentsListUsingGet({
        page,
        size,
        bestContentsSort,
        codeProvisionType,
        endAt,
        searchKeyword,
        startAt,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 정렬 순서(POPULARITY, LATEST)
         */
        bestContentsSort?: 'LATEST' | 'POPULARITY',
        /**
         * 지원유형[C09-XX]
         */
        codeProvisionType?: string,
        /**
         * 정책 종료 일시
         */
        endAt?: string,
        /**
         * 검색어
         */
        searchKeyword?: string,
        /**
         * 정책 시작 일시
         */
        startAt?: string,
    }): CancelablePromise<DefaultHttpResponse_ListBestContentsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/contents/best',
            query: {
                'best_contents_sort': bestContentsSort,
                'code_provision_type': codeProvisionType,
                'end_at': endAt,
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
                'start_at': startAt,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 신규 정책 소식 목록 조회
     * @returns DefaultHttpResponse_ListNewBestContentsResponse_ OK
     * @throws ApiError
     */
    public static getNewBestContentsListUsingGet(): CancelablePromise<DefaultHttpResponse_ListNewBestContentsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/contents/best/new',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 인기 정책 소식 목록 조회
     * @returns DefaultHttpResponse_ListPopularBestContentsResponse_ OK
     * @throws ApiError
     */
    public static getPopularBestContentsListUsingGet(): CancelablePromise<DefaultHttpResponse_ListPopularBestContentsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/contents/best/popular',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 소식 상세 조회
     * 정책 소식 상세 정보를 조회합니다
     * @returns DefaultHttpResponse_DetailBestContentsResponse_ OK
     * @throws ApiError
     */
    public static bestContentsUsingGet({
        bestContentsIdIdx,
    }: {
        /**
         * 정책 소식 id idx
         */
        bestContentsIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailBestContentsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/contents/best/{best_contents_id_idx}',
            path: {
                'best_contents_id_idx': bestContentsIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 로그인 멤버 정책 소식 좋아요&스크랩 정보 조회
     * @returns DefaultHttpResponse_WelloFeedbackResponse_ OK
     * @throws ApiError
     */
    public static bestContentsFeedbackUsingGet({
        bestContentsId,
    }: {
        /**
         * 정책 소식 ID
         */
        bestContentsId: number,
    }): CancelablePromise<DefaultHttpResponse_WelloFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/contents/feedback/{best_contents_id}',
            path: {
                'best_contents_id': bestContentsId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 내 상황에 맞는 육아 정책 관련 정책 조회
     * @returns DefaultHttpResponse_ListChildcareRelatedPolicyResponse_ OK
     * @throws ApiError
     */
    public static getListChildcareRelatedPolicyUsingGet({
        subRegionCode,
    }: {
        /**
         * 지역 상세 코드
         */
        subRegionCode: string,
    }): CancelablePromise<DefaultHttpResponse_ListChildcareRelatedPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy-case/baby/related-policy',
            query: {
                'sub_region_code': subRegionCode,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 최신글
     * @returns DefaultHttpResponse_ListLatestCommunityResponse_ OK
     * @throws ApiError
     */
    public static getLatestCommunityListUsingGet(): CancelablePromise<DefaultHttpResponse_ListLatestCommunityResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/community/latest',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 내가 작성한 커뮤니티 글
     * @returns DefaultHttpResponse_ListMyCommunityResponse_ OK
     * @throws ApiError
     */
    public static getMyCommunityListUsingGet({
        communityType,
        page,
        size,
        communitySort,
    }: {
        /**
         * 카테고리 아이디
         */
        communityType: string,
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 정렬순서 - POPULARITY, LATEST
         */
        communitySort?: 'LATEST' | 'POPULARITY',
    }): CancelablePromise<DefaultHttpResponse_ListMyCommunityResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/community/my',
            query: {
                'community_sort': communitySort,
                'community_type': communityType,
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 내가 작성한 커뮤니티 글 유형
     * @returns DefaultHttpResponse_ListMyCommunityTypeResponse_ OK
     * @throws ApiError
     */
    public static getMyCommunityTypeListUsingGet(): CancelablePromise<DefaultHttpResponse_ListMyCommunityTypeResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/community/my/type',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 자유 수다 목록 조회
     * @returns DefaultHttpResponse_ListCommunityFreeTalkResponse_ OK
     * @throws ApiError
     */
    public static getCommunityFreeTalkListUsingGet({
        page,
        size,
        searchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 검색어
         */
        searchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListCommunityFreeTalkResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/community/free-talk',
            query: {
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 자유 수다 등록
     * @returns DefaultHttpResponse_CreateCommunityFreeTalkResponse_ OK
     * @throws ApiError
     */
    public static createCommunityFreeTalkUsingPost({
        requestBody,
    }: {
        requestBody?: CreateCommunityFreeTalkRequest,
    }): CancelablePromise<DefaultHttpResponse_CreateCommunityFreeTalkResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/community/free-talk',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 자유 수다 피드백 (조회수, 좋아요 수, 댓글 수, 좋아요 확인 보정)
     * @returns DefaultHttpResponse_CommunityFreeTalkFeedbackResponse_ OK
     * @throws ApiError
     */
    public static feedbackCommunityFreeTalkUsingGet({
        communityIdIdx,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_CommunityFreeTalkFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/community/free-talk/feedback/{community_id_idx}',
            path: {
                'community_id_idx': communityIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - HOT한 수다 - 자유수다
     * @returns DefaultHttpResponse_ListCommunityTalkResponse_ OK
     * @throws ApiError
     */
    public static getCommunityFreeTalkHotListUsingGet({
        page,
        size,
        searchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 검색어
         */
        searchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListCommunityTalkResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/community/free-talk/hot',
            query: {
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 인기 자유수다
     * @returns DefaultHttpResponse_ListPopularCommunityFreeTalkResponse_ OK
     * @throws ApiError
     */
    public static getPopularCommunityFreeTalkListUsingGet(): CancelablePromise<DefaultHttpResponse_ListPopularCommunityFreeTalkResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/community/free-talk/popular',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 자유 수다 상세
     * @returns DefaultHttpResponse_DetailCommunityFreeTalkResponse_ OK
     * @throws ApiError
     */
    public static getCommunityFreeTalkUsingGet({
        communityIdIdx,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailCommunityFreeTalkResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/community/free-talk/{community_id_idx}',
            path: {
                'community_id_idx': communityIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 자유 수다 수정
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateCommunityFreeTalkUsingPut({
        communityIdIdx,
        requestBody,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
        requestBody?: ChangeCommunityFreeTalkRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/community/free-talk/{community_id_idx}',
            path: {
                'community_id_idx': communityIdIdx,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 자유 수다 삭제
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static deleteCommunityFreeTalkUsingPut({
        communityIdIdx,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/community/free-talk/{community_id_idx}/delete',
            path: {
                'community_id_idx': communityIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 정책 수다 목록 조회
     * @returns DefaultHttpResponse_ListCommunityPolicyTalkResponse_ OK
     * @throws ApiError
     */
    public static getCommunityPolicyTalkListUsingGet({
        page,
        size,
        code,
        communityCategoryId,
        searchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 코드
         */
        code?: string,
        /**
         * 커뮤니티 카테고리 아이디
         */
        communityCategoryId?: number,
        /**
         * 검색어
         */
        searchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListCommunityPolicyTalkResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/community/policy-talk',
            query: {
                'code': code,
                'community_category_id': communityCategoryId,
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 정책 수다 등록
     * @returns DefaultHttpResponse_CreateCommunityPolicyTalkResponse_ OK
     * @throws ApiError
     */
    public static createCommunityPolicyTalkUsingPost({
        requestBody,
    }: {
        requestBody?: CreateCommunityPolicyTalkRequest,
    }): CancelablePromise<DefaultHttpResponse_CreateCommunityPolicyTalkResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/community/policy-talk',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 정책 수다 피드백 (조회수, 좋아요 수, 댓글 수, 좋아요 확인 보정)
     * @returns DefaultHttpResponse_CommunityPolicyTalkFeedbackResponse_ OK
     * @throws ApiError
     */
    public static feedbackCommunityPolicyTalkUsingGet({
        communityIdIdx,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_CommunityPolicyTalkFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/community/policy-talk/feedback/{community_id_idx}',
            path: {
                'community_id_idx': communityIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - HOT한 수다 - 정책수다
     * @returns DefaultHttpResponse_ListCommunityTalkResponse_ OK
     * @throws ApiError
     */
    public static getCommunityPolicyTalkHotListUsingGet({
        page,
        size,
        searchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 검색어
         */
        searchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListCommunityTalkResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/community/policy-talk/hot',
            query: {
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 인기 정책수다
     * @returns DefaultHttpResponse_ListPopularCommunityPolicyTalkResponse_ OK
     * @throws ApiError
     */
    public static getPopularCommunityPolicyTalkListUsingGet(): CancelablePromise<DefaultHttpResponse_ListPopularCommunityPolicyTalkResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/community/policy-talk/popular',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 정책 수다 상세
     * @returns DefaultHttpResponse_DetailCommunityPolicyTalkResponse_ OK
     * @throws ApiError
     */
    public static getCommunityPolicyTalkUsingGet({
        communityIdIdx,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailCommunityPolicyTalkResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/community/policy-talk/{community_id_idx}',
            path: {
                'community_id_idx': communityIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 정책 수다 수정
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateCommunityPolicyTalkUsingPut({
        communityIdIdx,
        requestBody,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
        requestBody?: ChangeCommunityPolicyTalkRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/community/policy-talk/{community_id_idx}',
            path: {
                'community_id_idx': communityIdIdx,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 정책 수다 댓글 채택 등록
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveCommentPickUsingPost({
        communityIdIdx,
        commentId,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
        /**
         * 댓글 ID
         */
        commentId: number,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/community/policy-talk/{community_id_idx}/comment/{commentId}/comment-pick',
            path: {
                'community_id_idx': communityIdIdx,
                'commentId': commentId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 정책 수다 삭제
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static deleteCommunityPolicyTalkUsingPut({
        communityIdIdx,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/community/policy-talk/{community_id_idx}/delete',
            path: {
                'community_id_idx': communityIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 게시글 신고
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static reportCommunityUsingPost({
        communityId,
        requestBody,
    }: {
        /**
         * 커뮤니티 ID
         */
        communityId: number,
        requestBody?: CreateCommunityReportRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/community/{community_id}/report',
            path: {
                'community_id': communityId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 웰로 소식 목록 조회
     * @returns DefaultHttpResponse_ListCommunityWelloNewsResponse_ OK
     * @throws ApiError
     */
    public static getCommunityWelloNewsListUsingGet({
        page,
        size,
        searchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 검색어
         */
        searchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListCommunityWelloNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/community/wello-news',
            query: {
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 웰로 뉴스 피드백 (조회수, 좋아요 수, 댓글 수, 좋아요 확인 보정)
     * @returns DefaultHttpResponse_CommunityWelloNewsFeedbackResponse_ OK
     * @throws ApiError
     */
    public static feedbackCommunityWelloNewsUsingGet({
        communityIdIdx,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_CommunityWelloNewsFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/community/wello-news/feedback/{community_id_idx}',
            path: {
                'community_id_idx': communityIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 - 웰로 뉴스 상세
     * @returns DefaultHttpResponse_DetailCommunityWelloNewsResponse_ OK
     * @throws ApiError
     */
    public static getCommunityWelloNewsUsingGet({
        communityIdIdx,
    }: {
        /**
         * 커뮤니티 IDX
         */
        communityIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailCommunityWelloNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/community/wello-news/{community_id_idx}',
            path: {
                'community_id_idx': communityIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 동네 소식 콘텐츠 목록
     * @returns DefaultHttpResponse_ListHometownNewsResponse_ OK
     * @throws ApiError
     */
    public static hometownNewsListUsingGet({
        page,
        size,
        codeRegion,
        codeSubRegion,
        hometownNewsSort,
        searchKeyword,
        tagSearchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 코드_지역(시도)[C01-XX]
         */
        codeRegion?: string,
        /**
         * 코드_지역(시군구)[C01-XX-YY]
         */
        codeSubRegion?: string,
        /**
         * 정렬순서(LATEST,POPULARITY)
         */
        hometownNewsSort?: 'LATEST' | 'POPULARITY',
        /**
         * 검색어
         */
        searchKeyword?: string,
        /**
         * 태그 검색 키워드
         */
        tagSearchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListHometownNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/hometown-news',
            query: {
                'code_region': codeRegion,
                'code_sub_region': codeSubRegion,
                'hometown_news_sort': hometownNewsSort,
                'page': page,
                'search_keyword': searchKeyword,
                'size': size,
                'tag_search_keyword': tagSearchKeyword,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 로그인 멤버 동네 소식 좋아요&스크랩 정보 조회
     * @returns DefaultHttpResponse_WelloFeedbackResponse_ OK
     * @throws ApiError
     */
    public static hometownNewsFeedbackUsingGet({
        hometownNewsId,
    }: {
        /**
         * 동네 소식 ID
         */
        hometownNewsId: number,
    }): CancelablePromise<DefaultHttpResponse_WelloFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/hometown-news/feedback/{hometown_news_id}',
            path: {
                'hometown_news_id': hometownNewsId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 인기 동네 소식 목록
     * @returns DefaultHttpResponse_ListPopularHometownNewsResponse_ OK
     * @throws ApiError
     */
    public static getPopularHometownNewsListUsingGet(): CancelablePromise<DefaultHttpResponse_ListPopularHometownNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/hometown-news/popular',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 커뮤니티 인기 동네 소식 목록
     * @returns DefaultHttpResponse_ListPopularHometownNewsResponse_ OK
     * @throws ApiError
     */
    public static getPopularHometownNewsListForCommunityUsingGet(): CancelablePromise<DefaultHttpResponse_ListPopularHometownNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/hometown-news/popular/community',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 동네 소식 콘텐츠 상세
     * @returns DefaultHttpResponse_DetailHometownNewsResponse_ OK
     * @throws ApiError
     */
    public static hometownNewsUsingGet({
        hometownNewsIdIdx,
    }: {
        /**
         * 동네 소식 Id Idx
         */
        hometownNewsIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailHometownNewsResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/hometown-news/{hometown_news_id_idx}',
            path: {
                'hometown_news_id_idx': hometownNewsIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 문의내역 조회
     * 문의 내역을 조회합니다
     * @returns DefaultHttpResponse_ListInquiryResponse_ OK
     * @throws ApiError
     */
    public static inquiryListUsingGet({
        page,
        size,
    }: {
        page: number,
        size: number,
    }): CancelablePromise<DefaultHttpResponse_ListInquiryResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/cs/inquiry',
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 문의하기
     * 문의내용을 등록합니다
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveInquiryUsingPost({
        requestBody,
    }: {
        requestBody?: InquiryRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/cs/inquiry',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 문의상세 조회
     * 문의 상세 내역을 조회합니다
     * @returns DefaultHttpResponse_DetailInquiryResponse_ OK
     * @throws ApiError
     */
    public static getInquiryUsingGet({
        inquiryId,
    }: {
        /**
         * 문의 번호
         */
        inquiryId: number,
    }): CancelablePromise<DefaultHttpResponse_DetailInquiryResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/cs/inquiry/{inquiry_id}',
            path: {
                'inquiry_id': inquiryId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 계좌 목록 조회
     * @returns DefaultHttpResponse_ListMemberAccountResponse_ OK
     * @throws ApiError
     */
    public static memberAccountListUsingGet(): CancelablePromise<DefaultHttpResponse_ListMemberAccountResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-account',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 계좌 등록/변경
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveMemberAccountUsingPost({
        accountNumber,
        bankId,
    }: {
        /**
         * 계좌번호
         */
        accountNumber: string,
        /**
         * 은행ID
         */
        bankId: number,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-account',
            query: {
                'accountNumber': accountNumber,
                'bankId': bankId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 사용 가능한 계좌 조회
     * @returns DefaultHttpResponse_DetailMemberAccountResponse_ OK
     * @throws ApiError
     */
    public static getMemberAccountUsingGet(): CancelablePromise<DefaultHttpResponse_DetailMemberAccountResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-account/available',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 카드 신청 여정 목록
     * @returns DefaultHttpResponse_ListMemberCardResponse_ OK
     * @throws ApiError
     */
    public static memberCardListUsingGet(): CancelablePromise<DefaultHttpResponse_ListMemberCardResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-card',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 카드를 발급 받았어요
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static updateIssueAtUsingPut({
        memberCardId,
    }: {
        /**
         * 카드 신청 ID
         */
        memberCardId: number,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/member-card',
            query: {
                'memberCardId': memberCardId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 카드 신청
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveMemberCardUsingPost({
        availCardId,
        memberCode,
    }: {
        /**
         * 신청카드 ID
         */
        availCardId: number,
        /**
         * 추천인 코드
         */
        memberCode?: string,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-card',
            query: {
                'availCardId': availCardId,
                'memberCode': memberCode,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 카드를 발급 받았어요-카드뒷면 업로드
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static uploadCardImageUsingPut({
        cardImageUrl,
        memberCardId,
    }: {
        /**
         * 카드 이미지 URL
         */
        cardImageUrl: string,
        /**
         * 카드 신청 ID
         */
        memberCardId: number,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/wello/v2/auth/member-card/card-image',
            query: {
                'cardImageUrl': cardImageUrl,
                'memberCardId': memberCardId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 최근 인증된 멤버 CI 조회
     * @returns DefaultHttpResponse_DetailMemberVerificationCiResponse_ OK
     * @throws ApiError
     */
    public static getLatestCompletedCiUsingPost(): CancelablePromise<DefaultHttpResponse_DetailMemberVerificationCiResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-verification',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 본인인증 ID 조회
     * @returns DefaultHttpResponse_DetailMemberVerificationResponse_ OK
     * @throws ApiError
     */
    public static getMemberVerificationIdUsingGet(): CancelablePromise<DefaultHttpResponse_DetailMemberVerificationResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/member-verification/check',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 본인인증 정보 복호화
     * @returns DefaultHttpResponse_DecryptMemberVerificationResponse_ OK
     * @throws ApiError
     */
    public static decryptMemberVerificationUsingPost({
        requestBody,
    }: {
        requestBody?: DecryptMemberVerificationRequest,
    }): CancelablePromise<DefaultHttpResponse_DecryptMemberVerificationResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-verification/decrypt',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 최근 인증된 멤버 정보 조회
     * @returns DefaultHttpResponse_DetailMemberVerificationInfoResponse_ OK
     * @throws ApiError
     */
    public static getLatestCompletedUsingPost(): CancelablePromise<DefaultHttpResponse_DetailMemberVerificationInfoResponse_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/member-verification/info',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 검색목록 조회
     * @returns DefaultHttpResponse_ListMetaPolicyResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyListUsingGet({
        page,
        size,
        codeProvisionTypeList,
        codeRegion,
        codeSubRegion,
        descAgeList,
        policySort,
        searchKeyword,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 페이지 사이즈
         */
        size: number,
        /**
         * 지원유형[C09-XX] 목록
         */
        codeProvisionTypeList?: Array<string>,
        /**
         * 메타코드-지역(시도)[C01-XX]
         */
        codeRegion?: string,
        /**
         * 메타코드-지역(시군구)[C01-XX-YY]
         */
        codeSubRegion?: string,
        /**
         * 지원대상 목록
         */
        descAgeList?: Array<string>,
        /**
         * 정렬순서(LATEST,POPULARITY)
         */
        policySort?: 'LATEST' | 'POPULARITY',
        /**
         * 검색어
         */
        searchKeyword?: string,
    }): CancelablePromise<DefaultHttpResponse_ListMetaPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy',
            query: {
                'codeProvisionTypeList': codeProvisionTypeList,
                'codeRegion': codeRegion,
                'codeSubRegion': codeSubRegion,
                'descAgeList': descAgeList,
                'page': page,
                'policySort': policySort,
                'searchKeyword': searchKeyword,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 로그인 멤버 정책 좋아요&스크랩 정보 조회
     * @returns DefaultHttpResponse_WelloFeedbackResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyFeedbackUsingGet({
        policyId,
    }: {
        /**
         * 정책 ID
         */
        policyId: number,
    }): CancelablePromise<DefaultHttpResponse_WelloFeedbackResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy/feedback/{policy_id}',
            path: {
                'policy_id': policyId,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 관심 분야별 정책 조회
     * @returns DefaultHttpResponse_ListPolicyResponse_ OK
     * @throws ApiError
     */
    public static getInterestPolicyListUsingGet({
        codeProvisionType,
        page,
        size,
        codeRegion,
        codeSubRegion,
    }: {
        /**
         * 관심유형(C14)
         */
        codeProvisionType: string,
        page: number,
        size: number,
        /**
         * 시도 코드
         */
        codeRegion?: string,
        /**
         * 시군구 코드
         */
        codeSubRegion?: string,
    }): CancelablePromise<DefaultHttpResponse_ListPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy/interest-policy',
            query: {
                'codeProvisionType': codeProvisionType,
                'codeRegion': codeRegion,
                'codeSubRegion': codeSubRegion,
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 관련 정책 목록 조회(최대 7개)
     * @returns DefaultHttpResponse_ListRelatedMetaPolicyResponse_ OK
     * @throws ApiError
     */
    public static getRelatedMetaPolicyListUsingGet({
        metaPolicyIdIdx,
    }: {
        /**
         * 정책 id idx
         */
        metaPolicyIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_ListRelatedMetaPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy/policy/{meta_policy_id_idx}/related',
            path: {
                'meta_policy_id_idx': metaPolicyIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 회원 정책 상세 조회
     * @returns DefaultHttpResponse_DetailMetaPolicyResponse_ OK
     * @throws ApiError
     */
    public static metaPolicyUsingGet({
        metaPolicyIdIdx,
    }: {
        /**
         * 정책 idx
         */
        metaPolicyIdIdx: string,
    }): CancelablePromise<DefaultHttpResponse_DetailMetaPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy/{meta_policy_id_idx}',
            path: {
                'meta_policy_id_idx': metaPolicyIdIdx,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 회원 마감 임박 맞춤 정책 목록 조회
     * @returns DefaultHttpResponse_ListRecommendPolicyResponse_ OK
     * @throws ApiError
     */
    public static getRecommendNearExpiryPolicyListUsingGet({
        page,
        size,
        codeProvisionType,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 지원유형(C09)
         */
        codeProvisionType?: string,
    }): CancelablePromise<DefaultHttpResponse_ListRecommendPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy/recommend/near-expiry',
            query: {
                'code_provision_type': codeProvisionType,
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 회원 마감 임박 맞춤 정책 지원 유형 목록 조회(전체)
     * @returns DefaultHttpResponse_ListRecommendPolicyProvisionTypeResponse_ OK
     * @throws ApiError
     */
    public static getRecommendNearExpiryPolicyProvisionTypeAllListUsingGet(): CancelablePromise<DefaultHttpResponse_ListRecommendPolicyProvisionTypeResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy/recommend/near-expiry/provision-types',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 회원 신규 맞춤 정책 목록 조회
     * @returns DefaultHttpResponse_ListRecommendPolicyResponse_ OK
     * @throws ApiError
     */
    public static getRecommendNewPolicyListUsingGet({
        page,
        size,
        codeProvisionType,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 지원유형(C09)
         */
        codeProvisionType?: string,
    }): CancelablePromise<DefaultHttpResponse_ListRecommendPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy/recommend/new',
            query: {
                'code_provision_type': codeProvisionType,
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 회원 신규 맞춤 정책 지원 유형 목록 조회(전체)
     * @returns DefaultHttpResponse_ListRecommendPolicyProvisionTypeResponse_ OK
     * @throws ApiError
     */
    public static getRecommendNewPolicyProvisionTypeAllListUsingGet(): CancelablePromise<DefaultHttpResponse_ListRecommendPolicyProvisionTypeResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy/recommend/new/provision-types',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 회원 급상승 맞춤 정책 목록 조회
     * @returns DefaultHttpResponse_ListRecommendPolicyResponse_ OK
     * @throws ApiError
     */
    public static getRecommendTrendingPolicyListUsingGet({
        page,
        size,
        codeProvisionType,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 지원유형(C09)
         */
        codeProvisionType?: string,
    }): CancelablePromise<DefaultHttpResponse_ListRecommendPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy/recommend/trending',
            query: {
                'code_provision_type': codeProvisionType,
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 회원 급상승 맞춤 정책 지원 유형 목록 조회(전체)
     * @returns DefaultHttpResponse_ListRecommendPolicyProvisionTypeResponse_ OK
     * @throws ApiError
     */
    public static getRecommendTrendingPolicyProvisionTypeAllListUsingGet(): CancelablePromise<DefaultHttpResponse_ListRecommendPolicyProvisionTypeResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy/recommend/trending/provision-types',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 회원 확인하지 않은 맞춤 정책 목록 조회
     * @returns DefaultHttpResponse_ListRecommendPolicyResponse_ OK
     * @throws ApiError
     */
    public static getRecommendUnreadPolicyListUsingGet({
        page,
        size,
        codeProvisionType,
    }: {
        /**
         * 페이지
         */
        page: number,
        /**
         * 사이즈
         */
        size: number,
        /**
         * 지원유형(C09)
         */
        codeProvisionType?: string,
    }): CancelablePromise<DefaultHttpResponse_ListRecommendPolicyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy/recommend/unread',
            query: {
                'code_provision_type': codeProvisionType,
                'page': page,
                'size': size,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 회원 확인하지 않은 맞춤 정책 지원 유형 목록 조회(전체)
     * @returns DefaultHttpResponse_ListRecommendPolicyProvisionTypeResponse_ OK
     * @throws ApiError
     */
    public static getRecommendUnreadPolicyProvisionTypeAllListUsingGet(): CancelablePromise<DefaultHttpResponse_ListRecommendPolicyProvisionTypeResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy/recommend/unread/provision-types',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 혜택내역 조회
     * @returns DefaultHttpResponse_ListPaymentHisResponse_ OK
     * @throws ApiError
     */
    public static paymentHisListUsingGet(): CancelablePromise<DefaultHttpResponse_ListPaymentHisResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/payment-his',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 총 리워드 지급 수
     * @returns DefaultHttpResponse_TotalPaymentHisResponse_ OK
     * @throws ApiError
     */
    public static totalPaymentHisListUsingGet(): CancelablePromise<DefaultHttpResponse_TotalPaymentHisResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/payment-his/total-count',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 이벤트 참여
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveWelloParticipationUsingPost({
        requestBody,
    }: {
        requestBody?: SaveWelloEventParticipationRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/wello-event-participation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 바로신청 내역 조회
     * @returns DefaultHttpResponse_ListWelloPolicyApplyResponse_ OK
     * @throws ApiError
     */
    public static getWelloPolicyApplyListUsingGet(): CancelablePromise<DefaultHttpResponse_ListWelloPolicyApplyResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy-apply/history',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 정책 바로신청 내역 수
     * @returns DefaultHttpResponse_WelloPolicyApplyCountResponse_ OK
     * @throws ApiError
     */
    public static getWelloPolicyApplyCountUsingGet(): CancelablePromise<DefaultHttpResponse_WelloPolicyApplyCountResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/policy-apply/history/count',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 채무자신고 내역 저장 (각종 정보 제공 동의 및 채무자신고내역 저장)
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveReportUsingPost({
        policyApplyId,
        requestBody,
    }: {
        /**
         * 정책신청 ID
         */
        policyApplyId: number,
        requestBody?: SaveReportRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/policy-apply/report',
            headers: {
                'policyApplyId': policyApplyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * IP로 현재 위치의 지역정보 조회
     * @returns DefaultHttpResponse_ReverseGeocodingResponse_ OK
     * @throws ApiError
     */
    public static authGeolocationUsingGet({
        ip,
    }: {
        /**
         * IP
         */
        ip: string,
    }): CancelablePromise<DefaultHttpResponse_ReverseGeocodingResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/wello-policy-code/geolocation',
            query: {
                'ip': ip,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 경도&위도로 현재 위치의 지역정보 조회
     * @returns DefaultHttpResponse_ReverseGeocodingResponse_ OK
     * @throws ApiError
     */
    public static authReverseGeocodingUsingGet({
        coords,
    }: {
        /**
         * 입력 좌표
         */
        coords: string,
    }): CancelablePromise<DefaultHttpResponse_ReverseGeocodingResponse_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/wello/v2/auth/wello-policy-code/reverse-geocoding',
            query: {
                'coords': coords,
            },
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
    /**
     * 웰로 회원 트래킹 정보 저장
     * @returns DefaultHttpResponse_Void_ OK
     * @throws ApiError
     */
    public static saveWelloTrackingUsingPost({
        requestBody,
    }: {
        requestBody?: SaveWelloTrackRequest,
    }): CancelablePromise<DefaultHttpResponse_Void_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/wello/v2/auth/wello-track',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `SQL 오류 및 기타 미처리 예외 상황입니다.`,
                502: `외부 API 오류 입니다.`,
            },
        });
    }
}
